import Auth from '../ui/controllers/Auth';
import Autosuggest from '../ui/controllers/Autosuggest';
import EqualWidth from '../ui/controllers/EqualWidth';
import OtherValueCheckbox from '../ui/controllers/OtherValueCheckbox';
import ResponsiveGrid from '../ui/controllers/ResponsiveGrid';
import RichText from '../ui/controllers/RichText';
import LiveChat from '../ui/controllers/LiveChat';
import ClickableTile from '../ui/controllers/ClickableTile';
import Form from '../ui/controllers/Form';
import MiniCart from '../ui/controllers/MiniCart';
import OtherValue from '../ui/OtherValue';
import ToggleSync from '../ui/controllers/ToggleSync';
import TextfieldDecorator from '../ui/controllers/TextfieldDecorator';
import Header from '../ui/controllers/Header';
import OtherValueRadioButton from '../ui/controllers/OtherValueRadioButton';
import ConditionalCollapse from '../ui/controllers/ConditionalCollapse';
import ClientsideHero from '../ui/controllers/ClientsideHero';
import VideoButton from '../ui/controllers/video/VideoButton';
import ContentOpener from '../ui/controllers/ContentOpener';
import Captcha from '../ui/controllers/Captcha';
import SortItems from '../ui/controllers/SortItems';
import Collapsible from '../ui/controllers/Collapsible';
import InputSpacer from '../ui/controllers/InputSpacer';
import ToolTip from '../ui/controllers/ToolTip';
import Footnote from '../ui/controllers/Footnote';
import DatePicker from '../ui/controllers/DatePicker';
import FormValidation from '../ui/FormValidation';
import EnterpriseBot from '../ui/controllers/EnterpriseBot';

class ComponentFactory {
    #componentLibrary = {};

    constructor() {
        this.#register('Auth', Auth);
        this.#register('Autosuggest', Autosuggest);
        this.#register('Captcha', Captcha);
        this.#register('ClickableTile', ClickableTile);
        this.#register('ClientsideHero', ClientsideHero);
        this.#register('Collapsible', Collapsible);
        this.#register('ConditionalCollapse', ConditionalCollapse);
        this.#register('ContentOpener', ContentOpener);
        this.#register('DatePicker', DatePicker);
        this.#register('EnterpriseBot', EnterpriseBot);
        this.#register('EqualWidth', EqualWidth);
        this.#register('Footnote', Footnote);
        this.#register('Form', Form);
        this.#register('FormValidation', FormValidation);
        this.#register('Header', Header);
        this.#register('InputSpacer', InputSpacer);
        this.#register('LiveChat', LiveChat);
        this.#register('MiniCart', MiniCart);
        this.#register('OtherValue', OtherValue);
        this.#register('OtherValueCheckbox', OtherValueCheckbox);
        this.#register('OtherValueRadioButton', OtherValueRadioButton);
        this.#register('ResponsiveGrid', ResponsiveGrid);
        this.#register('RichText', RichText);
        this.#register('SortItems', SortItems);
        this.#register('TextfieldDecorator', TextfieldDecorator);
        this.#register('ToggleSync', ToggleSync);
        this.#register('ToolTip', ToolTip);
        this.#register('video/VideoButton', VideoButton);
    }

    #getComponentClassByPath(path) {
        return this.#componentLibrary[path];
    }

    #register(id, controller) {
        this.#componentLibrary['generic/ui/' + id] = controller;
    }

    create(path, node, settings, componentManager) {
        let ComponentClass = this.#getComponentClassByPath(path);
        if (ComponentClass) {
            return new ComponentClass(node, settings, componentManager);
        } else {
            console.error('Component not found', path);
        }
    }
}
export default new ComponentFactory();

import DOMUtils from './DOMUtils';
import gsap from 'gsap';

export default function scrollToTarget(target, animationTime, callBack) {
    let siteHeader = '.header',
        scrollMargin = 30,
        headerHeight = document.querySelector(siteHeader)?.getBoundingClientRect().height ?? 0,
        animationTimeToUse = animationTime === false ? 0.2 : animationTime,
        targetPos;

    //checks if target is a number or element
    if (typeof target === 'number') {
        targetPos = target;
    } else {
        targetPos = DOMUtils.offset(target).top - headerHeight - scrollMargin;
    }
    targetPos = targetPos >= 0 ? targetPos : 0;
    if (window.scrollY !== targetPos) {
        if (animationTimeToUse >= 0) {
            document.documentElement.style.scrollBehavior = 'auto';
            const tl = gsap.timeline({
                onComplete: () => {
                    document.documentElement.style.scrollBehavior = '';
                    if (callBack && typeof callBack === 'function') {
                        callBack();
                    }
                }
            });
            tl.to(window, { duration: animationTimeToUse, scrollTo: targetPos });
        } else {
            window.scrollTo(window.scrollX, targetPos);
            if (callBack && typeof callBack === 'function') {
                callBack();
            }
        }
    } else {
        if (callBack && typeof callBack === 'function') {
            callBack();
        }
    }
}

import Overlay from './Overlay';

class OverlayManager {
    static #contentLib = {};
    static #siblingLib = {};

    static openById(id, opener, breakpoint, parent, fullheight, siblingId) {
        let content = OverlayManager.#getContent(id),
            overlay = new Overlay(),
            sibling = OverlayManager.#getSibling(siblingId);

        content.classList.remove('is-hidden');

        overlay.setContent(content, parent);
        overlay.setOpener(opener);
        if (breakpoint) {
            overlay.attachBreakPointListener(breakpoint);
        }

        if (fullheight) {
            overlay.setFullHeight();
        }
        if (sibling) {
            overlay.displace(sibling);
        }

        overlay.open();
    }

    static #getContent(id) {
        if (OverlayManager.#contentLib[id]) {
            return OverlayManager.#contentLib[id];
        }

        let content = document.getElementById(id);
        if (content) {
            OverlayManager.#contentLib[id] = content;
            return content;
        }
        console.error('Cannot find content for id:', id);
    }

    static #getSibling(siblingId) {
        if (typeof siblingId === 'undefined' || siblingId === null) {
            return null;
        }
        if (OverlayManager.#siblingLib[siblingId]) {
            return OverlayManager.#siblingLib[siblingId];
        }

        let sibling = document.getElementById(siblingId);
        if (sibling) {
            OverlayManager.#siblingLib[siblingId] = sibling;
            return sibling;
        }
        console.error('Cannot find content for sibling:', sibling);
    }
}

export default OverlayManager;

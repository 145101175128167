import merge from 'lodash/merge';

class ClickableTile {
    static DEFAULT_CONFIG = {
        linkSelector: 'a, button',
        clickableClass: 'is-clickable'
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, ClickableTile.DEFAULT_CONFIG, config);
        this.link = this.node.querySelector(this.config.linkSelector);
        if (this.link) {
            ['mousedown', 'pointerdown'].forEach((eventType) => {
                this.node.addEventListener(eventType, (event) => {
                    if (!this.#isClickOnLink(event) && this.#isMiddleMouseButton(event)) {
                        event.preventDefault();
                    }
                });
            });
            this.node.addEventListener('click', (event) => this.executeLink(event));
            this.node.addEventListener('auxclick', (event) => this.executeLink(event));
            this.node.classList.add(this.config.clickableClass);
        }
    }

    executeLink = (event) => {
        // Prevent handling the click if any of the following is true:
        // - One of the links or one of its children was directly clicked
        // - The user is selecting something
        // - The user right-clicked or used any other not supported button like a back button
        if (this.#isClickOnLink(event) || getSelection().toString() || event.button >= 2) {
            return;
        }

        const tempClickHandler = (event) => this.#tempClickHandler(event);
        // add last-second event handler
        this.link.addEventListener('click', tempClickHandler);
        try {
            // trigger the click event
            const myEvent = new CustomEvent('click', {
                detail: {
                    extraEventData: {
                        trigger: 'background'
                    },
                    originalEvent: event
                }
            });
            this.link.dispatchEvent(myEvent);
        } finally {
            // remove last-second event handler
            this.link.removeEventListener('click', tempClickHandler);
        }
    };

    #tempClickHandler(event) {
        const originalEvent = event.detail.originalEvent;
        const href = this.link.href;
        if (href) {
            let anchorTarget = this.link.getAttribute('target');
            if (originalEvent.ctrlKey || originalEvent.metaKey || this.#isMiddleMouseButton(originalEvent)) {
                anchorTarget = '_blank';
            }
            const newWindow = anchorTarget && anchorTarget !== '_self';
            if (newWindow) {
                const windowRef = window.open(href, anchorTarget);
                if (windowRef !== null && typeof windowRef !== 'undefined') {
                    const anchorRel = this.link.getAttribute('rel');
                    if (anchorRel && anchorRel.indexOf('noopener') !== -1) {
                        windowRef.opener = null;
                    }
                }
            } else {
                document.location.href = href;
            }
        }
    }

    #isClickOnLink(event) {
        return this.link === event.target || this.link.contains(event.target);
    }

    #isMiddleMouseButton(event) {
        return event.button === 1;
    }
}

export default ClickableTile;

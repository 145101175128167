import EventEmitter from 'wolfy87-eventemitter';
import debounce from 'lodash/debounce';
import viewportSize from 'viewport-size';
import sortBy from 'lodash/sortBy';

class Responsive extends EventEmitter {
    EVENTS = {
        initialized: 'Responsive.EVENTS.initialized',
        resized: 'Responsive.EVENTS.resized',
        crossedBreakpoint: 'Responsive.EVENTS.crossed_breakpoint'
    };
    #currentBreakpoint;

    constructor() {
        super();
        this.breakpointData = {};
        this.breakpointList = [];

        this.viewportWidth = this.getViewportWidth();

        // Store current breakpoint AFTER initResponsive has been called. Prevents premature firing of events
        this.on(this.EVENTS.initialized, () => {
            this.#currentBreakpoint = this.getBreakpoint();
        });
        ['resize', 'orientationchange'].forEach((event) =>
            window.addEventListener(
                event,
                debounce(() => this.resizeHandler(), 150)
            )
        );
    }

    addBreakpointData(name, width) {
        this.breakpointData[name] = {
            name: name,
            width: width
        };
        this.breakpointList.push({
            name: name,
            width: width
        });
        this.breakpointList = sortBy(this.breakpointList, 'width');
    }

    getBreakpointData() {
        return this.breakpointData;
    }

    resizeHandler() {
        this.viewportWidth = this.getViewportWidth();

        let breakpoint = this.getBreakpoint();

        this.emitEvent(this.EVENTS.resized, [
            {
                viewportWidth: this.viewportWidth,
                breakpoint: breakpoint
            }
        ]);

        if (breakpoint !== this.#currentBreakpoint) {
            this.emitEvent(this.EVENTS.crossedBreakpoint, [
                {
                    viewportWidth: this.viewportWidth,
                    breakpoint: breakpoint
                }
            ]);
            this.#currentBreakpoint = breakpoint;
        }
    }

    getViewportWidth() {
        //uses viewportSize instead of measuring windowwidth itself,
        // because this gives different sizes per browser depending on scrollbars.
        return viewportSize.getWidth();
    }

    getViewportHeight() {
        return viewportSize.getHeight();
    }

    getBreakpoint(width) {
        if (!width) {
            width = this.getViewportWidth();
        }

        let i = this.breakpointList.length;
        while (--i >= 0) {
            let bp = this.breakpointList[i];
            if (this.breakpointList[i].width <= width) {
                return bp;
            }
        }
        return null;
    }

    testBreakpoint(name) {
        let breakpointObj = this.breakpointData[name];
        if (!breakpointObj) {
            return false;
        }
        return this.matchWidth(breakpointObj.width);
    }

    matchWidth(width) {
        return this.matchMedia('only screen and (min-width: ' + width + 'px)');
    }

    matchMedia(query) {
        return window.matchMedia && window.matchMedia(query).matches;
    }

    matchSrcset(srcset) {
        let sizes = Object.keys(srcset),
            width = this.getViewportWidth();

        let i = this.breakpointList.length;
        while (--i >= 0) {
            let bp = this.breakpointList[i];
            if (this.breakpointList[i].width < width && sizes.includes(this.breakpointList[i].name)) {
                return srcset[bp.name];
            }
        }
        return null;
    }
}

export default new Responsive();

import Responsive from '../../utils/Responsive';
import merge from 'lodash/merge';

class ConditionalCollapse {
    static DEFAULT_CONFIG = {
        enabledFromBreakpoints: [],
        disabledFromBreakpoints: []
    };

    #clickListener = (event) => {
        event.preventDefault();
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, ConditionalCollapse.DEFAULT_CONFIG, config);
        this.init();
    }

    init() {
        this.#setCollapseState();
        Responsive.on(Responsive.EVENTS.crossedBreakpoint, () => this.#setCollapseState());
    }

    #setCollapseState() {
        let currentBreakpoint = Responsive.getBreakpoint().name;
        let breakpoints = Responsive.breakpointList.map((b) => b.name);
        let disabled = false;
        for (let breakpoint of breakpoints) {
            if (this.config.enabledFromBreakpoints.indexOf(breakpoint) >= 0) {
                disabled = false;
            }
            if (this.config.disabledFromBreakpoints.indexOf(breakpoint) >= 0) {
                disabled = true;
            }
            if (breakpoint === currentBreakpoint) {
                break;
            }
        }
        if (disabled) {
            this.node.setAttribute('open', '');
            this.node.classList.add('collapse--disabled');
            this.node.addEventListener('click', this.#clickListener);
        } else {
            this.node.removeAttribute('open');
            this.node.classList.remove('collapse--disabled');
            this.node.removeEventListener('click', this.#clickListener);
        }
    }
}
export default ConditionalCollapse;

/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import Utils from '../common/utils';
import Input from './input';
import DOMUtils from '../../generic/utils/DOMUtils';

class Typeahead {
    constructor(o, www) {
        let onFocused,
            onBlurred,
            onEnterKeyed,
            onTabKeyed,
            onEscKeyed,
            onUpKeyed,
            onDownKeyed,
            onLeftKeyed,
            onRightKeyed,
            onQueryChanged,
            onWhitespaceChanged;

        o = o || {};

        if (!o.input) {
            Utils.error('missing input');
        }

        if (!o.menu) {
            Utils.error('missing menu');
        }

        if (!o.eventBus) {
            Utils.error('missing event bus');
        }

        www.mixin(this);

        this.eventBus = o.eventBus;
        this.minLength = Utils.isNumber(o.minLength) ? o.minLength : 1;
        this.autocompleteEnabled = !Utils.isUndefined(o.autocomplete) ? o.autocomplete : true;

        this.input = o.input;
        this.menu = o.menu;

        this.enabled = true;

        // activate the typeahead on init if the input has focus
        this.active = false;
        this.input.hasFocus() && this.activate();

        // detect the initial lang direction
        this.dir = this.input.getLangDir();

        this.#hacks();

        this.menu
            .bind()
            .onSync('selectableClicked', this.#onSelectableClicked, this)
            .onSync('asyncRequested', this.#onAsyncRequested, this)
            .onSync('asyncCanceled', this.#onAsyncCanceled, this)
            .onSync('asyncReceived', this.#onAsyncReceived, this)
            .onSync('datasetRendered', this.#onDatasetRendered, this)
            .onSync('datasetCleared', this.#onDatasetCleared, this);

        // composed event handlers for input
        onFocused = Typeahead.#c(this, 'activate', 'open', 'onFocused');
        onBlurred = Typeahead.#c(this, 'deactivate', 'onBlurred');
        onEnterKeyed = Typeahead.#c(this, 'isActive', 'isOpen', 'onEnterKeyed');
        onTabKeyed = Typeahead.#c(this, 'isActive', 'isOpen', 'onTabKeyed');
        onEscKeyed = Typeahead.#c(this, 'isActive', 'onEscKeyed');
        onUpKeyed = Typeahead.#c(this, 'isActive', 'open', 'onUpKeyed');
        onDownKeyed = Typeahead.#c(this, 'isActive', 'open', 'onDownKeyed');
        onLeftKeyed = Typeahead.#c(this, 'isActive', 'isOpen', 'onLeftKeyed');
        onRightKeyed = Typeahead.#c(this, 'isActive', 'isOpen', 'onRightKeyed');
        onQueryChanged = Typeahead.#c(this, 'openIfActive', 'onQueryChanged');
        onWhitespaceChanged = Typeahead.#c(this, 'openIfActive', 'onWhitespaceChanged');

        this.input
            .bind()
            .onSync('focused', onFocused, this)
            .onSync('blurred', onBlurred, this)
            .onSync('enterKeyed', onEnterKeyed, this)
            .onSync('tabKeyed', onTabKeyed, this)
            .onSync('escKeyed', onEscKeyed, this)
            .onSync('upKeyed', onUpKeyed, this)
            .onSync('downKeyed', onDownKeyed, this)
            .onSync('leftKeyed', onLeftKeyed, this)
            .onSync('rightKeyed', onRightKeyed, this)
            .onSync('queryChanged', onQueryChanged, this)
            .onSync('whitespaceChanged', onWhitespaceChanged, this)
            .onSync('langDirChanged', this.#onLangDirChanged, this);
    }

    #hacks() {
        let input, menu;

        // these default values are to make testing easier
        input = this.input.input || DOMUtils.createElementFromHTML('<div/>');
        menu = this.menu.node || DOMUtils.createElementFromHTML('<div/>');

        // #705: if there's scrollable overflow, ie doesn't support
        // blur cancellations when the scrollbar is clicked
        //
        // #351: preventDefault won't cancel blurs in ie <= 8
        input.addEventListener('blur', (e) => {
            let active, isActive, hasActive;

            active = document.activeElement;
            isActive = menu === active;
            hasActive = !!Array.from(menu.childNodes).find((node) => node === active);

            if (Utils.isMsie() && (isActive || hasActive)) {
                e.preventDefault();
                // stop immediate in order to prevent Input#_onBlur from
                // getting exectued
                e.stopImmediatePropagation();
                Utils.defer(() => {
                    input.focus();
                });
            }
        });

        // #351: prevents input blur due to clicks within menu
        menu.addEventListener('mousedown', (e) => {
            e.preventDefault();
        });
    }

    #onSelectableClicked(type, el) {
        this.select(el);
    }

    #onDatasetCleared() {
        this.#updateHint();
    }

    #onDatasetRendered(type, dataset, suggestions, async) {
        this.#updateHint();
        this.eventBus.trigger('render', suggestions, async, dataset);
    }

    #onAsyncRequested(type, dataset, query) {
        this.eventBus.trigger('asyncrequest', query, dataset);
    }

    #onAsyncCanceled(type, dataset, query) {
        this.eventBus.trigger('asynccancel', query, dataset);
    }

    #onAsyncReceived(type, dataset, query) {
        this.eventBus.trigger('asyncreceive', query, dataset);
    }

    onFocused() {
        this.#minLengthMet() && this.menu.update(this.input.getQuery());
    }

    onBlurred() {
        if (this.input.hasQueryChangedSinceLastFocus()) {
            this.eventBus.trigger('change', this.input.getQuery());
        }
    }

    onEnterKeyed(type, e) {
        let selectable;

        if ((selectable = this.menu.getActiveSelectable())) {
            this.select(selectable) && e.preventDefault();
        }
    }

    onTabKeyed(type, e) {
        let selectable;

        if ((selectable = this.menu.getActiveSelectable())) {
            this.select(selectable) && e.preventDefault();
        } else if (this.autocompleteEnabled && (selectable = this.menu.getTopSelectable())) {
            this.autocomplete(selectable) && e.preventDefault();
        }
    }

    onEscKeyed() {
        this.close();
    }

    onUpKeyed() {
        this.moveCursor(-1);
    }

    onDownKeyed() {
        this.moveCursor(+1);
    }

    onLeftKeyed() {
        if (this.autocompleteEnabled && this.dir === 'rtl' && this.input.isCursorAtEnd()) {
            this.autocomplete(this.menu.getTopSelectable());
        }
    }

    onRightKeyed() {
        if (this.autocompleteEnabled && this.dir === 'ltr' && this.input.isCursorAtEnd()) {
            this.autocomplete(this.menu.getTopSelectable());
        }
    }

    onQueryChanged(e, query) {
        this.#minLengthMet(query) ? this.menu.update(query) : this.menu.empty();
    }

    onWhitespaceChanged() {
        this.#updateHint();
    }

    #onLangDirChanged(e, dir) {
        if (this.dir !== dir) {
            this.dir = dir;
            this.menu.setLanguageDirection(dir);
        }
    }

    openIfActive() {
        this.isActive() && this.open();
    }

    #minLengthMet(query) {
        query = Utils.isString(query) ? query : this.input.getQuery() || '';

        return query.length >= this.minLength;
    }

    #updateHint() {
        let selectable, data, val, query, escapedQuery, frontMatchRegEx, match;

        selectable = this.menu.getTopSelectable();
        data = this.menu.getSelectableData(selectable);
        val = this.input.getInputValue();

        if (data && !Utils.isBlankString(val) && !this.input.hasOverflow()) {
            query = Input.normalizeQuery(val);
            escapedQuery = Utils.escapeRegExChars(query);

            // match input value, then capture trailing text
            frontMatchRegEx = new RegExp('^(?:' + escapedQuery + ')(.+$)', 'i');
            match = frontMatchRegEx.exec(data.val);

            // clear hint if there's no trailing text
            match && this.input.setHint(val + match[1]);
        } else {
            this.input.clearHint();
        }
    }

    isEnabled() {
        return this.enabled;
    }

    enable() {
        this.enabled = true;
    }

    disable() {
        this.enabled = false;
    }

    isActive() {
        return this.active;
    }

    activate() {
        // already active
        if (this.isActive()) {
            return true;
        }

        // unable to activate either due to the typeahead being disabled
        // or due to the active event being prevented
        else if (!this.isEnabled() || this.eventBus.before('active')) {
            return false;
        }

        // activate
        else {
            this.active = true;
            this.eventBus.trigger('active');
            return true;
        }
    }

    deactivate() {
        // already idle
        if (!this.isActive()) {
            return true;
        }

        // unable to deactivate due to the idle event being prevented
        else if (this.eventBus.before('idle')) {
            return false;
        }

        // deactivate
        else {
            this.active = false;
            this.close();
            this.eventBus.trigger('idle');
            return true;
        }
    }

    isOpen() {
        return this.menu.isOpen();
    }

    open() {
        if (!this.isOpen() && !this.eventBus.before('open')) {
            this.menu.open();
            this.#updateHint();
            this.eventBus.trigger('open');
        }

        return this.isOpen();
    }

    close() {
        if (this.isOpen() && !this.eventBus.before('close')) {
            this.menu.close();
            this.input.clearHint();
            this.input.resetInputValue();
            this.eventBus.trigger('close');
        }
        return !this.isOpen();
    }

    setVal(val) {
        // expect val to be a string, so be safe, and coerce
        this.input.setQuery(Utils.toStr(val));
    }

    getVal() {
        return this.input.getQuery();
    }

    select(selectable) {
        let data = this.menu.getSelectableData(selectable);

        if (data && !this.eventBus.before('select', data.obj)) {
            this.input.setQuery(data.val, true);

            this.eventBus.trigger('select', data.obj);
            this.close();

            // return true if selection succeeded
            return true;
        }

        return false;
    }

    autocomplete(selectable) {
        let query, data, isValid;

        query = this.input.getQuery();
        data = this.menu.getSelectableData(selectable);
        isValid = data && query !== data.val;

        if (isValid && !this.eventBus.before('autocomplete', data.obj)) {
            this.input.setQuery(data.val);
            this.eventBus.trigger('autocomplete', data.obj);

            // return true if autocompletion succeeded
            return true;
        }

        return false;
    }

    moveCursor(delta) {
        let query, candidate, data, payload, cancelMove;

        query = this.input.getQuery();
        candidate = this.menu.selectableRelativeToCursor(delta);
        data = this.menu.getSelectableData(candidate);
        payload = data ? data.obj : null;

        // update will return true when it's a new query and new suggestions
        // need to be fetched – in this case we don't want to move the cursor
        cancelMove = this.#minLengthMet() && this.menu.update(query);

        if (!cancelMove && !this.eventBus.before('cursorchange', payload)) {
            this.menu.setCursor(candidate);

            // cursor moved to different selectable
            if (data) {
                this.input.setInputValue(data.val);
            }

            // cursor moved off of selectables, back to input
            else {
                this.input.resetInputValue();
                this.#updateHint();
            }

            this.eventBus.trigger('cursorchange', payload);

            // return true if move succeeded
            return true;
        }

        return false;
    }

    static #c(ctx) {
        let methods = [].slice.call(arguments, 1);

        return function () {
            let args = [].slice.call(arguments);

            Utils.each(methods, (method) => {
                return ctx[method].apply(ctx, args);
            });
        };
    }
}

export default Typeahead;

import EventEmitter from 'wolfy87-eventemitter';

class ChatObserver extends EventEmitter {
    static DEFAULT_OPENING_TIME = {
        hours: 7,
        minutes: 0
    };

    static DEFAULT_CLOSING_TIME = {
        hours: 23,
        minutes: 0
    };

    static CURRENT_TIMEOUT;

    EVENTS = {
        disabled: 'ChatObserver.EVENTS.disabled',
        offline: 'ChatObserver.EVENTS.offline',
        online: 'ChatObserver.EVENTS.online'
    };

    constructor() {
        super();
        this.currentState = null;

        this.addListener(this.EVENTS.online, () => {
            this.currentState = 'online';
        });

        this.addListener(this.EVENTS.offline, () => {
            this.currentState = 'offline';
        });

        this.addListener(this.EVENTS.disabled, () => {
            this.currentState = 'disabled';
        });
    }

    init() {
        if (window.NSDesignConfig?.chat?.enabled) {
            const currentDateTime = new Date();
            const openingDateTime = ChatObserver.getOpeningDateTime(currentDateTime);
            const closingDateTime = ChatObserver.getClosingDateTime(currentDateTime);
            if (openingDateTime <= currentDateTime.getTime() && currentDateTime.getTime() <= closingDateTime) {
                this.emitEvent(this.EVENTS.online);
                this.#waitForOffline(currentDateTime);
            } else {
                this.emitEvent(this.EVENTS.offline);
                this.#waitForOnline(currentDateTime);
            }
        } else {
            this.emitEvent(this.EVENTS.disabled);
        }
    }

    getCurrentState() {
        return this.currentState;
    }

    //For test automation usage
    stopAutomaticUpdates() {
        if (ChatObserver.CURRENT_TIMEOUT) {
            clearTimeout(ChatObserver.CURRENT_TIMEOUT);
        }
    }

    #waitForOnline(date) {
        const openingDateTime = ChatObserver.getOpeningDateTime(date);
        if (openingDateTime < date.getTime()) {
            //check opening time of next day.
            const dutchLocalDateTime = ChatObserver.getDutchLocalDateTime(date);
            const nextDayDutchLocalDateTime = new Date(dutchLocalDateTime);
            nextDayDutchLocalDateTime.setDate(nextDayDutchLocalDateTime.getDate() + 1);
            const nextDayOpeningDateTime = ChatObserver.getOpeningDateTime(nextDayDutchLocalDateTime);
            ChatObserver.CURRENT_TIMEOUT = setTimeout(() => {
                this.emitEvent(this.EVENTS.online);
                this.#waitForOffline(new Date());
            }, nextDayOpeningDateTime - date.getTime());
        } else {
            ChatObserver.CURRENT_TIMEOUT = setTimeout(() => {
                this.emitEvent(this.EVENTS.online);
                this.#waitForOffline(new Date());
            }, openingDateTime - date.getTime());
        }
    }

    #waitForOffline(date) {
        const closingDateTime = ChatObserver.getClosingDateTime(date);
        ChatObserver.CURRENT_TIMEOUT = setTimeout(() => {
            this.emitEvent(this.EVENTS.offline);
            this.#waitForOnline(new Date());
        }, closingDateTime - date.getTime());
    }

    static toDutchLocalDateTimeString(date) {
        if (ChatObserver.toLocaleStringSupportsLocales()) {
            return date.toLocaleString('en-US', {
                dateStyle: 'short',
                timeStyle: 'long',
                hour12: false,
                timeZone: 'Europe/Amsterdam'
            });
        } else {
            //Fallback, in this case the user might experience the wrong opening times (their local time instead of our local time)
            return date.toString();
        }
    }

    static getDutchLocalDateTime(date) {
        const dutchLocalDateTimeString = ChatObserver.toDutchLocalDateTimeString(date);
        return new Date(dutchLocalDateTimeString);
    }

    static getOpeningDateTime(date) {
        return ChatObserver.replaceTime(
            ChatObserver.toDutchLocalDateTimeString(date),
            ChatObserver.DEFAULT_OPENING_TIME.hours,
            ChatObserver.DEFAULT_OPENING_TIME.minutes
        );
    }

    static getClosingDateTime(date) {
        return ChatObserver.replaceTime(
            ChatObserver.toDutchLocalDateTimeString(date),
            ChatObserver.DEFAULT_CLOSING_TIME.hours,
            ChatObserver.DEFAULT_CLOSING_TIME.minutes
        );
    }

    static toLocaleStringSupportsLocales() {
        return typeof Intl === 'object' && !!Intl && typeof Intl.DateTimeFormat === 'function';
    }

    static replaceTime(dateTimeString, hour, minute) {
        const hourString = `${String(hour).padStart(2)}:${String(minute).padStart(2)}:00`;
        const newDateTimeString = dateTimeString.replace(/\d\d:\d\d:\d\d/, hourString);
        return Date.parse(newDateTimeString);
    }
}

export default new ChatObserver();

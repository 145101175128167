/*
 * Live detection of the input-mode used by the visitor. This can be either mouse
 * or touch. This does not check the capability (ie "'ontouchstart' in window"),
 * but tracks actual interaction.
 *
 * The upside of this is that we can now support apply differences in styling
 * for devices which support both touch and mouse, and actually cater to the user's
 * current chosen style of input.
 *
 * Similar to Modernizr the InputModeDetector adds a class to the html node to
 * indicate the style of input used by the visitor at this moment, either
 * 'is-touching' or 'is-mousing'. This className can then be used to tweak styling.
 *
 * WARNING: as the className set by InputModeDetector is liable to change at any moment
 * the user switches from touching to mousing, the styling depending on the
 * is-touching/mousing classNames must not be too different.
 *
 * A good example of use is applying a :hover pseudo-selector for mouse-users and
 * :active pseudo-selector for touch-users. IE, see the 'touchOptimizedHover' mixin.
 */
class InputModeDetector {
    static CONFIG = {
        classNames: {
            mouseMode: 'is-mousing',
            touchMode: 'is-touching'
        }
    };

    static start() {
        InputModeDetector.#mouseMovedCallback();
    }

    static #registerTouchStartHandler() {
        document.addEventListener('touchstart', () => InputModeDetector.#touchStartCallback(), {
            once: true
        });
    }

    static #registerMouseMovedHandler() {
        document.addEventListener('mousemove', () => InputModeDetector.#mouseMovedCallback(), {
            once: true
        });
    }

    static #touchStartCallback() {
        InputModeDetector.#registerMouseMovedHandler();
        document.documentElement.classList.add(InputModeDetector.CONFIG.classNames.touchMode);
        document.documentElement.classList.remove(InputModeDetector.CONFIG.classNames.mouseMode);
    }

    static #mouseMovedCallback() {
        InputModeDetector.#registerTouchStartHandler();
        document.documentElement.classList.remove(InputModeDetector.CONFIG.classNames.touchMode);
        document.documentElement.classList.add(InputModeDetector.CONFIG.classNames.mouseMode);
    }
}

export default InputModeDetector;

import merge from 'lodash/merge';

class TextfieldDecorator {
    static DEFAULT_CONFIG = {
        preventPasting: false
    };
    constructor(node, config) {
        this.node = node;
        this.config = merge({}, TextfieldDecorator.DEFAULT_CONFIG, config);

        if (this.config.preventPasting) {
            this.preventPasting();
        }
    }

    preventPasting() {
        this.node.addEventListener('paste', (e) => {
            e.preventDefault();
        });
    }
}

export default TextfieldDecorator;

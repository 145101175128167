/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import Utils from '../common/utils';
class EventBus {
    static #namespace = 'typeahead:';

    // DEPRECATED: will be remove in v1
    //
    // NOTE: there is no deprecation plan for the opened and closed event
    // as their behavior has changed enough that it wouldn't make sense
    static #deprecationMap = {
        render: 'rendered',
        cursorchange: 'cursorchanged',
        select: 'selected',
        autocomplete: 'autocompleted'
    };
    constructor(o) {
        if (!o || !o.el) {
            Utils.error('EventBus initialized without el');
        }

        this.el = o.el;
    }

    #trigger(type, args) {
        let e;

        e = new CustomEvent(EventBus.#namespace + type, {
            detail: {
                args: args || []
            }
        });

        return !this.el.dispatchEvent(e);
    }

    before(type) {
        let args;

        args = [].slice.call(arguments, 1);
        return this.#trigger('before' + type, args);
    }

    trigger(type) {
        let deprecatedType;

        this.#trigger(type, [].slice.call(arguments, 1));

        // TODO: remove in v1
        if ((deprecatedType = EventBus.#deprecationMap[type])) {
            this.#trigger(deprecatedType, [].slice.call(arguments, 1));
        }
    }
}

export default EventBus;

import Overlay from '../../Overlay';
import VideoPlayer from './VideoPlayer';
import merge from 'lodash/merge';

class VideoButton {
    DEFAULT_CONFIG = {};

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, this.DEFAULT_CONFIG, config);
        this.#attachListeners();
    }

    refocus() {
        this.node.focus();
    }

    #attachListeners() {
        this.node.addEventListener('click', (e) => this.#openPlayerInOverlay(e));
    }

    #openPlayerInOverlay(e) {
        e.preventDefault();

        let videoPlayer;
        let overlay;

        videoPlayer = new VideoPlayer(null, this.config);
        videoPlayer.init();

        overlay = new Overlay({
            type: 'video'
        });
        overlay.opener = this;
        overlay.setContent(videoPlayer.getNode());
        overlay.open();
        overlay.on(Overlay.EVENTS.overlayIsClosed, () => {
            videoPlayer.destroy();
        });
    }
}

export default VideoButton;

import ChatUtil from '../../utils/ChatUtil';
import ChatObserver from '../../utils/ChatObserver';
import merge from 'lodash/merge';

class EnterpriseBot {
    static DEFAULT_CONFIG = {
        chatButtonSelector: 'button',
        localStoragePrefix: ''
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, EnterpriseBot.DEFAULT_CONFIG, config);
        this.chatButton = this.node.querySelector(this.config.chatButtonSelector);
        this.bindEvents();
        this.load();
    }

    bindEvents() {
        ChatObserver.on(ChatObserver.EVENTS.disabled, () => {
            this.hide();
        });
        ChatObserver.on(ChatObserver.EVENTS.offline, () => {
            this.hide();
        });
        ChatObserver.on(ChatObserver.EVENTS.online, () => {
            this.load();
        });

        this.chatButton.addEventListener('click', (event) => {
            event.preventDefault();

            if (!ChatUtil.checkChatReady()) {
                return false;
            } else {
                ChatUtil.showChatbot('button');
            }
        });

        window.addEventListener('message', (e) => {
            if (e.origin === window.location.origin) {
                // If the received message is "minimize-window", display the custom button
                if (e.data && typeof e.data === 'string' && e.data === 'minimize-window') {
                    if (ChatUtil.checkChatReady()) {
                        this.show();
                        this.chatButton.focus();
                    }
                } else if (e.data && typeof e.data === 'string' && (e.data === 'chat-window-open' || e.data === 'close-window')) {
                    this.hide();
                }
            }
        });
    }

    load() {
        try {
            if (
                ChatUtil.checkChatReady() &&
                window.localStorage.getItem(this.config.localStoragePrefix + 'auto_open_suppress') === 'true' &&
                window.localStorage.getItem(this.config.localStoragePrefix + 'tab_exist') === 'true'
            ) {
                this.show();
            }
        } catch (e) {
            //ignore this
        }
    }

    show() {
        this.node.style.display = 'block';
    }

    hide() {
        this.node.style.display = '';
    }
}

export default EnterpriseBot;

import EventEmitter from 'wolfy87-eventemitter';
import DataCollector from '../utils/datacollector/DataCollector';
import merge from 'lodash/merge';
class FormConditionalFields extends EventEmitter {
    static EVENTS = {
        updateConditionalField: 'Form.EVENTS.updateConditionalField'
    };

    static DEFAULT_CONFIG = {
        fields: [],
        pages: [],
        selectors: {
            fieldContainer: '.formfield, .form__text, .formfieldSet'
        },
        classNames: {
            hiddenClass: 'is-hidden'
        }
    };

    constructor(node, config) {
        super();
        this.node = node;
        this.config = merge({}, FormConditionalFields.DEFAULT_CONFIG, config);
        this.fieldsByName = [];
    }

    attach(form) {
        let conditions = this.config.conditions;
        if (!conditions || !conditions.fields || Object.keys(conditions.fields).length === 0) {
            return;
        }

        this.form = form;

        this.fields = this.config.conditions.fields.map((field) => {
            return {
                name: field.name,
                condtype: field.condtype,
                condnegated: field.condnegated,
                conditions: this.resolveConditionalFieldNames(field)
            };
        });

        this.node.addEventListener('change', (event) => this.#onChange(event));

        if (this.config.conditions) {
            this.matchConditions(this.fields);
        }

        // expose events to Data Collector
        DataCollector.subscribe(this, FormConditionalFields.EVENTS);
    }

    #onChange(event) {
        let targetFieldName = event.target.name;
        let affectedFields = this.fields.filter((field) => {
            return targetFieldName in field.conditions;
        });
        this.matchConditions(affectedFields, targetFieldName);

        // Don't emit duplicate event if manually triggered (e.g. by ToggleSync.js CMS-7021)
        if (event.isTrusted) {
            this.emitEvent(FormConditionalFields.EVENTS.updateConditionalField, [{ trigger: targetFieldName }]);
        }
    }

    matchConditions(fields) {
        fields.forEach((field) => {
            let matchConditions = Object.keys(field.conditions).every((condition) => {
                let relatedFields = this.#getFieldsByName(condition);
                const relatedField = relatedFields.find((relatedField) => {
                    if (relatedField.type === 'radio') {
                        return relatedField.matches(':checked');
                    } else {
                        return true;
                    }
                });
                let fieldValue = relatedField?.value;
                return field.conditions[condition] === fieldValue;
            });

            field.match = (matchConditions && !field.condnegated) || (!matchConditions && field.condnegated);
        });

        fields.forEach((field) => this.#updateConditionalField(field));
    }

    #updateConditionalField(field) {
        if (field.condtype === 'visibility') {
            this.#updateFieldVisibility(field);
        }
    }

    #updateFieldVisibility(field) {
        let targetContainer = this.#getFieldsByName(field.name)[0].closest(this.config.selectors.fieldContainer);
        if (!targetContainer) {
            return;
        }
        if (field.match !== false) {
            targetContainer.classList.remove(this.config.classNames.hiddenClass);
        } else {
            targetContainer.classList.add(this.config.classNames.hiddenClass);
        }
    }

    /**
     * Utility function to retrieve field elements by [name] attribute
     * @param name
     * @returns fields
     */
    #getFieldsByName(name) {
        this.fieldsByName[name] =
            this.fieldsByName[name] || Array.from(this.node.querySelectorAll('[name="' + name + '"], [data-name="' + name + '"]'));
        return this.fieldsByName[name];
    }

    /**
     * Resolve the name of all conditional fields (Dropdowns, Radio's, etc).
     * @param field
     * @returns [name] list of names
     */
    resolveConditionalFieldNames(field) {
        let conditionalFieldNames = [];

        // get condition names from single value or from array
        if (field.condfields) {
            field.condfields.forEach((condfield) => {
                conditionalFieldNames[condfield.condname] = conditionalFieldNames[condfield.condname] || condfield.condvalue;
            });
        } else {
            conditionalFieldNames[field.condname] = conditionalFieldNames[field.condname] || field.condvalue;
        }

        return conditionalFieldNames;
    }
}

export default FormConditionalFields;

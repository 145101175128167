import merge from 'lodash/merge';

class AutosuggestAccessibility {
    static DEFAULT_CONFIG = {
        ariaSettings: {
            input: {
                role: 'combobox',
                'aria-autocomplete': 'both'
            },
            list: {
                role: 'listbox'
            }
        }
    };

    static instanceCount = 0;
    optionCount = 0;

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, config, AutosuggestAccessibility.DEFAULT_CONFIG);

        // create a unique id
        this.ariaID = 'as' + AutosuggestAccessibility.instanceCount++;
        this.autosuggestInput = this.node.querySelector(this.config.selectors.input);
        this.#initAriaAttributes();
        this.#addListeners();
    }

    updateExpandedState() {
        let isExpanded = this.opened && this.resultCount > 0;

        this.autosuggestInput.setAttribute('aria-expanded', isExpanded);
    }

    updateActiveDescendant() {
        let activeDescendantId = this.#getActiveDescendantId() || '';

        this.autosuggestInput.setAttribute('aria-activedescendant', activeDescendantId);
    }

    #initAriaAttributes() {
        let menuName = this.config.classNames.menu;
        let ariaSettings = this.config.ariaSettings;

        this.autosuggestInput.setAttribute('role', ariaSettings.input.role);
        this.autosuggestInput.setAttribute('aria-autocomplete', ariaSettings.input['aria-autocomplete']);
        this.autosuggestInput.setAttribute('aria-owns', this.ariaID + '_' + menuName);

        this.autosuggestList = this.node.querySelector('.' + menuName);
        this.autosuggestList.setAttribute('id', this.ariaID + '_' + menuName);
        this.autosuggestList.setAttribute('role', ariaSettings.list.role);

        this.autosuggestInput.setAttribute('aria-expanded', false);
    }

    #addListeners() {
        this.autosuggestInput.addEventListener('typeahead:open', () => {
            this.opened = true;
            this.updateExpandedState();
        });

        this.autosuggestInput.addEventListener('typeahead:close', () => {
            this.opened = false;
            this.updateExpandedState();
        });

        this.autosuggestInput.addEventListener('typeahead:render', (evt) => {
            this.resultCount = evt.detail.args[0]?.length || 0;
            this.updateExpandedState();
        });

        this.autosuggestInput.addEventListener('typeahead:cursorchange', () => {
            this.updateActiveDescendant();
        });

        this.autosuggestInput.addEventListener('typeahead:select', (evt) => {
            const obj = evt.detail.args[0];
            this.autosuggestInput.setAttribute('aria-valuenow', obj.naam);
            this.autosuggestInput.setAttribute('value', obj.naam);
        });
    }

    #getActiveDescendantId() {
        let activeDescendant = this.node.querySelector('.' + this.config.classNames.cursor);
        let id;

        if (activeDescendant) {
            id = activeDescendant.id;

            if (!id) {
                id = this.ariaID + '-option' + this.optionCount++;
                activeDescendant.id = id;
            }

            return id;
        }
        return null;
    }
}

export default AutosuggestAccessibility;

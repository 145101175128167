import Responsive from '../utils/Responsive';

export default function setViewportHeight() {
    set();

    Responsive.on(Responsive.EVENTS.resized, () => {
        set();
    });
}

function set() {
    // First we get the viewport height, and then we multiply it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

import DOMUtils from '../utils/DOMUtils';

class FormValues {
    static getFormValues(container) {
        let result = {};

        if (!container.dataset.novalidate) {
            let inputs = Array.from(container.querySelectorAll('input, select, textarea')).filter((input) => DOMUtils.isVisible(input));
            inputs.forEach((input) => {
                let name = input.name;
                if (!result[name] && name) {
                    result[name] = FormValues.getInputValue(name);
                }
            });
        }

        return result;
    }

    static getInputValue(inputName) {
        let inputs = Array.from(document.getElementsByName(inputName)),
            firstInput = inputs[0];

        if (!firstInput) {
            // actually this should not happen
            return undefined;
        }

        let nodeName = firstInput.nodeName.toLowerCase(),
            inputType = firstInput.type;

        switch (true) {
            case nodeName === 'input' && inputType === 'radio': {
                return (
                    inputs.find((input) => {
                        return input.matches(':checked');
                    })?.value || ''
                );
            }
            case nodeName === 'input' && inputType === 'checkbox': {
                const checkedInputs = inputs.filter((input) => {
                    return input.matches(':checked');
                });
                const checkBoxValues = checkedInputs.map((item) => item.value);
                return checkBoxValues.length === 0 ? '' : checkBoxValues;
            }
            default: {
                return firstInput.value;
            }
        }
    }
}

export default FormValues;

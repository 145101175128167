/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import Utils from '../common/utils';
import WWW from './www';
import DefaultMenu from './default_menu';
import Menu from './menu';
import EventBus from './event_bus';
import Input from './input';
import Typeahead from './typeahead';
import DOMUtils from '../../generic/utils/DOMUtils';

class Plugin {
    constructor(node) {
        this.node = node;
    }

    initialize(node, o, datasets) {
        let www;

        datasets = Utils.isArray(datasets) ? datasets : [datasets];

        o = o || {};
        www = WWW(o.classNames);

        this.attach(node, o, datasets, www);
    }

    attach(inputElement, o, datasets, www) {
        let wrapper, hint, menuElement, defaultHint, defaultMenu, eventBus, input, menu, MenuConstructor;

        // highlight is a top-level config that needs to get inherited
        // from all of the datasets
        Utils.each(datasets, (d) => {
            d.highlight = !!o.highlight;
        });

        wrapper = DOMUtils.createElementFromHTML(www.html.wrapper);
        hint = o.hint;
        menuElement = o.menu;

        defaultHint = o.hint !== false && !hint;
        defaultMenu = o.menu !== false && !menuElement;

        defaultHint && (hint = Plugin.#buildHintFromInput(inputElement, www));
        if (defaultMenu) {
            menuElement = DOMUtils.createElementFromHTML(www.html.menu);
            const css = www.css.menu;
            menuElement.style.position = css.position;
            menuElement.style.top = css.top;
            menuElement.style.left = css.left;
            menuElement.style.zIndex = css.zIndex;
            menuElement.style.display = css.display;
        }

        // hint should be empty on init
        if (hint) {
            hint.value = '';
        }
        inputElement = Plugin.#prepInput(inputElement, www);

        // only apply inline styles and make dom changes if necessary
        if (defaultHint || defaultMenu) {
            const wrapperCss = www.css.wrapper;
            wrapper.style.position = wrapperCss.position;
            wrapper.style.display = wrapperCss.display;
            if (defaultHint) {
                const inputCss = www.css.input;
                inputElement.style.position = inputCss.position;
                inputElement.style.verticalAlign = inputCss.verticalAlign;
                inputElement.style.backgroundColor = inputCss.backgroundColor;
            } else {
                const inputCss = www.css.inputWithNoHint;
                inputElement.style.position = inputCss.position;
                inputElement.style.verticalAlign = inputCss.verticalAlign;
            }
            inputElement.replaceWith(wrapper);
            wrapper.appendChild(inputElement);
            if (defaultHint) {
                inputElement.parentElement.prepend(hint);
            }
            if (defaultMenu) {
                inputElement.parentElement.append(menuElement);
            }
        }

        MenuConstructor = defaultMenu ? DefaultMenu : Menu;

        eventBus = new EventBus({ el: inputElement });
        input = new Input({ hint: hint, input: inputElement }, www);
        menu = new MenuConstructor(
            {
                node: menuElement,
                datasets: datasets
            },
            www
        );

        this.typeaheadInstance = new Typeahead(
            {
                input: input,
                menu: menu,
                eventBus: eventBus,
                minLength: o.minLength,
                autocomplete: o.autocomplete !== false
            },
            www
        );
    }

    close() {
        this.typeaheadInstance.close();
    }

    // mirror jQuery#val functionality: reads opearte on first match,
    // write operates on all matches
    val(newVal) {
        if (!arguments.length) {
            return this.typeaheadInstance.getVal();
        } else {
            this.typeaheadInstance.setVal(newVal);
        }
    }

    static #buildHintFromInput(inputElement, www) {
        const hint = DOMUtils.createElementFromHTML(inputElement.outerHTML);
        hint.classList.add(www.classes.hint);
        hint.readonly = true;
        hint.removeAttribute('id');
        hint.removeAttribute('name');
        hint.removeAttribute('placeholder');
        hint.removeAttribute('required');
        hint.setAttribute('autocomplete', 'off');
        hint.setAttribute('spellcheck', false);
        hint.setAttribute('tabindex', -1);

        hint.style.position = www.css.hint.position;
        hint.style.top = www.css.hint.top;
        hint.style.left = www.css.hint.left;
        hint.style.borderColor = www.css.hint.borderColor;
        hint.style.boxShadow = www.css.hint.boxShadow;
        hint.style.opacity = www.css.hint.opacity;
        const styles = Plugin.#getBackgroundStyles(inputElement);
        hint.style.backgroundAttachment = styles.backgroundAttachment;
        hint.style.backgroundClip = styles.backgroundClip;
        hint.style.backgroundColor = styles.backgroundColor;
        hint.style.backgroundImage = styles.backgroundImage;
        hint.style.backgroundOrigin = styles.backgroundOrigin;
        hint.style.backgroundPosition = styles.backgroundPosition;
        hint.style.backgroundRepeat = styles.backgroundRepeat;
        hint.style.backgroundSize = styles.backgroundSize;
        return hint;
    }

    static #prepInput(inputElement, www) {
        inputElement.classList.add(www.classes.input);
        inputElement.setAttribute('autocomplete', 'off');
        inputElement.setAttribute('spellcheck', false);

        // ie7 does not like it when dir is set to auto
        try {
            !inputElement.getAttribute('dir') && inputElement.setAttribute('dir', 'auto');
        } catch (e) {
            //ignore
        }

        return inputElement;
    }

    static #getBackgroundStyles(el) {
        return {
            backgroundAttachment: getComputedStyle(el).backgroundAttachment,
            backgroundClip: getComputedStyle(el).backgroundClip,
            backgroundColor: getComputedStyle(el).backgroundColor,
            backgroundImage: getComputedStyle(el).backgroundImage,
            backgroundOrigin: getComputedStyle(el).backgroundOrigin,
            backgroundPosition: getComputedStyle(el).backgroundPosition,
            backgroundRepeat: getComputedStyle(el).backgroundRepeat,
            backgroundSize: getComputedStyle(el).backgroundSize
        };
    }
}

export default Plugin;

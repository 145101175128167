import templateForDisplay from '../../jstemplates/form/spacerinput.tpl';
import templateForDelimiter from '../../jstemplates/form/spacerdelimiter.tpl';
import Handlebars from 'handlebars/dist/handlebars';
import DOMUtils from '../../utils/DOMUtils';
import merge from 'lodash/merge';

class InputSpacer {
    static DEFAULT_CONFIG = {
        numberFieldSelector: '[data-spacerInput-element="input"]',
        textFieldSelector: '[data-spacerInput-element="display"]',
        separatorSelector: 'inputSpacer__separator',
        numberFieldClasses: 'inputSpacer__input inputSpacer__input--input',
        delimiter: '-',
        delimiterWidth: 13,
        maxLengthInput: 16
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, InputSpacer.DEFAULT_CONFIG, config);

        this.initSpacer();
        this.attachListeners();
    }

    initSpacer() {
        let parent = this.node.parentNode,
            disTemplate = Handlebars.compile(templateForDisplay),
            displayTemplate = disTemplate(),
            delTemplate = Handlebars.compile(templateForDelimiter),
            templateObj = { delimiterWidth: this.config.delimiterWidth };

        parent.prepend(DOMUtils.createElementFromHTML(displayTemplate));
        this.delimiterTemplate = delTemplate(templateObj).trim();
        this.config.numberFieldClasses.split(' ').forEach((cssClass) => this.node.classList.add(cssClass));
        this.displayField = parent.querySelector(this.config.textFieldSelector);
        this.inputSpacer = this.displayField.parentNode;

        this.switchToDisplay();
    }

    attachListeners() {
        this.node.addEventListener('focus', () => {
            this.inputSpacer.style.display = 'none';
            this.node.style.opacity = 1;
            this.node.style.pointerEvents = 'initial';
        });

        this.node.addEventListener('blur', () => {
            this.switchToDisplay();
        });

        this.inputSpacer.addEventListener('click', () => {
            this.node.focus();
        });
    }

    switchToDisplay() {
        let validity = this.node.validity;

        if (validity && validity.badInput) {
            return false;
        }

        let strippedValue = this.node.value;

        this.formattedValue = this.getDisplayValue(strippedValue);
        this.displayField.replaceChildren();
        this.displayField.innerHTML = this.formattedValue ?? '';
        this.inputSpacer.style.display = '';
        this.node.style.opacity = 0;
        this.node.style.pointerEvents = 'none';
    }

    getDisplayValue(value) {
        let fieldValue;
        if (value.length > 0) {
            fieldValue = value.match(new RegExp('.{1,4}', 'g')).join(this.delimiterTemplate);
        }
        return fieldValue;
    }
}
export default InputSpacer;

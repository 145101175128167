<div class="overlay overlay--videoPlayer">
    <div class="overlay__scrollContainer" >
        <div class="overlay__contentPositioner" tabindex="0">
            <div class="overlay__content overlay__focus" data-contentContainer>

            </div>
            <button href="#" class="closeButton closeButton--white overlay__close">
                <span class="icon">
                    <span class="alt">Sluiten</span>
                </span>
            </button>
        </div>
    </div>
    <div class="darkener"></div>
</div>

import merge from 'lodash/merge';

class Auth {
    static #DEFAULT_CONFIG = {
        processedVariable: 'gsspProcessed',
        userInfoEvent: 'userInfoEvent',
        selectors: {
            loggedIn: '[data-header-module="mijnns-loggedIn"]',
            loggedOut: '[data-header-module="mijnns-loggedOut"]',
            emailShort: '[data-header-module="mijnns-loggedIn"] [data-email="short"]',
            emailLong: '[data-header-module="mijnns-loggedIn"] [data-email="long"]'
        }
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, Auth.#DEFAULT_CONFIG, config);

        this.init();
    }

    init() {
        // To prevent flickering on pageLoad, apply "is-hidden" in the markup
        // hideMenuItem.call(this, this.config.selectors.loggedIn);

        this.addListeners();
    }

    /**
     * Add all listeners
     */
    addListeners() {
        const eventBus = this.#getEventBus();
        eventBus.on(this.config.userInfoEvent, (data) => {
            this.#processUserInfo(data);
        });
    }

    /**
     * Process status based on incoming data from GSSP
     * @param userInfo
     */
    #processUserInfo(userInfo) {
        if (typeof userInfo !== 'undefined' && userInfo !== null && typeof userInfo.emailAddress !== 'undefined') {
            this.#parseEmail(userInfo.emailAddress);

            this.#showMenuItem(this.config.selectors.loggedIn);
            this.#hideMenuItem(this.config.selectors.loggedOut);

            this.#setProcessedState(true);
        } else {
            this.#showMenuItem(this.config.selectors.loggedOut);
            this.#hideMenuItem(this.config.selectors.loggedIn);

            this.node.querySelectorAll(this.config.selectors.emailShort).forEach((el) => (el.textContent = ''));
            this.node.querySelectorAll(this.config.selectors.emailLong).forEach((el) => (el.textContent = ''));

            this.#setProcessedState(false);
        }
    }

    #showMenuItem(selector) {
        this.node.querySelectorAll(selector).forEach((el) => el.classList.remove('is-hidden'));
    }

    #hideMenuItem(selector) {
        this.node.querySelectorAll(selector).forEach((el) => el.classList.add('is-hidden'));
    }

    /**
     * Keep global boolean value of processed state
     * @param value
     */
    #setProcessedState(value) {
        window[this.config.processedVariable] = value;
    }

    /**
     * Parse e-mail field in all given selectors from config
     * @param email
     */
    #parseEmail(email) {
        const emailShort = email.substring(0, 1) + '.';

        // for mobile view
        this.node.querySelectorAll(this.config.selectors.emailShort).forEach((el) => (el.textContent = emailShort));

        // for desktop view and screen readers
        this.node.querySelectorAll(this.config.selectors.emailLong).forEach((el) => (el.textContent = email));
    }

    /**
     * Get a reference to the global eventbus of NS Design
     * @returns {*}
     */
    #getEventBus() {
        return window.eventBus;
    }
}

export default Auth;

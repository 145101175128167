import cartTemplate from '../../jstemplates/cart/template.tpl';
import each from 'lodash/each';
import Handlebars from 'handlebars/dist/handlebars';
import Cookies from 'js-cookie';
import merge from 'lodash/merge';
import DOMUtils from '../../utils/DOMUtils';

class MiniCart {
    static DEFAULT_CONFIG = {
        itemCount: 0,
        cartCookieName: 'MiniBasketCookie',
        classNames: {
            basketIconClass: 'icon--cart',
            cartFilled: 'miniCart--filled'
        }
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, MiniCart.DEFAULT_CONFIG, config);

        this.init();
    }

    init() {
        let cookieValue = this.getCookieValue(),
            itemCount = this.config.itemCount;

        if (cookieValue && cookieValue.basket) {
            itemCount = cookieValue.basket.itemCount;
        }

        //Show cart if it's filled
        if (itemCount > 0) {
            let cartObj = {
                cartItems: itemCount,
                cartAmountText: this.getItemLabelText(this.config.labels, itemCount),
                cartUrl: this.config.cartUrl,
                shoppingCartText: this.config.labels.shoppingCartText,
                shoppingCartIcon: this.getShoppingCartIcon(),
                closeText: this.config.labels.closeText
            };

            this.node.classList.add(this.config.classNames.cartFilled);
            this.fillCartValues(cartObj);
        }
    }

    getCookieValue() {
        try {
            return JSON.parse(Cookies.get(this.config.cartCookieName));
        } catch (e) {
            return null;
        }
    }

    getItemLabelText(labels, itemCount) {
        let labelText = itemCount > 1 ? 'articlePlural' : 'articleSingular';

        each(labels, (value, key) => {
            if (key === labelText) {
                labelText = value;
            }
        });

        return labelText;
    }

    getShoppingCartIcon() {
        return this.config.classNames.basketIconClass;
    }

    fillCartValues(cartObj) {
        let cartContent = Handlebars.compile(cartTemplate);
        this.node.replaceChildren();
        this.node.append(DOMUtils.createElementFromHTML(cartContent(cartObj)));
    }
}

export default MiniCart;

/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */

// inspired by https://github.com/jharding/boomerang
class EventEmitter {
    static #splitter = /\s+/;
    static #nextTick = EventEmitter.#getNextTick();

    onSync(types, cb, context) {
        return this.#on('sync', types, cb, context);
    }

    onAsync(types, cb, context) {
        return this.#on('async', types, cb, context);
    }

    off(types) {
        let type;

        if (!this._callbacks) {
            return this;
        }

        types = types.split(EventEmitter.#splitter);

        while ((type = types.shift())) {
            delete this._callbacks[type];
        }

        return this;
    }

    trigger(types) {
        let type, callbacks, args, syncFlush, asyncFlush;

        if (!this._callbacks) {
            return this;
        }

        types = types.split(EventEmitter.#splitter);
        args = [].slice.call(arguments, 1);

        while ((type = types.shift()) && (callbacks = this._callbacks[type])) {
            syncFlush = EventEmitter.#getFlush(callbacks.sync, this, [type].concat(args));
            asyncFlush = EventEmitter.#getFlush(callbacks.async, this, [type].concat(args));

            syncFlush() && EventEmitter.#nextTick(asyncFlush);
        }

        return this;
    }

    #on(method, types, cb, context) {
        let type;

        if (!cb) {
            return this;
        }

        types = types.split(EventEmitter.#splitter);
        cb = context ? EventEmitter.#bindContext(cb, context) : cb;

        this._callbacks = this._callbacks || {};

        while ((type = types.shift())) {
            this._callbacks[type] = this._callbacks[type] || { sync: [], async: [] };
            this._callbacks[type][method].push(cb);
        }

        return this;
    }

    static #getFlush(callbacks, context, args) {
        return flush;

        function flush() {
            let cancelled;

            for (let i = 0, len = callbacks.length; !cancelled && i < len; i += 1) {
                // only cancel if the callback explicitly returns false
                cancelled = callbacks[i].apply(context, args) === false;
            }

            return !cancelled;
        }
    }

    static #getNextTick() {
        let nextTickFn;

        // IE10+
        if (window.setImmediate) {
            nextTickFn = function nextTickSetImmediate(fn) {
                window.setImmediate(() => {
                    fn();
                });
            };
        }

        // old browsers
        else {
            nextTickFn = function nextTickSetTimeout(fn) {
                setTimeout(() => {
                    fn();
                }, 0);
            };
        }

        return nextTickFn;
    }

    static #bindContext(fn, context) {
        return fn.bind
            ? fn.bind(context)
            : function () {
                  fn.apply(context, [].slice.call(arguments, 0));
              };
    }
}

export default EventEmitter;

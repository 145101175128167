import merge from 'lodash/merge';

export default function publishTagmanagementEvent(category, eventname, data) {
    data = typeof data === 'object' ? data : {};

    // Send events to DDQ
    if (typeof window.nsDataQueue === 'object' && typeof category !== 'string' && typeof eventname === 'string') {
        let ddqData = merge(
            {
                event: eventname
            },
            data
        );

        window.nsDataQueue.push(ddqData);
    }
}

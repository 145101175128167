import EventEmitter from 'wolfy87-eventemitter';
import Handlebars from 'handlebars/dist/handlebars';
import template from '../jstemplates/clearbutton.tpl';
import merge from 'lodash/merge';
import DOMUtils from '../utils/DOMUtils';
import EventNamespaceHandlerUtils from '../utils/EventNamespaceHandlerUtils';

class ClearableInput extends EventEmitter {
    static EVENTS = {
        cleared: 'ClearableInput.EVENTS.cleared'
    };

    static DEFAULT_CONFIG = {
        hasClass: 'has-input',

        containerSelector: '.searchForm__field, .formfield__autosuggest',
        buttonSelector: '.clearButton',
        wrapSelector: '.searchForm__clear',
        clearFieldText: 'Veld leegmaken'
    };

    constructor(node, config) {
        super();
        this.node = node;

        this.config = merge({}, ClearableInput.DEFAULT_CONFIG, config);

        // create the wrap with button
        this.create();

        this.container = this.node.closest(this.config.containerSelector);
        this.clearButton = this.container.querySelector(this.config.buttonSelector);

        this.listenForInput();
        this.listenForClear();
    }

    listenForInput() {
        EventNamespaceHandlerUtils.addEventListener(this.node, 'input.clearableInput', () => this.toggleClearButton());
    }

    toggleClearButton() {
        if (this.node.value.length > 0) {
            this.showClearButton();
        } else {
            this.hideClearButton();
        }
    }

    showClearButton() {
        this.container.classList.add(this.config.hasClass);
    }

    hideClearButton() {
        this.container.classList.remove(this.config.hasClass);
    }

    listenForClear() {
        EventNamespaceHandlerUtils.addEventListener(this.clearButton, 'click.clearableInput', (e) => this.clearButtonHandler(e));
    }

    clearButtonHandler(e) {
        this.node.nodeValue = '';
        this.node.focus();

        // we will let other objects know that the field has been cleared
        this.emitEvent(ClearableInput.EVENTS.cleared);
        this.hideClearButton();

        e.preventDefault();
    }

    destroy() {
        EventNamespaceHandlerUtils.removeEventListener(this.node, 'input.clearableInput');
        EventNamespaceHandlerUtils.removeEventListener(this.clearButton, 'click.clearableInput');
        this.clearButton = null;
        this.container = null;
        this.node = null;
        this.node = null;
        this.config = null;
    }

    create() {
        // compile the template
        const buttonTemplate = Handlebars.compile(template);
        // define our context
        const context = { wrapSelector: this.config.wrapSelector.substring(1), clearFieldText: this.config.clearFieldText };
        // get html
        const html = buttonTemplate(context);
        const element = DOMUtils.createElementFromHTML(html);
        // inject the html in the dom
        this.node.after(element);
    }
}

export default ClearableInput;

<div style="display: block; position: relative; max-width: 100%;" class="is-hidden">
    <div style="padding-top: 56.25%;">
        <video id="{{id}}"
               data-video-id="{{videoId}}"
               data-account="{{accountId}}"
               data-player="{{player}}"
               data-embed="default"
               data-application-id
               class="video-js"
               controls
               autoplay="true"
               preload="true">
        </video>
    </div>
</div>

import gsap from 'gsap';
import EventEmitter from 'wolfy87-eventemitter';
import DOMUtils from '../utils/DOMUtils';

class Darkener extends EventEmitter {
    static CONFIG = {
        modes: {
            header: 'darkener--header',
            planbar: 'darkener--planbar',
            datepicker: 'darkener--datepicker'
        }
    };
    EVENTS = {
        hideIsDone: 'Darkener.EVENTS.hideIsDone'
    };

    static NOOP_TWEEN = gsap.delayedCall(0, () => {
        //NOOP
    });

    constructor() {
        super();
        this.mode = undefined;
        this.isShowing = false;
        this.clientList = [];
        this.create();
    }

    show(clientId, duration) {
        clientId = clientId || 'unknown';

        let animationDuration = 0.5 || duration,
            clientIndex = this.clientList.indexOf(clientId);

        if (clientIndex > -1) {
            return Darkener.NOOP_TWEEN;
        }

        this.clientList.push(clientId);

        if (this.isShowing) {
            return Darkener.NOOP_TWEEN;
        }

        this.isShowing = true;
        document.body.append(this.node);
        return gsap.to(this.node, animationDuration, { opacity: 1 });
    }

    hide(clientId, duration) {
        clientId = clientId || 'unknown';

        let animationDuration = 0.3 || duration,
            clientIndex = this.clientList.indexOf(clientId);

        if (clientIndex === -1) {
            return Darkener.NOOP_TWEEN;
        }

        this.clientList.splice(clientIndex, 1);

        if (this.clientList.length > 0 || !this.isShowing) {
            return Darkener.NOOP_TWEEN;
        }

        this.isShowing = false;

        return gsap.to(this.node, animationDuration, {
            opacity: 0,
            onComplete: () => {
                this.node.remove();
                this.emitEvent(this.EVENTS.hideIsDone);
            }
        });
    }

    setMode(modeKey) {
        if (this.mode === modeKey) {
            return;
        }

        if (this.mode) {
            this.node.classList.remove(this.mode);
        }

        let mode = Darkener.CONFIG.modes[modeKey];
        if (mode) {
            this.mode = mode;
            this.node.classList.add(this.mode);
        }
    }

    create() {
        this.node = DOMUtils.createElementFromHTML('<div class="darkener"></div>');
        this.node.style.opacity = 0;
    }
}

export default new Darkener();

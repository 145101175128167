/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */

import Utils from '../common/utils';
import EventEmitter from './event_emitter';
import Dataset from './dataset';
import DOMUtils from '../../generic/utils/DOMUtils';

class Menu extends EventEmitter {
    #clickHandler;

    constructor(o, www) {
        super();
        o = o || {};

        if (!o.node) {
            Utils.error('node is required');
        }

        www.mixin(this);

        this.node = o.node;

        // the latest query #update was called with
        this.query = null;
        this.datasets = o.datasets.map((dataset) => this.initializeDataset(dataset, www));
    }

    initializeDataset(oDataset, www) {
        let node;
        if (oDataset.node) {
            node = this.node.querySelector(oDataset.node);
        }
        if (!node) {
            node = DOMUtils.createElementFromHTML('<div />');
            this.node.append(node);
        }
        oDataset.node = node;
        return new Dataset(oDataset, www);
    }

    onSelectableClick(e) {
        this.trigger('selectableClicked', e.target);
    }

    onRendered(type, dataset, suggestions, async) {
        if (this.allDatasetsEmpty()) {
            this.node.classList.add(this.classes.empty);
        } else {
            this.node.classList.remove(this.classes.empty);
        }
        this.trigger('datasetRendered', dataset, suggestions, async);
    }

    onCleared() {
        if (this.allDatasetsEmpty()) {
            this.node.classList.add(this.classes.empty);
        } else {
            this.node.classList.remove(this.classes.empty);
        }
        this.trigger('datasetCleared');
    }

    propagate() {
        this.trigger.apply(this, arguments);
    }

    allDatasetsEmpty() {
        return Utils.every(this.datasets, isDatasetEmpty);

        function isDatasetEmpty(dataset) {
            return dataset.isEmpty();
        }
    }

    getSelectables() {
        return Array.from(this.node.querySelectorAll(this.selectors.selectable));
    }

    removeCursor() {
        let selectable = this.getActiveSelectable();
        if (selectable) {
            selectable.classList.remove(this.classes.cursor);
        }
    }

    ensureVisible(el) {
        let elTop, elBottom, nodeScrollTop, nodeHeight;
        let style = getComputedStyle(el);
        elTop = el.getBoundingClientRect().y;
        elBottom = elTop + el.getBoundingClientRect().height + parseFloat(style.marginTop) + parseFloat(style.marginBottom);
        nodeScrollTop = this.node.scrollY;
        nodeHeight =
            this.node.getBoundingClientRect().height +
            parseInt(getComputedStyle(this.node)['paddingTop'], 10) +
            parseInt(getComputedStyle(this.node)['paddingBottom'], 10);

        if (elTop < 0) {
            this.node.scrollTo(this.node.scrollX, nodeScrollTop + elTop);
        } else if (nodeHeight < elBottom) {
            this.node.scrollTo(this.node.scrollX, nodeScrollTop + (elBottom - nodeHeight));
        }
    }

    bind() {
        let that = this,
            onSelectableClick;

        onSelectableClick = this.onSelectableClick.bind(this);
        this.#clickHandler = DOMUtils.addEventListener(this.node, 'click', this.selectors.selectable, onSelectableClick);

        Utils.each(this.datasets, (dataset) => {
            dataset
                .onSync('asyncRequested', that.propagate, that)
                .onSync('asyncCanceled', that.propagate, that)
                .onSync('asyncReceived', that.propagate, that)
                .onSync('rendered', that.onRendered, that)
                .onSync('cleared', that.onCleared, that);
        });

        return this;
    }

    isOpen() {
        return this.node.classList.contains(this.classes.open);
    }

    open() {
        this.node.classList.add(this.classes.open);
    }

    close() {
        this.node.classList.remove(this.classes.open);
        this.removeCursor();
    }

    setLanguageDirection(dir) {
        this.node.setAttribute('dir', dir);
    }

    selectableRelativeToCursor(delta) {
        let selectables, oldCursor, oldIndex, newIndex;

        oldCursor = this.getActiveSelectable();
        selectables = this.getSelectables();

        // shifting before and after modulo to deal with -1 index
        oldIndex = oldCursor ? selectables.indexOf(oldCursor) : -1;
        newIndex = oldIndex + delta;
        newIndex = ((newIndex + 1) % (selectables.length + 1)) - 1;

        // wrap new index if less than -1
        newIndex = newIndex < -1 ? selectables.length - 1 : newIndex;

        return newIndex === -1 ? null : selectables[newIndex];
    }

    setCursor(selectable) {
        this.removeCursor();

        if (selectable) {
            selectable.classList.add(this.classes.cursor);

            // in the case of scrollable overflow
            // make sure the cursor is visible in the node
            this.ensureVisible(selectable);
        }
    }

    getSelectableData(el) {
        return el ? Dataset.extractData(el) : null;
    }

    getActiveSelectable() {
        let selectable = this.getSelectables().find((selectable) => selectable.matches(this.selectors.cursor));
        return selectable ?? null;
    }

    getTopSelectable() {
        let selectables = this.getSelectables();

        return selectables.length > 0 ? selectables[0] : null;
    }

    update(query) {
        let isValidUpdate = query !== this.query;

        // don't update if the query hasn't changed
        if (isValidUpdate) {
            this.query = query;
            Utils.each(this.datasets, updateDataset);
        }

        return isValidUpdate;

        function updateDataset(dataset) {
            dataset.update(query);
        }
    }

    empty() {
        Utils.each(this.datasets, clearDataset);

        this.query = null;
        this.node.classList.add(this.classes.empty);

        function clearDataset(dataset) {
            dataset.clear();
        }
    }
}

export default Menu;

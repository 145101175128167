class DebugModeManager {
    constructor() {
        document.addEventListener('keypress', (e) => {
            if (document.activeElement && document.activeElement.matches(':read-write')) {
                return;
            }

            if (e.key === 'd' || e.key === 'D') {
                document.body.classList.toggle('debugMode');
            }
        });
    }
}
export default new DebugModeManager();

import FormValidation from '../FormValidation';
import EventEmitter from 'wolfy87-eventemitter';
import FormStepIndicator from '../FormStepIndicator';
import FormConditionalFields from '../FormConditionalFields';
import FormPages from '../FormPages';
import merge from 'lodash/merge';

class Form extends EventEmitter {
    static DEFAULT_CONFIG = {
        fields: [],
        validationURL: '',
        conditions: {
            validateOnInit: true
        }
    };

    constructor(node, config) {
        super();

        this.node = node;
        this.config = merge({}, Form.DEFAULT_CONFIG, config);

        this.conditionalFields = new FormConditionalFields(this.node, this.config);
        this.conditionalFields.attach(this);

        this.formStepIndicator = new FormStepIndicator(this.node, this.config);
        this.formStepIndicator.attach(this);

        this.validation = new FormValidation(this.node, this.config);
        this.validation.attach(this);

        this.pages = new FormPages(this.node, this.config);
        this.pages.attach(this);
    }

    getValidation() {
        return this.validation;
    }

    updateStepIndicator() {
        return this.formStepIndicator;
    }
}

export default Form;

import merge from 'lodash/merge';
class OtherValue {
    static DEFAULT_CONFIG = {
        textInput: '.textInput'
    };
    constructor(node, config) {
        this.config = merge({}, OtherValue.DEFAULT_CONFIG, config);

        const groupItemInput = node.querySelector(this.config.groupItemInput);
        if (!groupItemInput) {
            throw new Error('$groupItemInput is not defined! Specify it from a subclass or through the data-config attribute in HTML.');
        }

        const textInput = node.querySelector(this.config.textInput);

        textInput.addEventListener('click', () => {
            groupItemInput.checked = true;
        });

        ['change', 'click'].forEach((event) =>
            groupItemInput.addEventListener(event, () => {
                if (groupItemInput.checked) {
                    textInput.focus();
                }
            })
        );
    }
}

export default OtherValue;

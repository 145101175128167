class DOMUtils {
    static createElementFromHTML(htmlString) {
        const div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        return div.firstChild;
    }

    static offset(el) {
        const box = el.getBoundingClientRect();
        const docElem = document.documentElement;
        return {
            top: box.top + window.scrollY - docElem.clientTop,
            left: box.left + window.scrollX - docElem.clientLeft
        };
    }

    static outerWidthWithMargins(el) {
        const style = getComputedStyle(el);

        return el.getBoundingClientRect().width + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    }

    static isVisible(el) {
        return !!(el.offsetWidth || el.offsetHeight);
    }

    static addEventListener(el, eventName, selector, eventHandler) {
        const wrappedHandler = (e) => {
            if (!e.target) return;
            const el = e.target.closest(selector);
            if (el) {
                const newEvent = Object.create(e, {
                    target: {
                        value: el
                    }
                });
                eventHandler(newEvent);
            }
        };
        el.addEventListener(eventName, wrappedHandler);
        return wrappedHandler;
    }

    static serializeArray(form) {
        const arr = [];
        Array.prototype.slice.call(form.elements).forEach((field) => {
            if (!field.name || field.disabled || ['file', 'reset', 'submit', 'button'].indexOf(field.type) > -1) return;
            if (field.type === 'select-multiple') {
                Array.prototype.slice.call(field.options).forEach((option) => {
                    if (!option.selected) return;
                    arr.push({
                        name: field.name,
                        value: option.value
                    });
                });
                return;
            }
            if (['checkbox', 'radio'].indexOf(field.type) > -1 && !field.checked) return;
            arr.push({
                name: field.name,
                value: field.value
            });
        });
        return arr;
    }

    static parents(el, selector) {
        const parents = [];
        while ((el = el.parentNode) && el !== document) {
            if (!selector || el.matches(selector)) parents.push(el);
        }
        return parents;
    }
}

export default DOMUtils;

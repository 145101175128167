import publishTagmanagementEvent from '../publishTagmanagementEvent';
import merge from 'lodash/merge';

class FilterDataCollector {
    static DEFAULT_CONFIG = {
        selectors: {
            checkbox: 'app-filtertype-checkbox .checkbox__input',
            clearButton: 'app-resetform .button',
            showResultsButton: 'app-show-results .overlay__close',
            resultsSummary: 'app-results-summary',
            dataSelect: 'data-count'
        }
    };

    constructor(node, config) {
        this.node = node;
        config = config || {};
        this.config = merge({}, FilterDataCollector.DEFAULT_CONFIG, config);
        this.init();
    }

    init() {
        this.addListeners();
    }

    countResults() {
        return document.querySelector(this.config.selectors.resultsSummary)?.querySelector(`[${this.config.selectors.dataSelect}]`)?.dataset
            .count;
    }

    addListeners() {
        let eventBus = window.eventBus;

        eventBus.addListener('filterApp.initFilter', () => {
            this.#publishData('onload', 'init');
        });

        eventBus.addListener('filterApp.checkFilter', (checkbox) => {
            this.#publishData('checkfilter', checkbox);
        });

        eventBus.addListener('filterApp.clearFilter', () => {
            this.#publishData('clearfilter', 'clearButton');
        });

        eventBus.addListener('filterApp.showResults', () => {
            this.#publishData('showresults', 'showResultsButton');
        });
    }

    #publishData(eventName, trigger) {
        //set time out for results
        setTimeout(() => {
            /* eslint-disable camelcase */
            let interactionEvent = {
                interaction_type: 'filter',
                interaction_action: eventName,
                interaction_location: 'body',
                interaction_data: {
                    trigger: trigger,
                    filters: this.#getFilterState(),
                    results: this.countResults()
                }
            };
            /* eslint-enable */
            publishTagmanagementEvent(null, 'website_interaction', interactionEvent);
        }, 100);
    }

    #getFilterState() {
        let checkedInputs = Array.from(document.querySelectorAll(this.config.selectors.checkbox + ':checked'));
        checkedInputs = checkedInputs.map((input) => {
            return input.id || null;
        });
        return checkedInputs || [];
    }
}

export default FilterDataCollector;

/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */

import Menu from './menu';

class DefaultMenu extends Menu {
    constructor(o, www) {
        super(o, www);
    }

    open() {
        // only display the menu when there's something to be shown
        !this.allDatasetsEmpty() && this.#show();
        return super.open();
    }

    close() {
        this.#hide();
        super.close();
    }

    onRendered(type, dataset, suggestions, async) {
        if (this.allDatasetsEmpty()) {
            this.#hide();
        } else {
            this.isOpen() && this.#show();
        }

        return super.onRendered(type, dataset, suggestions, async);
    }

    onCleared() {
        if (this.allDatasetsEmpty()) {
            this.#hide();
        } else {
            this.isOpen() && this.#show();
        }

        return super.onCleared();
    }

    setLanguageDirection(dir) {
        if (dir === 'ltr') {
            this.node.style.left = this.css.ltr.left;
            this.node.style.right = this.css.ltr.right;
        } else {
            this.node.style.left = this.css.rtl.left;
            this.node.style.right = this.css.rtl.right;
        }
        return super.setLanguageDirection(dir);
    }

    #hide() {
        this.node.style.display = 'none';
    }

    #show() {
        this.node.style.display = 'block';
    }
}

export default DefaultMenu;

// thanks http://davidwalsh.name/detect-scrollbar-width
export default function getScrollbarWidth() {
    // Create the measurement node
    const scrollElement = document.createElement('div');
    scrollElement.className = 'scrollbarMeasure';
    document.body.appendChild(scrollElement);

    // Get the scrollbar width
    const scrollbarWidth = scrollElement.offsetWidth - scrollElement.clientWidth;

    // Delete the DIV
    document.body.removeChild(scrollElement);

    return scrollbarWidth;
}

import merge from 'lodash/merge';
class Captcha {
    constructor(node, config) {
        this.config = merge({}, {}, config);

        let src = node.getAttribute('href');
        let modernBrowser = typeof Audio !== 'undefined' && new Audio().canPlayType('audio/x-wav');
        let audioObject = null;

        if (modernBrowser) {
            audioObject = document.createElement('audio');
            audioObject.src = src;
            audioObject.preload = 'none';
        }

        node.addEventListener('click', (e) => {
            e.preventDefault();
            try {
                if (modernBrowser) {
                    // In modern browsers use the <audio> support
                    if (audioObject.load) {
                        audioObject.load();
                    }
                    audioObject.play();
                } else {
                    // IE11 has no .wav support using audio, so fallback with ancient <bgsound> tag
                    audioObject = audioObject || document.createElement('bgsound');
                    audioObject.src = src;

                    // Add it to body
                    document.body.appendChild(audioObject);
                }
            } catch (err) {
                //ignore
            }
            return false;
        });
    }
}
export default Captcha;

import StepIndicatorTemplate from '../jstemplates/form/stepsindicator/full.tpl';
import StepDotTemplate from '../jstemplates/form/stepsindicator/dot.tpl';
import headingTemplate from '../jstemplates/form/stepsindicator/heading.tpl';
import Responsive from '../utils/Responsive';
import Handlebars from 'handlebars/dist/handlebars';
import merge from 'lodash/merge';
import DOMUtils from '../utils/DOMUtils';

class FormStepIndicator {
    static DEFAULT_CONFIG = {
        selectors: {
            formPageContainer: '.formPageContainer',
            stepsIndicator: '.stepsIndicator',
            dot: '.stepsIndicator__dot',
            stepsHolder: '.stepsIndicator__stepHolder',
            progressBarHolder: '.stepsIndicator__progressBarHolder',
            progressBar: '.stepsIndicator__progressBar',
            statusHolder: '.stepsIndicator__statusHolder',
            stepsIndicatorHeader: '.stepsIndicator__header',
            heading: '.stepsIndicator__heading'
        },
        classNames: {
            heading: 'stepsIndicator__heading',
            isOverflowing: 'is-overflowing',
            isCurrent: 'is-current',
            dotDone: 'stepsIndicator__dot--done'
        },
        statusLabel: '',
        minimalStepDistance: 5
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, FormStepIndicator.DEFAULT_CONFIG, config);

        this.formPageContainer = this.node.querySelector(this.config.selectors.formPageContainer);
        this.pageHeadings = this.config.pageHeadings;
        this.pageNodes = this.node.querySelectorAll('.formPage');
    }

    attach() {
        let hasPages = !this.isSinglePage();

        if (hasPages) {
            this.#createStepIndicator();
            Responsive.on(Responsive.EVENTS.resized, () => {
                this.updateSteps();
            });
        }
    }

    setSteps(stepsData) {
        if (this.isSinglePage()) {
            return false;
        }

        this.stepsCount = stepsData.pagesInFlow + 1;
        this.currentStepIndex = stepsData.currentIndex;

        // Only (re)build the steps if the number of pages in the flow changes.
        if (this.prevStepsCount !== this.stepsCount) {
            this.buildSteps();
        } else {
            this.updateSteps(true);
        }

        this.prevStepsCount = this.stepsCount;
    }

    buildSteps() {
        let steps = [];
        for (let i = 0; i < this.stepsCount; i++) {
            let number = i + 1;
            if (this.stepsCount === number) {
                number = '';
            }
            steps.push(DOMUtils.createElementFromHTML(this.stepDotTpl(number)));
        }

        this.stepsHolder.replaceChildren();
        steps.forEach((step) => this.stepsHolder.append(step));

        this.dots = Array.from(this.stepsHolder.querySelectorAll(this.config.selectors.dot));
        this.updateSteps();
    }

    #getStepDistance() {
        let totalSteps = this.stepsCount,
            dotWidth = this.dots[0].getBoundingClientRect().width,
            allDotsWidth = totalSteps * dotWidth;

        return (this.#getStepsContainerWidth() - allDotsWidth) / parseInt(totalSteps - 1);
    }

    updateSteps() {
        let totalSteps = this.stepsCount,
            stepDistance = this.#getStepDistance(),
            minStepDistance = this.config.minimalStepDistance,
            setDotStates = (step) => {
                const dot = this.dots[step];
                if (step <= this.currentStepIndex) {
                    dot.classList.add(this.config.classNames.dotDone);
                    // get the left offset of the "current step" dot
                    // so we can use it to draw the blue line.
                    // And update the status Bar (accessibility, aria-live)
                    if (step === this.currentStepIndex) {
                        this.#updateProgressBar(dot.offsetLeft);
                        this.#updateHeader();
                        this.#updateStatusContainer();
                    }
                } else {
                    dot.classList.remove(this.config.classNames.dotDone);
                }
            };

        // Set the minimal distance between dots
        if (stepDistance < minStepDistance) {
            stepDistance = minStepDistance;
        }
        for (let step = 0; step < totalSteps; step++) {
            const dot = this.dots[step];
            dot.style.marginRight = totalSteps - 1 === step ? 0 : stepDistance + 'px';
            setDotStates(step);
        }

        this.containerWidth = this.#getStepsContainerWidth(this);

        this.#checkContainerOverflow(stepDistance);
    }

    #updateStatusContainer() {
        let statusText = this.#getStatusData();
        this.statusHolder.replaceChildren();
        this.statusHolder.innerHTML = statusText;
    }

    #getStatusData() {
        let currentStep = parseInt(this.currentStepIndex + 1),
            totalSteps = parseInt(this.stepsCount - 1);
        return this.indicatorTemplate({ num: currentStep, total: totalSteps });
    }

    #updateHeader() {
        let pageTitle = this.formPageContainer.querySelector('.is-active').dataset.pagetitle,
            createTitle = () => {
                this.stepsIndicatorHeader.replaceChildren();
                this.stepsIndicatorHeader.append(DOMUtils.createElementFromHTML(this.headingTpl({ title: pageTitle })));
            };

        if (pageTitle) {
            createTitle();
        } else {
            this.stepsIndicatorHeader.replaceChildren();
        }
    }

    #checkContainerOverflow(stepDistance) {
        let totalStepsWidth = this.stepsCount * this.dots[0].getBoundingClientRect().width,
            contentWidth = totalStepsWidth + parseInt(stepDistance * (this.stepsCount - 1));

        this.progressBarHolder.removeAttribute('style');
        this.stepsHolder.removeAttribute('style');

        if (contentWidth > this.containerWidth) {
            this.stepsHolder.style.width = contentWidth + 'px';
            this.progressBarHolder.style.width = contentWidth - stepDistance + 'px';
        }
    }

    #getStepsContainerWidth() {
        return this.node.querySelector(this.config.selectors.stepsIndicator).getBoundingClientRect().width;
    }

    #updateProgressBar(offsetLeft) {
        this.progressBar.style.width = offsetLeft + 'px';
    }

    isSinglePage() {
        return this.pageNodes.length <= 1;
    }

    #createStepIndicator() {
        let indicatorTemplate = Handlebars.compile(StepIndicatorTemplate),
            stepIndicator = DOMUtils.createElementFromHTML(indicatorTemplate(this.config));
        this.formPageContainer.prepend(stepIndicator);

        this.statusText = this.config.statusLabel;
        this.indicatorTemplate = Handlebars.compile(this.statusText);
        this.stepDotTpl = Handlebars.compile(StepDotTemplate);
        this.headingTpl = Handlebars.compile(headingTemplate);

        this.stepsHolder = this.node.querySelector(this.config.selectors.stepsHolder);
        this.stepsIndicator = this.node.querySelector(this.config.selectors.stepsIndicator);

        this.progressBarHolder = this.stepsIndicator.querySelector(this.config.selectors.progressBarHolder);
        this.progressBar = this.stepsIndicator.querySelector(this.config.selectors.progressBar);
        this.statusHolder = this.stepsIndicator.querySelector(this.config.selectors.statusHolder);
        this.stepsIndicatorHeader = this.stepsIndicator.querySelector(this.config.selectors.stepsIndicatorHeader);
    }
}

export default FormStepIndicator;

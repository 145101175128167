import gsap from 'gsap/gsap-core';
import Handlebars from 'handlebars/dist/handlebars';
import Template from '../../jstemplates/balloon.tpl';
import merge from 'lodash/merge';
import DOMUtils from '../../utils/DOMUtils';

class ToolTip {
    static DEFAULT_CONFIG = {
        toolTipSelector: '.toolTip',
        balloonContainerClass: 'toolTip__container',
        balloonBodyClass: 'toolTip__body',
        balloonHiddenClass: 'toolTip--hidden',
        activeTooltipClass: 'is-active'
    };

    static instanceCount = 0;

    #onClick = (e) => this.clickHandler(e);
    #onBodyClick = (event) => {
        let clickedOutsideBalloon = !this.node.contains(event.target);
        if (clickedOutsideBalloon) {
            this.closeTooltip();
        }
    };

    constructor(node, config) {
        this.node = node;
        this.oldVersion = this.node.nodeName === 'A';
        this.trigger = this.oldVersion ? this.node : this.node.querySelector('button');
        this.contentElement = this.node.querySelector('[data-tooltip-content]');
        this.content = this.oldVersion ? this.node.textContent : this.contentElement.textContent;
        if (this.oldVersion) {
            this.node.replaceChildren();
        } else {
            this.contentElement.replaceChildren();
            this.contentElement.classList.remove(...this.contentElement.classList);
        }
        this.config = merge({}, ToolTip.DEFAULT_CONFIG, config, { content: this.content.trim() });
        // create a unique id
        this.tooltipID = 'tooltip' + ToolTip.instanceCount++;
        this.expandedByClick = false;
        this.isHoverEnabled = true;
        this.attachListeners();
    }

    attachListeners() {
        // always prevent default on clicks
        this.trigger.addEventListener('click', (e) => {
            e.preventDefault();
        });
        // this handler is not always there
        this.trigger.addEventListener('click', this.#onClick);
        this.trigger.addEventListener('keydown', (e) => this.keydownHandler(e));
        ['mouseenter', 'mouseleave'].forEach((eventName) => {
            this.trigger.addEventListener(eventName, (e) => this.mouseHandler(e));
        });
    }

    mouseHandler(event) {
        // Only hover event if the balloon is not visible
        if (this.isHoverEnabled) {
            switch (event.type) {
                case 'mouseenter':
                    this.openTooltip();
                    break;
                case 'mouseleave':
                    this.closeTooltip();
            }
        }
    }

    clickHandler(event) {
        event.stopPropagation();
        if (!this.oldVersion && this.expandedByClick) {
            this.closeTooltip();
        } else {
            this.attachBodyListener();
            this.isHoverEnabled = false;
            if (!this.oldVersion) {
                this.expandedByClick = true;
                this.trigger.setAttribute('aria-pressed', 'true');
            }
            //The setTimeout is only to improve screenreader on Android
            window.setTimeout(() => {
                this.openTooltip();
            }, 250);
        }
    }

    keydownHandler(e) {
        if (!this.oldVersion && this.expandedByClick) {
            if (e.key === 'Escape') {
                this.closeTooltip();
            }
        }
    }

    attachBodyListener() {
        if (this.oldVersion) {
            this.trigger.removeEventListener('click', this.#onClick);
        }

        //Normally you would assign this handler to the body element. On android however the screenreader then starts saying "error, ongeldige invoer" when using the tooltip.
        document.querySelectorAll('body > *').forEach((element) => {
            element.addEventListener('click', this.#onBodyClick);
        });
    }

    removeBodyListener() {
        document.querySelectorAll('body > *').forEach((element) => {
            element.removeEventListener('click', this.#onBodyClick);
        });
        if (this.oldVersion) {
            this.trigger.addEventListener('click', this.#onClick);
        }
    }

    openTooltip() {
        this.createBalloon();
        this.toolTipContainer.classList.remove(this.config.balloonHiddenClass);

        this.trigger.classList.add(this.config.activeTooltipClass);
        this.node.classList.add(this.config.activeTooltipClass);

        gsap.to(this.toolTipContainer, 0.15, { opacity: 1 });
    }

    closeTooltip() {
        this.removeBodyListener();
        this.isHoverEnabled = true;
        this.expandedByClick = false;
        if (!this.oldVersion) {
            this.trigger.setAttribute('aria-pressed', 'false');
        }
        let hideBalloon = () => {
            this.trigger.classList.remove(this.config.activeTooltipClass);
            this.node.classList.remove(this.config.activeTooltipClass);
            if (this.oldVersion) {
                this.toolTipContainer.classList.add(this.config.balloonHiddenClass);
                this.node.replaceChildren();
                this.toolTipContainer = null;
            } else {
                this.contentElement.replaceChildren();
                this.toolTipContainer = null;
            }
        };
        if (this.toolTipContainer) {
            gsap.to(this.toolTipContainer, 0.15, { opacity: 0, onComplete: () => hideBalloon() });
        }
    }

    createBalloon() {
        if (this.toolTipContainer) {
            return false;
        }

        let balloonTemplate = Handlebars.compile(Template),
            balloon = DOMUtils.createElementFromHTML(balloonTemplate(this.config)),
            balloonParent = this.oldVersion ? this.node : this.contentElement;

        balloonParent.append(balloon);
        this.toolTipContainer = balloonParent.querySelector('.' + this.config.balloonContainerClass);

        let nodeHeight = this.node.offsetHeight,
            toolTemplateHeight = this.toolTipContainer.offsetHeight,
            topDistance = toolTemplateHeight / 2 - nodeHeight / 2;

        // position the balloon
        this.toolTipContainer.style.marginTop = -topDistance + 'px';
    }
}
export default ToolTip;

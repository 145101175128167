import EventEmitter from 'wolfy87-eventemitter';
import publishTagmanagementEvent from '../../utils/publishTagmanagementEvent';
import UrlUtils from '../../utils/UrlUtils';
import merge from 'lodash/merge';

class SortItems extends EventEmitter {
    static EVENTS = {
        changeSort: 'SortItems.EVENTS.changeSort'
    };

    static DEFAULT_CONFIG = {
        selectors: {
            sortPicker: '.sortItems__picker',
            sortPickerOption: '.radioButton--underlined .radioButton__label',
            sortItemsContainer: 'sortItems__container',
            sortItemMatch: '.sortItems__match',
            sortItemMismatch: '.sortItems__mismatch',
            sortedItem: '.sortItems__item',
            activeClass: '.is-active',
            sortCaption: '.sortItems__caption',
            sortItem: '.grid__unit',
            resultsLabel: '.sortItems__match .sortItems__heading',
            resultsLabelCount: '.sortItems__resultCount'
        },
        labels: {
            allResults: 'Alle {0} resultaten',
            filteredResults: '{0} resultaten voor {1}'
        },
        eventData: {
            initialEvent: 'init',
            filterEvent: 'filter'
        }
    };

    constructor(node, config) {
        super();
        this.node = node;
        this.config = merge({}, SortItems.DEFAULT_CONFIG, config);
        this.init();
    }
    /**
     * Initialize SortItems
     */
    init() {
        this.selects = Array.from(this.node.querySelectorAll(this.config.selectors.sortPicker));
        this.select = this.selects.find((select) => select.tagName === 'SELECT');
        this.radio = this.selects.find((select) => select.tagName !== 'SELECT');
        this.captions = JSON.parse(this.radio.dataset.captions);

        let items = this.node.querySelectorAll(this.config.selectors.sortItem);
        this.itemCount = this.matchCount = items.length;
        this.#setItemSort(items);

        this.#setCaptions();

        this.#attachListeners();

        this.#checkParams();

        this.#collectData(this.config.eventData.initialEvent);
    }

    #attachListeners() {
        this.select.addEventListener('change', (event) => this.#onChange(event));
        this.radio.addEventListener('change', (event) => this.#onChange(event));
        this.on(SortItems.EVENTS.changeSort, () => {
            this.#updateUrl();
            this.#collectData(this.config.eventData.filterEvent);
        });
        this.radio
            .querySelectorAll(this.config.selectors.sortPickerOption)
            .forEach((radio) => radio.addEventListener('mouseenter', (event) => this.#setCaptions(event)));
    }

    #onChange(event) {
        this.#synchronizeControls(event);
        let target = event.target;
        let items = this.#matchItems(target);
        this.#orderItems(items.matches, this.node.querySelector(this.config.selectors.sortItemMatch));
        this.#orderItems(items.mismatches, this.node.querySelector(this.config.selectors.sortItemMismatch));
        this.#setCaptions();
        this.#setLabels();
        this.#setMismatchVisibility();

        if (event.isTrusted) {
            this.emit(SortItems.EVENTS.changeSort);
        }
    }

    /**
     * Mobile filter functionality
     */
    #matchItems(target) {
        let sortValue = target.value;
        let items = Array.from(this.node.querySelectorAll(this.config.selectors.sortItem));

        let matchedItems = [];
        let mismatchedItems = [];

        if (sortValue) {
            items.forEach((item) => {
                let itemData = JSON.parse(item.dataset.itemsort);
                if (itemData && itemData.sortOption && itemData.sortOption.indexOf(sortValue) > -1) {
                    matchedItems.push(item);
                } else {
                    mismatchedItems.push(item);
                }
            });
        } else {
            matchedItems = items;
        }

        this.matchCount = matchedItems.length;

        return {
            matches: matchedItems,
            mismatches: mismatchedItems
        };
    }

    /**
     * Set sort attribute based on data attribute or DOM order of appearance
     * @param items
     */
    #setItemSort(items) {
        items.forEach((item, index) => {
            let sortData = JSON.parse(item.dataset.itemsort || '{}') || {};
            item._siSortIndex = sortData.index || index;
        });
    }

    /**
     * Order matches by index (1,2,3 etc.)
     */
    #orderItems(items, parent) {
        items
            .sort((a, b) => {
                return a._siSortIndex - b._siSortIndex;
            })
            .forEach((item) => parent.append(item));
    }

    /**
     * Keep desktop radio buttons and mobile dropdown in sync
     * @param event
     */
    #synchronizeControls(event) {
        let value = event.target.value;
        let isSelect = event.target.matches('select');
        if (isSelect) {
            this.radio.querySelector('input[type="radio"][value="' + value + '"]').checked = true;
        } else {
            this.select.value = value;
        }
    }

    /**
     * Set the caption below the sort input
     * @param event
     */
    #setCaptions(event) {
        let activeOptionValue;
        if (event && event.type === 'mouseenter') {
            activeOptionValue = event.target.htmlFor;
        } else {
            activeOptionValue = this.select.value;
        }
        let caption = this.captions[activeOptionValue] || '';
        this.node.querySelectorAll(this.config.selectors.sortCaption).forEach((sortCaption) => (sortCaption.textContent = caption));
    }

    /**
     * Check if query string exists in select as option value
     */
    #checkParams() {
        let cat = UrlUtils.params('cat');

        if (cat) {
            const option = this.select.querySelector('option[value="' + cat + '"]');
            if (option) {
                option.selected = true;
                this.select.dispatchEvent(new Event('change', { bubbles: true }));
            }
        }
    }

    /**
     * Set the heading labels above the match/mismatch containers
     */
    #setLabels() {
        let allResults = this.itemCount === this.matchCount;
        let resultsLabelHtml = allResults ? this.config.labels.allResults : this.config.labels.filteredResults;
        resultsLabelHtml = resultsLabelHtml.replace('{0}', '<span class="sortItems__resultCount">' + this.matchCount + '</span>');

        if (!allResults) {
            let activeOption = this.select.querySelector(':checked');
            let activeOptionText = activeOption.matches('input[type="radio"]')
                ? activeOption.labels[0].innerHTML
                : activeOption.textContent;
            resultsLabelHtml = resultsLabelHtml.replace('{1}', activeOptionText.toLowerCase());
        }

        let resultsLabel = this.node.querySelector(this.config.selectors.resultsLabel);
        resultsLabel.innerHTML = resultsLabelHtml;
    }

    #setMismatchVisibility() {
        if (this.itemCount === this.matchCount) {
            this.node.querySelectorAll(this.config.selectors.sortItemMismatch).forEach((item) => item.classList.add('is-hidden'));
        } else {
            this.node.querySelectorAll(this.config.selectors.sortItemMismatch).forEach((item) => item.classList.remove('is-hidden'));
        }
    }

    #updateUrl() {
        let sortValue = this.select.value;
        if (sortValue) {
            UrlUtils.params({ cat: sortValue });
        } else {
            UrlUtils.params({ cat: undefined });
        }
    }

    #collectData(eventName) {
        let matches = Array.from(this.node.querySelectorAll(this.config.selectors.sortItemMatch + ' ' + this.config.selectors.sortItem));
        matches = matches.map((match) => {
            return JSON.parse(match.dataset.itemsort);
        });

        let mismatches = Array.from(
            this.node.querySelectorAll(this.config.selectors.sortItemMismatch + ' ' + this.config.selectors.sortItem)
        );
        mismatches = mismatches.map((mismatch) => {
            return JSON.parse(mismatch.dataset.itemsort);
        });

        let sortValue = this.select.value || '';

        /* eslint-disable camelcase */
        let interactionEvent = {
            interaction_type: 'filter',
            interaction_action: eventName,
            interaction_location: 'body',
            interaction_data: {
                trigger: sortValue,
                matches: matches,
                mismatches: mismatches
            }
        };
        /* eslint-enable */
        publishTagmanagementEvent(null, 'website_interaction', interactionEvent);
    }
}
export default SortItems;

import EventEmitter from 'wolfy87-eventemitter';

class Relay42Observer extends EventEmitter {
    EVENTS = {
        loadingDone: 'Relay42Observer.EVENTS.loading_done'
    };

    handleRelay42LoadingFinished(success) {
        this.emitEvent(this.EVENTS.loadingDone, [success]);
    }
}

export default new Relay42Observer();

/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import Utils from '../common/utils';

class Tokenizers {
    obj = {
        nonword: this.#getObjTokenizer(this.nonword),
        whitespace: this.#getObjTokenizer(this.whitespace)
    };

    whitespace(str) {
        str = Utils.toStr(str);
        return str ? str.split(/\s+/) : [];
    }

    nonword(str) {
        str = Utils.toStr(str);
        return str ? str.split(/\W+/) : [];
    }

    #getObjTokenizer(tokenizer) {
        return function setKey(keys) {
            keys = Utils.isArray(keys) ? keys : [].slice.call(arguments, 0);

            return function tokenize(o) {
                let tokens = [];

                Utils.each(keys, (k) => {
                    tokens = tokens.concat(tokenizer(Utils.toStr(o[k])));
                });

                return tokens;
            };
        };
    }
}

export default new Tokenizers();

import scrollToTarget from '../utils/scrollToTarget';
import setTemporaryFocus from '../utils/setTemporaryFocus';
import merge from 'lodash/merge';
import DOMUtils from '../utils/DOMUtils';

class FormErrorSummary {
    static DEFAULT_CONFIG = {
        classNames: {
            hidden: 'is-hidden',
            isLoaded: 'is-loaded',
            isLoading: 'is-loading'
        },
        selectors: {
            errorListContainer: '.formErrorSummary',
            errorList: '.formErrorSummary__list'
        },
        pageData: null
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, FormErrorSummary.DEFAULT_CONFIG, config);
    }

    displayErrors(currentPage, errors) {
        let formErrorSummary = currentPage.querySelector(this.config.selectors.errorListContainer),
            errorList = formErrorSummary.querySelector(this.config.selectors.errorList);

        if (errors && errors.length) {
            formErrorSummary.classList.remove(this.config.classNames.hidden);
            errorList.replaceChildren();

            // print labels
            errors
                .map((error) => {
                    return error.inputLabel;
                })
                .filter((error, index, items) => {
                    return items.indexOf(error) === index;
                })
                .forEach((error) => {
                    errorList.append(DOMUtils.createElementFromHTML(`<li>${error}</li>`));
                });

            // Scroll to error summary block, then focus it
            scrollToTarget(formErrorSummary, 0.5, () => {
                let thisForm = this.form;
                thisForm.emit('errorSummaryScrollDone');
                setTemporaryFocus(formErrorSummary);
            });
        }
    }

    clearErrors() {
        this.node.classList.add(this.config.classNames.hidden);
    }

    updateErrorSummary(page, errors) {
        // check if event was emitted for this error summary
        if (!this.#isActivePage(page)) {
            return;
        }
        // proceed with setting or clearing errors
        if (errors && errors.length > 0) {
            this.displayErrors(page, errors);
        } else {
            this.clearErrors();
        }
    }

    attach(form) {
        this.form = form;
        this.#attachListeners();
    }

    #attachListeners() {
        let thisForm = this.form;
        thisForm.on('switchPage', () => this.clearErrors());
        thisForm.on('updateErrorSummary', (page, errors) => this.updateErrorSummary(page, errors));
    }

    #isActivePage(activePage) {
        let thisPage = this.config.pageData.node;
        return thisPage === activePage;
    }
}
export default FormErrorSummary;

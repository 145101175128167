import Responsive from '../../utils/Responsive';
import Darkener from '../Darkener';
import ScrollBlocker from '../ScrollBlocker';
import scrollToTarget from '../../utils/scrollToTarget';
import 'moment/locale/nl';
import moment from 'moment-timezone/builds/moment-timezone.min';
import momentData from '../../../../../out-moment/data.json';
import nl from '../../translations/datepicker/nl';
import merge from 'lodash/merge';
import Picker from '../../../pickadate/Picker';
import { default as PickadateDatePicker } from '../../../pickadate/DatePicker';

class DatePicker {
    static DEFAULT_CONFIG = {
        selectors: {
            inputSelector: '.datePicker__input',
            triggerSelector: '.datePicker__button',
            pickerHolder: '.picker__holder',
            monthSelector: '.picker__select--month',
            yearSelector: '.picker__select--year'
        },
        apiConfig: {
            editable: false,
            selectYears: 999, // disable pickadate year range behavior
            selectMonths: true,
            firstDay: 1,
            userInputFormat: 'D-M-YYYY',
            format: 'dd-mm-yyyy',
            formatSubmit: 'dd-mm-yyyy',
            numberOfYearsInThePast: 100, // minimum selectable date
            maxSelectableDate: 5, // maximum selectable date
            min: false,
            max: false,
            disable: [],
            enable: [], //any date in this list will be enabled, even when in the 'disable' list
            klass: {
                buttonClose: 'icon icon--close icon--nsBlue picker__button--close'
            }
        },
        classNames: {
            isFixed: 'is-fixed'
        }
    };

    /**
     * @param node
     * @param config
     * @constructor
     */
    constructor(node, config) {
        this.node = node;
        this.config = merge({}, DatePicker.DEFAULT_CONFIG, config);
        this.init();
    }

    /**
     * Initialize date picker
     */
    init() {
        let apiConfig = this.config.apiConfig;
        let localeConfig = this.getLocaleConfig();
        let selectableDateLimits = this.getSelectableDateLimits();
        let config = merge({}, localeConfig, apiConfig, selectableDateLimits);

        this.input = this.node.querySelector(this.config.selectors.inputSelector);
        this.calendar = this.node.querySelector(this.config.selectors.triggerSelector);

        this.datePicker = new Picker(this.calendar, PickadateDatePicker, config);
        this.picker = this.datePicker.root;
        this.pickerHolder = this.picker.querySelector(this.config.selectors.pickerHolder);

        this.inputHeight = this.input.offsetHeight;

        this.attachListeners();
        this.swapDateSelectors();
    }

    /**
     * Set localization for date picker labels
     * @returns {{}}: custom config for localization
     */
    getLocaleConfig() {
        moment.tz.load(momentData);
        let localeConfig;
        let lang = document.documentElement.lang;
        if (lang === 'nl') {
            localeConfig = nl;
        } else {
            localeConfig = {};
            lang = 'en';
        }
        moment.locale(lang);
        return localeConfig;
    }

    /**
     * Sets the minimum and maximum selectable dates on the picker
     * @returns {{}}:  config with min and max
     */
    getSelectableDateLimits() {
        let minDate = this.config.apiConfig.numberOfYearsInThePast,
            maxDate = this.config.apiConfig.maxSelectableDate;

        minDate = moment().subtract(minDate, 'years').month(0).startOf('month').toArray();
        maxDate = moment().add(maxDate, 'years').month(11).endOf('month').toArray();

        //Set the min and max date
        //Split and reverse the date (from dd,mm,yyyy to yyyy,mm,dd)
        if (this.config.apiConfig.min) {
            let minSplit = this.config.apiConfig.min.split(',');
            minDate = [minSplit[2], minSplit[1] - 1, minSplit[0]];
        }
        if (this.config.apiConfig.max) {
            let maxSplit = this.config.apiConfig.max.split(',');
            maxDate = [maxSplit[2], maxSplit[1] - 1, maxSplit[0]];
        }

        //Disable a selection of dates and/or days in a week
        let disableDates = [];
        if (typeof this.config.apiConfig.disable === 'string' && typeof this.config.apiConfig.enable === 'string') {
            let daysOfWeek = ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'];
            let datesConcatenated = [this.config.apiConfig.disable.split('|'), this.config.apiConfig.enable.split('|')];
            let dateEntry = -1;
            let datesPart;
            let inv;
            for (let di = 0; di < datesConcatenated.length; di++) {
                inv = undefined;
                if (di === 1) {
                    inv = 'inverted'; //invert dates that need to be enabled (holiday exceptions)
                }
                datesPart = datesConcatenated[di];
                for (let d in datesPart) {
                    let date = datesPart[d];
                    if (typeof date === 'string') {
                        dateEntry = daysOfWeek.indexOf(date); //disable days of the week (1 to 7 = sunday to monday)
                        if (dateEntry === -1 && (date.match(/^\d\d?-\d\d?-\d{4}$/) || []).length > 0) {
                            //disable a date (dd-mm-yyyy)
                            let dateSplit = date.split('-');
                            dateEntry = [parseInt(dateSplit[2]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[0]), inv];
                        }
                        disableDates.push(dateEntry);
                    }
                }
            }
        }

        return {
            min: minDate,
            max: maxDate,
            disable: disableDates
        };
    }

    /**
     * Two-way binding to make input and calendar update each other
     */
    attachListeners() {
        this.input.addEventListener('change', () => this.updateDatePicker());
        this.datePicker
            .on('set', (event) => this.updateInput(event))
            .on('open', () => this.openDatepicker())
            .on('close', () => this.prepareOverlayClose())
            .on('render', () => this.swapDateSelectors());

        Responsive.on(Responsive.EVENTS.crossedBreakpoint, () => {
            this.#switchPickerStates();
        });
    }

    /**
     * Handle change events triggered by date picker
     * @param event: custom date picker event object containing event information
     */
    updateInput(event) {
        let action = Object.keys(event)[0];

        switch (action) {
            case 'select':
                this.input.value = this.datePicker.get('value');
                this.input.dispatchEvent(new Event('change', { bubbles: true }));
                break;
            case 'clear':
                this.input.value = '';
                this.input.dispatchEvent(new Event('change', { bubbles: true }));
                break;
        }
    }

    /**
     * Update date picker after input change
     */
    updateDatePicker() {
        let value = this.input.value;
        let parsedInput = value ? this.getParsedInput(value) : null;

        if (parsedInput && parsedInput.isValid()) {
            let formattedInput = parsedInput.format(this.config.apiConfig.format.toUpperCase());
            this.datePicker.set('select', formattedInput, { muted: true });
        }
    }

    /**
     * Convert user input to moment object
     * @param value: input value entered by keyboard
     */
    getParsedInput(value) {
        return moment(value, this.config.apiConfig.userInputFormat, true);
    }

    /**
     * Swap position of the Month/year selectors
     */
    swapDateSelectors() {
        let monthSelector = this.picker.querySelector(this.config.selectors.monthSelector),
            yearSelector = this.picker.querySelector(this.config.selectors.yearSelector);

        monthSelector.after(yearSelector);
    }

    /**
     * Prepare the opening of the datepicker
     */
    prepareOverlayOpen() {
        Darkener.setMode('datepicker');
        Darkener.show();

        this.picker.classList.add(this.config.classNames.isFixed);
        ScrollBlocker.disableScroll();
    }

    /**
     * Prepare the closing of the datepicker
     */
    prepareOverlayClose() {
        Darkener.hide();
        Darkener.on(Darkener.EVENTS.hideIsDone, () => {
            this.picker.classList.remove(this.config.classNames.isFixed);
            ScrollBlocker.enableScroll();
        });
    }

    /**
     * Open the datepicker
     */
    openDatepicker() {
        this.updateDatePicker();
        this.#switchPickerStates();
    }

    /**
     * Switches between the 'small breakpoint' variant (darkener with fixed datepicker),
     * and the 'default' 'medium+ breakpoint' variant.
     * Apply's only when the picker is open.
     */

    #switchPickerStates() {
        let isLargeDevice = Responsive.testBreakpoint('m'),
            pickerIsOpen = this.datePicker.get('open');

        if (pickerIsOpen) {
            if (isLargeDevice) {
                this.prepareOverlayClose();
                this.scrollToPlace();
            } else {
                this.prepareOverlayOpen();
            }
        }
    }

    /**
     * Scrolls the picker to the right place on the screen
     */
    scrollToPlace() {
        let doScroll = () => {
            let documentOffset = window.scrollY,
                viewPortHeight = Responsive.getViewportHeight(),
                pickerBottomShadowHeight = 18,
                pickerHeight = this.pickerHolder.offsetHeight + pickerBottomShadowHeight,
                inputOffsetTop = this.input.getBoundingClientRect().top + window.scrollY - document.documentElement.clientTop,
                inputPositionTop = inputOffsetTop - documentOffset,
                inputOffset = inputPositionTop + this.inputHeight,
                pickerPos = documentOffset + (pickerHeight - (viewPortHeight - inputOffset)),
                inputPos = inputOffsetTop - pickerBottomShadowHeight / 2,
                windowTooSmall = pickerHeight + this.inputHeight > viewPortHeight,
                aboveTheFold = inputPositionTop > viewPortHeight / 2,
                overflowScreenBottom = inputOffset + pickerHeight > viewPortHeight;

            let scrollTargetPos = -1;
            if (windowTooSmall) {
                if (aboveTheFold) {
                    scrollTargetPos = inputPos;
                }
            } else if (overflowScreenBottom) {
                scrollTargetPos = pickerPos;
            }

            if (scrollTargetPos >= 0) {
                const scrollDiff = Math.abs(documentOffset - scrollTargetPos);
                const scrollTime = 0.1 + scrollDiff / 1200;
                scrollToTarget(scrollTargetPos, scrollTime);
            }
        };

        setTimeout(doScroll, 350);
    }
}

export default DatePicker;

<div class="stepsIndicator">
    <div class="stepsIndicator__header" aria-live="polite" role="status">
    </div>
    <div class="stepsIndicator__container">
        <div class="stepsIndicator__stepHolder">
        </div>
        <span class="stepsIndicator__progressBarHolder">
            <span class="stepsIndicator__progressBar">
            </span>
        </span>
        <span aria-live="polite" role="status" class="stepsIndicator__statusHolder"></span>
    </div>
</div>

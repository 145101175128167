/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import Utils from '../common/utils';

class SearchIndex {
    static #CHILDREN = 'c';
    static #IDS = 'i';

    constructor(o) {
        o = o || {};

        if (!o.datumTokenizer || !o.queryTokenizer) {
            Utils.error('datumTokenizer and queryTokenizer are both required');
        }

        this.identify = o.identify || Utils.stringify;
        this.datumTokenizer = o.datumTokenizer;
        this.queryTokenizer = o.queryTokenizer;

        this.reset();
    }

    bootstrap(o) {
        this.datums = o.datums;
        this.trie = o.trie;
    }

    add(data) {
        let that = this;

        data = Utils.isArray(data) ? data : [data];

        Utils.each(data, (datum) => {
            let id, tokens;

            that.datums[(id = that.identify(datum))] = datum;
            tokens = SearchIndex.#normalizeTokens(that.datumTokenizer(datum));

            Utils.each(tokens, (token) => {
                let node, chars, ch;

                node = that.trie;
                chars = token.split('');

                while ((ch = chars.shift())) {
                    node = node[SearchIndex.#CHILDREN][ch] || (node[SearchIndex.#CHILDREN][ch] = SearchIndex.#newNode());
                    node[SearchIndex.#IDS].push(id);
                }
            });
        });
    }

    get(ids) {
        let that = this;

        return Utils.map(ids, (id) => {
            return that.datums[id];
        });
    }

    search(query) {
        let that = this,
            tokens,
            matches;

        tokens = SearchIndex.#normalizeTokens(this.queryTokenizer(query));

        Utils.each(tokens, (token) => {
            let node, chars, ch, ids;

            // previous tokens didn't share any matches
            if (matches && matches.length === 0) {
                return false;
            }

            node = that.trie;
            chars = token.split('');

            while (node && (ch = chars.shift())) {
                node = node[SearchIndex.#CHILDREN][ch];
            }

            if (node && chars.length === 0) {
                ids = node[SearchIndex.#IDS].slice(0);
                matches = matches ? SearchIndex.#getIntersection(matches, ids) : ids;
            }

            // break early if we find out there are no possible matches
            else {
                matches = [];
                return false;
            }
        });

        return matches
            ? Utils.map(SearchIndex.#unique(matches), (id) => {
                  return that.datums[id];
              })
            : [];
    }

    all() {
        let values = [];

        for (let key in this.datums) {
            values.push(this.datums[key]);
        }

        return values;
    }

    reset() {
        this.datums = {};
        this.trie = SearchIndex.#newNode();
    }

    serialize() {
        return { datums: this.datums, trie: this.trie };
    }

    static #normalizeTokens(tokens) {
        // filter out falsy tokens
        tokens = Utils.filter(tokens, (token) => {
            return !!token;
        });

        // normalize tokens
        tokens = Utils.map(tokens, (token) => {
            return token.toLowerCase();
        });

        return tokens;
    }

    static #newNode() {
        let node = {};

        node[SearchIndex.#IDS] = [];
        node[SearchIndex.#CHILDREN] = {};

        return node;
    }

    static #unique(array) {
        let seen = {},
            uniques = [];

        for (let i = 0, len = array.length; i < len; i++) {
            if (!seen[array[i]]) {
                seen[array[i]] = true;
                uniques.push(array[i]);
            }
        }

        return uniques;
    }

    static #getIntersection(arrayA, arrayB) {
        let ai = 0,
            bi = 0,
            intersection = [];

        arrayA = arrayA.sort();
        arrayB = arrayB.sort();

        let lenArrayA = arrayA.length,
            lenArrayB = arrayB.length;

        while (ai < lenArrayA && bi < lenArrayB) {
            if (arrayA[ai] < arrayB[bi]) {
                ai++;
            } else if (arrayA[ai] > arrayB[bi]) {
                bi++;
            } else {
                intersection.push(arrayA[ai]);
                ai++;
                bi++;
            }
        }

        return intersection;
    }
}

export default SearchIndex;

/*!
 * pickadate.js v3.6.4, 2019/05/25
 * By Amsul, http://amsul.ca
 * Hosted on http://amsul.github.io/pickadate.js
 * Licensed under MIT
 */
import Utils from './Utils';
import merge from 'lodash/merge';
import DOMUtils from '../generic/utils/DOMUtils';
import TabSequenceWatch from '../generic/ui/TabSequenceWatch';

class Picker {
    constructor(ELEMENT, COMPONENT, OPTIONS) {
        // If there’s no element, return the picker constructor.
        if (!ELEMENT) return Picker.constructor;

        let IS_DEFAULT_THEME = false,
            // The state of the picker.
            STATE = {
                id: ELEMENT.id || 'P' + Math.abs(~~(Math.random() * new Date())),
                handlingOpen: false
            },
            // Merge the defaults and options passed.
            SETTINGS = COMPONENT ? merge({}, COMPONENT.defaults, OPTIONS) : OPTIONS || {},
            // Merge the default classes with the settings classes.
            CLASSES = merge({}, Picker.klasses(), SETTINGS.klass),
            // The element node wrapper into a jQuery object.
            // Pseudo picker constructor.
            PickerInstance = function () {
                return this.start();
            },
            // The picker prototype.
            P = (PickerInstance.prototype = {
                constructor: PickerInstance,

                node: ELEMENT,

                documentClickHandler: (event) => {
                    // If the picker is currently midway through processing
                    // the opening sequence of events then don't handle clicks
                    // on any part of the DOM. This is caused by a bug in Chrome 73
                    // where a click event is being generated with the incorrect
                    // path in it.
                    // In short, if someone does a click that finishes after the
                    // new element is created then the path contains only the
                    // parent element and not the input element itself.

                    if (STATE.handlingOpen) {
                        return;
                    }

                    let target = getRealEventTarget(event, ELEMENT);

                    // If the target of the event is not the element, close the picker picker.
                    // * Don’t worry about clicks or focusins on the root because those don’t bubble up.
                    //   Also, for Firefox, a click on an `option` element bubbles up directly
                    //   to the doc. So make sure the target wasn't the doc.
                    // * In Firefox stopPropagation() doesn’t prevent right-click events from bubbling,
                    //   which causes the picker to unexpectedly close when right-clicking it. So make
                    //   sure the event wasn’t a right-click.
                    // * In Chrome 62 and up, password autofill causes a simulated focusin event which
                    //   closes the picker.
                    if (!event.isSimulated && target !== ELEMENT && target !== document && event.which !== 3) {
                        // If the target was the holder that covers the screen,
                        // keep the element focused to maintain tabindex.
                        P.close(target === P.holder);
                    }
                },

                documentKeydownHandler: (event) => {
                    let // Get the keycode.
                        keycode = event.keyCode,
                        // Translate that to a selection change.
                        keycodeToMove = P.component.key[keycode],
                        // Grab the target.
                        target = getRealEventTarget(event, ELEMENT);

                    // On escape, close the picker and give focus.
                    if (keycode === 27) {
                        P.close(true);
                    }

                    // Check if there is a key movement or “enter” keypress on the element.
                    else if (target === P.holder && (keycodeToMove || keycode === 13)) {
                        // Prevent the default action to stop page movement.
                        event.preventDefault();

                        // Trigger the key movement action.
                        if (keycodeToMove) {
                            Utils.trigger(P.component.key.go, P, [Utils.trigger(keycodeToMove)]);
                        }

                        // On “enter”, if the highlighted item isn’t disabled, set the value and close.
                        else if (!P.root.querySelector('.' + CLASSES.highlighted).classList.contains(CLASSES.disabled)) {
                            P.set('select', P.component.item.highlight);
                            if (SETTINGS.closeOnSelect) {
                                P.close(true);
                            }
                        }
                    }

                    // If the target is within the root and “enter” is pressed,
                    // prevent the default action and trigger a click on the target instead.
                    else if (P.root.contains(target) && keycode === 13) {
                        event.preventDefault();
                        target.click();
                    }
                },
                /**
                 * Initialize everything
                 */
                start: function () {
                    // If it’s already started, do nothing.
                    if (STATE && STATE.start) return P;

                    // Update the picker states.
                    STATE.methods = {};
                    STATE.start = true;
                    STATE.open = false;
                    STATE.type = ELEMENT.type;

                    // Confirm focus state, convert into text input to remove UA stylings,
                    // and set as readonly to prevent keyboard popup.
                    ELEMENT.autofocus = ELEMENT === getActiveElement();
                    ELEMENT.readOnly = !SETTINGS.editable;
                    SETTINGS.id = ELEMENT.id = ELEMENT.id || STATE.id;
                    if (ELEMENT.type !== 'text') {
                        ELEMENT.type = 'text';
                    }

                    // Create a new picker component with the settings.
                    P.component = new COMPONENT(P, SETTINGS);

                    // Create the picker root and then prepare it.
                    P.root = DOMUtils.createElementFromHTML('<div class="' + CLASSES.picker + '" id="' + ELEMENT.id + '_root" />');
                    prepareElementRoot();

                    // Create the picker holder and then prepare it.
                    P.holder = DOMUtils.createElementFromHTML(createWrappedComponent());
                    P.root.appendChild(P.holder);
                    prepareElementHolder();

                    // If there’s a format for the hidden input element, create the element.
                    if (SETTINGS.formatSubmit) {
                        prepareElementHidden();
                    }

                    // Prepare the input element.
                    prepareElement();

                    // Insert the hidden input as specified in the settings.
                    if (SETTINGS.containerHidden) document.querySelector(SETTINGS.containerHidden).append(P._hidden);
                    else ELEMENT.after(P._hidden);

                    // Insert the root as specified in the settings.
                    if (SETTINGS.container) document.querySelector(SETTINGS.container).append(P.root);
                    else ELEMENT.after(P.root);

                    // Bind the default component and settings events.
                    P.on({
                        start: P.component.onStart,
                        render: P.component.onRender,
                        stop: P.component.onStop,
                        open: P.component.onOpen,
                        close: P.component.onClose,
                        set: P.component.onSet
                    }).on({
                        start: SETTINGS.onStart,
                        render: SETTINGS.onRender,
                        stop: SETTINGS.onStop,
                        open: SETTINGS.onOpen,
                        close: SETTINGS.onClose,
                        set: SETTINGS.onSet
                    });

                    // Once we’re all set, check the theme in use.
                    IS_DEFAULT_THEME = isUsingDefaultTheme(P.holder);

                    // If the element has autofocus, open the picker.
                    if (ELEMENT.autofocus) {
                        P.open();
                    }

                    // Trigger queued the “start” and “render” events.
                    return P.trigger('start').trigger('render');
                }, //start

                /**
                 * Render a new picker
                 */
                render: function (entireComponent) {
                    // Insert a new component holder in the root or box.
                    if (entireComponent) {
                        P.holder = DOMUtils.createElementFromHTML(createWrappedComponent());
                        prepareElementHolder();
                        P.root.replaceChildren(P.holder);
                    } else {
                        P.root.querySelector('.' + CLASSES.box).innerHTML = P.component.nodes(STATE.open);
                    }
                    // Add elements to tabbing order
                    let elements = P.root.querySelectorAll('div[tabindex="-1"]');
                    for (const element of elements) {
                        element.setAttribute('tabindex', '0');
                    }
                    P.tabWatch.enable();

                    // Trigger the queued “render” events.
                    return P.trigger('render');
                }, //render

                /**
                 * Destroy everything
                 */
                stop: function () {
                    // If it’s already stopped, do nothing.
                    if (!STATE.start) return P;

                    // Then close the picker.
                    P.close();

                    // Remove the hidden field.
                    if (P._hidden) {
                        P._hidden.parentNode.removeChild(P._hidden);
                    }

                    // Remove the root.
                    P.root.remove();

                    // Remove the input class, remove the stored data, and unbind
                    // the events (after a tick for IE - see `P.close`).
                    ELEMENT.classList.remove(CLASSES.input);
                    setTimeout(() => {
                        ELEMENT.removeEventListener('change', handleChange);
                        ELEMENT.removeEventListener('click', handleClick);
                        ELEMENT.removeEventListener('keydown', handleKeydownEvent);
                    });
                    // Restore the element state
                    ELEMENT.type = STATE.type;
                    ELEMENT.readOnly = false;

                    // Trigger the queued “stop” events.
                    P.trigger('stop');

                    // Reset the picker states.
                    STATE.methods = {};
                    STATE.start = false;

                    return P;
                }, //stop

                /**
                 * Open up the picker
                 */
                open: function (dontGiveFocus) {
                    // If it’s already open, do nothing.
                    if (STATE.open) return P;

                    // Add the “active” class.
                    ELEMENT.classList.add(CLASSES.active);

                    // * A Firefox bug, when `html` has `overflow:hidden`, results in
                    //   killing transitions :(. So add the “opened” state on the next tick.
                    //   Bug: https://bugzilla.mozilla.org/show_bug.cgi?id=625289
                    setTimeout(() => {
                        // Add the “opened” class to the picker root.
                        P.root.classList.add(CLASSES.opened);
                        aria(P.root, 'hidden', false);

                        // Add elements to tabbing order
                        let elements = P.root.querySelectorAll('div[tabindex="-1"]');
                        for (const element of elements) {
                            element.setAttribute('tabindex', '0');
                        }
                        P.tabWatch.enable();
                    }, 0);

                    // If we have to give focus, bind the element and doc events.
                    if (dontGiveFocus !== false) {
                        // Set it as open.
                        STATE.open = true;

                        // Prevent the page from scrolling.
                        if (IS_DEFAULT_THEME) {
                            document.body.style.overflow = 'hidden';
                            const currentPaddingRight = getComputedStyle(document.body).paddingRight || 0;
                            document.body.style.paddingRight = `${currentPaddingRight + getScrollbarWidth()}`;
                        }

                        // Pass focus to the root element’s jQuery object.
                        P.holder.focus();

                        // Bind the document events.
                        ['click'].forEach((event) => document.addEventListener(event, P.documentClickHandler));
                        document.addEventListener('keydown', P.documentKeydownHandler);
                    }

                    // Trigger the queued “open” events.
                    return P.trigger('open');
                }, //open

                /**
                 * Close the picker
                 */
                close: function (giveFocus) {
                    // If we need to give focus, do it before changing states.
                    if (giveFocus) {
                        ELEMENT.focus();
                    }

                    // Remove the “active” class.
                    ELEMENT.classList.remove(CLASSES.active);

                    // * A Firefox bug, when `html` has `overflow:hidden`, results in
                    //   killing transitions :(. So remove the “opened” state on the next tick.
                    //   Bug: https://bugzilla.mozilla.org/show_bug.cgi?id=625289
                    setTimeout(() => {
                        P.root.classList.remove(CLASSES.opened);
                        aria(P.root, 'hidden', true);

                        // Remove elements from tabbing order
                        let elements = P.root.querySelectorAll('div[tabindex="0"]');
                        for (const element of elements) {
                            element.setAttribute('tabindex', '-1');
                        }
                    }, 0);

                    // If it’s already closed, do nothing more.
                    if (!STATE.open) return P;

                    // Set it as closed.
                    STATE.open = false;

                    // Allow the page to scroll.
                    if (IS_DEFAULT_THEME) {
                        document.body.style.overflow = '';
                        document.body.style.paddingRight -= getScrollbarWidth();
                    }

                    // Unbind the document events.
                    document.removeEventListener('click', P.documentClickHandler);
                    document.removeEventListener('keydown', P.documentKeydownHandler);

                    // Trigger the queued “close” events.
                    return P.trigger('close');
                }, //close

                /**
                 * Clear the values
                 */
                clear: function (options) {
                    return P.set('clear', null, options);
                }, //clear

                /**
                 * Set something
                 */
                set: function (thing, value, options) {
                    let thingItem,
                        thingValue,
                        thingIsObject = Utils.isPlainObject(thing),
                        thingObject = thingIsObject ? thing : {};

                    // Make sure we have usable options.
                    options = thingIsObject && Utils.isPlainObject(value) ? value : options || {};

                    if (thing) {
                        // If the thing isn’t an object, make it one.
                        if (!thingIsObject) {
                            thingObject[thing] = value;
                        }

                        // Go through the things of items to set.
                        for (thingItem in thingObject) {
                            // Grab the value of the thing.
                            thingValue = thingObject[thingItem];

                            // First, if the item exists and there’s a value, set it.
                            if (thingItem in P.component.item) {
                                if (thingValue === undefined) thingValue = null;
                                P.component.set(thingItem, thingValue, options);
                            }

                            // Then, check to update the element value and broadcast a change.
                            if ((thingItem === 'select' || thingItem === 'clear') && SETTINGS.updateInput) {
                                ELEMENT.value = thingItem === 'clear' ? '' : P.get(thingItem, SETTINGS.format);
                                ELEMENT.dispatchEvent(new Event('change', { bubbles: true }));
                            }
                        }

                        // Render a new picker.
                        P.render();
                    }

                    // When the method isn’t muted, trigger queued “set” events and pass the `thingObject`.
                    return options.muted ? P : P.trigger('set', thingObject);
                }, //set

                /**
                 * Get something
                 */
                get: function (thing, format) {
                    // Make sure there’s something to get.
                    thing = thing || 'value';

                    // If a picker state exists, return that.
                    if (STATE[thing] != null) {
                        return STATE[thing];
                    }

                    // Return the submission value, if that.
                    if (thing === 'valueSubmit') {
                        if (P._hidden) {
                            return P._hidden.value;
                        }
                        thing = 'value';
                    }

                    // Return the value, if that.
                    if (thing === 'value') {
                        return ELEMENT.value;
                    }

                    // Check if a component item exists, return that.
                    if (thing in P.component.item) {
                        if (typeof format === 'string') {
                            let thingValue = P.component.get(thing);
                            return thingValue ? Utils.trigger(P.component.formats.toString, P.component, [format, thingValue]) : '';
                        }
                        return P.component.get(thing);
                    }
                }, //get

                /**
                 * Bind events on the things.
                 */
                on: function (thing, method, internal) {
                    let thingName,
                        thingMethod,
                        thingIsObject = Utils.isPlainObject(thing),
                        thingObject = thingIsObject ? thing : {};

                    if (thing) {
                        // If the thing isn’t an object, make it one.
                        if (!thingIsObject) {
                            thingObject[thing] = method;
                        }

                        // Go through the things to bind to.
                        for (thingName in thingObject) {
                            // Grab the method of the thing.
                            thingMethod = thingObject[thingName];

                            // If it was an internal binding, prefix it.
                            if (internal) {
                                thingName = '_' + thingName;
                            }

                            // Make sure the thing methods collection exists.
                            STATE.methods[thingName] = STATE.methods[thingName] || [];

                            // Add the method to the relative method collection.
                            STATE.methods[thingName].push(thingMethod);
                        }
                    }

                    return P;
                }, //on

                /**
                 * Unbind events on the things.
                 */
                off: function () {
                    let i,
                        thingName,
                        names = arguments,
                        namesCount;
                    for (i = 0, namesCount = names.length; i < namesCount; i += 1) {
                        thingName = names[i];
                        if (thingName in STATE.methods) {
                            delete STATE.methods[thingName];
                        }
                    }
                    return P;
                },

                /**
                 * Fire off method events.
                 */
                trigger: function (name, data) {
                    let _trigger = function (name) {
                        let methodList = STATE.methods[name];
                        if (methodList) {
                            methodList.map((method) => {
                                Utils.trigger(method, P, [data]);
                            });
                        }
                    };
                    _trigger('_' + name);
                    _trigger(name);
                    return P;
                } //trigger
            });

        /**
         * Wrap the picker holder components together.
         */
        function createWrappedComponent() {
            // Create a picker wrapper holder
            return Utils.node(
                'div',

                // Create a picker wrapper node
                Utils.node(
                    'div',

                    // Create a picker frame
                    Utils.node(
                        'div',

                        // Create a picker box node
                        Utils.node(
                            'div',

                            // Create the components nodes.
                            P.component.nodes(STATE.open),

                            // The picker box class
                            CLASSES.box
                        ),

                        // Picker wrap class
                        CLASSES.wrap
                    ),

                    // Picker frame class
                    CLASSES.frame
                ),

                // Picker holder class
                CLASSES.holder,

                'tabindex="-1"'
            ); //endreturn
        } //createWrappedComponent

        /**
         * Prepare the input element with all bindings.
         */
        function prepareElement() {
            ELEMENT.classList.add(CLASSES.input);
            ELEMENT.value = ELEMENT.dataset.value ? P.get('select', SETTINGS.format) : ELEMENT.value;
            ELEMENT.addEventListener('click', handleClick);
            // Only bind keydown events if the element isn’t editable.
            if (!SETTINGS.editable) {
                // Handle keyboard event based on the picker being opened or not.
                ELEMENT.addEventListener('keydown', handleKeydownEvent);
            }

            // Update the aria attributes.
            aria(ELEMENT, {
                haspopup: true,
                owns: ELEMENT.id + '_root'
            });
        }

        function handleClick(event) {
            event.preventDefault();
            P.open();
        }

        /**
         * Prepare the root picker element with all bindings.
         */
        function prepareElementRoot() {
            aria(P.root, 'hidden', true);
            aria(P.root, 'role', 'dialog');
            P.tabWatch = new TabSequenceWatch(P.root);
        }

        /**
         * Prepare the holder picker element with all bindings.
         */
        function prepareElementHolder() {
            P.holder.addEventListener('keydown', handleKeydownEvent);
            P.holder.addEventListener('focus', handleFocusToOpenEvent);
            P.holder.addEventListener('blur', () => {
                // Remove the “target” class.
                ELEMENT.classList.remove(CLASSES.target);
            });
            ['mousedown', 'click'].forEach((eventName) => {
                P.holder.addEventListener(eventName, (event) => {
                    let target = getRealEventTarget(event, ELEMENT);

                    // Make sure the target isn’t the root holder so it can bubble up.
                    if (target !== P.holder) {
                        event.stopPropagation();

                        // * For mousedown events, cancel the default action in order to
                        //   prevent cases where focus is shifted onto external elements
                        //   when using things like jQuery mobile or MagnificPopup (ref: #249 & #120).
                        //   Also, for Firefox, don’t prevent action on the `option` element.
                        if (event.type === 'mousedown' && !target.matches('input, select, textarea, button, option')) {
                            event.preventDefault();

                            // Re-focus onto the holder so that users can click away
                            // from elements focused within the picker.
                            P.holder.focus();
                        }
                    }
                });
            });
            // If there’s a click on an actionable element, carry out the actions.
            DOMUtils.addEventListener(P.holder, 'click', '[data-pick], [data-nav], [data-clear], [data-close]', (event) => {
                let target = event.target,
                    targetDataNav = target.dataset.nav,
                    targetDataPickRaw = target.dataset.pick,
                    targetDataPick = isNaN(targetDataPickRaw) ? targetDataPickRaw : parseInt(targetDataPickRaw),
                    targetDataClear = target.dataset.clear,
                    targetDataClose = target.dataset.close,
                    targetDisabled = target.classList.contains(CLASSES.navDisabled) || target.classList.contains(CLASSES.disabled),
                    // * For IE, non-focusable elements can be active elements as well
                    //   (http://stackoverflow.com/a/2684561).
                    activeElement = getActiveElement();
                activeElement = activeElement && (activeElement.type || activeElement.href ? activeElement : null);

                // If it’s disabled or nothing inside is actively focused, re-focus the element.
                if (targetDisabled || (activeElement && !P.root.contains(activeElement))) {
                    P.holder.focus();
                }

                // If something is superficially changed, update the `highlight` based on the `nav`.
                if (!targetDisabled && targetDataNav) {
                    P.set('highlight', P.component.item.highlight, { nav: targetDataNav });
                }

                // If something is picked, set `select` then close with focus.
                else if (!targetDisabled && targetDataPick) {
                    P.set('select', targetDataPick);
                    if (SETTINGS.closeOnSelect) {
                        P.close(true);
                    }
                }

                // If a “clear” button is pressed, empty the values and close with focus.
                else if (targetDataClear) {
                    P.clear();
                    if (SETTINGS.closeOnClear) {
                        P.close(true);
                    }
                } else if (targetDataClose) {
                    P.close(true);
                }
            });
        }

        /**
         * Prepare the hidden input element along with all bindings.
         */
        function prepareElementHidden() {
            let name;

            if (SETTINGS.hiddenName === true) {
                name = ELEMENT.name;
                ELEMENT.name = '';
            } else {
                name = [
                    typeof SETTINGS.hiddenPrefix == 'string' ? SETTINGS.hiddenPrefix : '',
                    typeof SETTINGS.hiddenSuffix == 'string' ? SETTINGS.hiddenSuffix : '_submit'
                ];
                name = name[0] + ELEMENT.name + name[1];
            }

            P._hidden = DOMUtils.createElementFromHTML(
                '<input ' +
                    'type=hidden ' +
                    // Create the name using the original input’s with a prefix and suffix.
                    'name="' +
                    name +
                    '"' +
                    // If the element has a value, set the hidden value as well.
                    (ELEMENT.dataset.value || ELEMENT.value ? ' value="' + P.get('select', SETTINGS.formatSubmit) + '"' : '') +
                    '>'
            );

            ELEMENT.addEventListener('change', handleChange);
        }

        function handleChange() {
            P._hidden.value = ELEMENT.value ? P.get('select', SETTINGS.formatSubmit) : '';
        }

        function handleFocusToOpenEvent(event) {
            // Stop the event from propagating to the doc.
            event.stopPropagation();

            // Add the “target” class.
            ELEMENT.classList.add(CLASSES.target);

            // And then finally open the picker.
            P.open();
        }

        // For iOS8.
        function handleKeydownEvent(event) {
            let keycode = event.keyCode,
                // Check if one of the delete keys was pressed.
                isKeycodeDelete = /^(8|46)$/.test(keycode);

            // For some reason IE clears the input value on “escape”.
            if (keycode === 27) {
                P.close(true);
                return false;
            }

            // Check if `space` or `delete` was pressed or the picker is closed with a key movement.
            if (keycode === 32 || isKeycodeDelete || (!STATE.open && P.component.key[keycode])) {
                // Prevent it from moving the page and bubbling to doc.
                event.preventDefault();
                event.stopPropagation();

                // If `delete` was pressed, clear the values and close the picker.
                // Otherwise open the picker.
                if (isKeycodeDelete) {
                    P.clear().close();
                } else {
                    P.open();
                }
            }
        }

        // Return a new picker instance.
        return new PickerInstance();
    }

    static klasses(prefix) {
        prefix = prefix || 'picker';
        return {
            picker: prefix,
            opened: prefix + '--opened',

            input: prefix + '__input',
            active: prefix + '__input--active',
            target: prefix + '__input--target',

            holder: prefix + '__holder',

            frame: prefix + '__frame',
            wrap: prefix + '__wrap',

            box: prefix + '__box'
        };
    }
}

export default Picker;

/**
 * Check if the default theme is being used.
 */
function isUsingDefaultTheme(element) {
    let theme,
        prop = 'position';

    // For IE.
    if (element.currentStyle) {
        theme = element.currentStyle[prop];
    }

    // For normal browsers.
    else if (window.getComputedStyle) {
        theme = getComputedStyle(element)[prop];
    }

    return theme === 'fixed';
}

/**
 * Get the width of the browser’s scrollbar.
 * Taken from: https://github.com/VodkaBears/Remodal/blob/master/src/jquery.remodal.js
 */
function getScrollbarWidth() {
    if (document.documentElement.getBoundingClientRect().height <= window.getBoundingClientRect().height) {
        return 0;
    }

    let outer = DOMUtils.createElementFromHTML('<div style="visibility:hidden;width:100px" />');
    document.body.append(outer);

    // Get the width without scrollbars.
    let widthWithoutScroll = outer.offsetWidth;

    // Force adding scrollbars.
    outer.style.overflow = 'scroll';

    // Add the inner div.
    let inner = DOMUtils.createElementFromHTML('<div style="width:100%" />');
    outer.append(inner);

    // Get the width with scrollbars.
    let widthWithScroll = inner.offsetWidth;

    // Remove the divs.
    outer.remove();

    // Return the difference between the widths.
    return widthWithoutScroll - widthWithScroll;
}

/**
 * Get the target element from the event.
 * If ELEMENT is supplied and present in the event path (ELEMENT is ancestor of the target),
 * returns ELEMENT instead
 */
function getRealEventTarget(event, ELEMENT) {
    return ELEMENT.contains(event.target) ? ELEMENT : event.target;
}

function aria(element, attribute, value) {
    if (Utils.isPlainObject(attribute)) {
        for (let key in attribute) {
            ariaSet(element, key, attribute[key]);
        }
    } else {
        ariaSet(element, attribute, value);
    }
}

function ariaSet(element, attribute, value) {
    element.setAttribute((attribute === 'role' ? '' : 'aria-') + attribute, value);
}

// IE8 bug throws an error for activeElements within iframes.
function getActiveElement() {
    try {
        return document.activeElement;
    } catch (err) {
        //ignore
    }
}

/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import PersistentStorage from './persistent_storage';

class Prefetch {
    static #keys = { data: 'data', protocol: 'protocol', thumbprint: 'thumbprint' };

    constructor(o) {
        this.url = o.url;
        this.ttl = o.ttl;
        this.cache = o.cache;
        this.prepare = o.prepare;
        this.transform = o.transform;
        this.thumbprint = o.thumbprint;

        this.storage = new PersistentStorage(o.cacheKey);
    }

    #settings() {
        return { url: this.url, type: 'GET', dataType: 'json' };
    }

    store(data) {
        if (!this.cache) {
            return;
        }

        this.storage.set(Prefetch.#keys.data, data, this.ttl);
        this.storage.set(Prefetch.#keys.protocol, location.protocol, this.ttl);
        this.storage.set(Prefetch.#keys.thumbprint, this.thumbprint, this.ttl);
    }

    fromCache() {
        let stored = {},
            isExpired;

        if (!this.cache) {
            return null;
        }

        stored.data = this.storage.get(Prefetch.#keys.data);
        stored.protocol = this.storage.get(Prefetch.#keys.protocol);
        stored.thumbprint = this.storage.get(Prefetch.#keys.thumbprint);

        // the stored data is considered expired if the thumbprints
        // don't match or if the protocol it was originally stored under
        // has changed
        isExpired = stored.thumbprint !== this.thumbprint || stored.protocol !== location.protocol;

        // TODO: if expired, remove from local storage

        return stored.data && !isExpired ? stored.data : null;
    }

    fromNetwork(cb) {
        let that = this,
            settings;

        if (!cb) {
            return;
        }

        settings = this.prepare(this.#settings());
        fetch(settings.url, {
            headers: settings.headers,
            method: settings.method
        })
            .then(onResponse)
            .catch(onError);

        function onError() {
            cb(true);
        }

        function onResponse(resp) {
            if (resp.ok) {
                resp.json().then((result) => cb(null, that.transform(result)));
            } else {
                cb(true);
            }
        }
    }

    clear() {
        this.storage.clear();
        return this;
    }
}

export default Prefetch;

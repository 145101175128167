/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */

import Tokenizers from './tokenizers';
import oParser from './options_parser';
import Transport from './transport';
import Remote from './remote';
import Prefetch from './prefetch';
import SearchIndex from './search_index';
import Utils from '../common/utils';

class Bloodhound {
    constructor(o) {
        o = oParser(o);

        this.sorter = o.sorter;
        this.identify = o.identify;
        this.sufficient = o.sufficient;

        this.local = o.local;
        this.remote = o.remote ? new Remote(o.remote) : null;
        this.prefetch = o.prefetch ? new Prefetch(o.prefetch) : null;

        // the backing data structure used for fast pattern matching
        this.index = new SearchIndex({
            identify: this.identify,
            datumTokenizer: o.datumTokenizer,
            queryTokenizer: o.queryTokenizer
        });

        // hold off on intialization if the intialize option was explicitly false
        o.initialize !== false && this.initialize();
    }

    static tokenizers = Tokenizers;

    __ttAdapter() {
        let that = this;

        return this.remote ? withAsync : withoutAsync;

        function withAsync(query, sync, async) {
            return that.search(query, sync, async);
        }

        function withoutAsync(query, sync) {
            return that.search(query, sync);
        }
    }

    async #loadPrefetch() {
        let serialized;
        return new Promise((resolve, reject) => {
            if (!this.prefetch) {
                resolve();
            } else if ((serialized = this.prefetch.fromCache())) {
                this.index.bootstrap(serialized);
                resolve();
            } else {
                this.prefetch.fromNetwork((err, data) => {
                    if (err) {
                        reject();
                    }

                    this.add(data);
                    this.prefetch.store(this.index.serialize());
                    resolve();
                });
            }
        });
    }

    #initialize() {
        // in case this is a reinitialization, clear previous data
        this.clear();
        (this.initPromise = this.#loadPrefetch()).then(() => {
            this.add(this.local);
        }); // local must be added to index after prefetch
        return this.initPromise;
    }

    initialize(force) {
        return !this.initPromise || force ? this.#initialize() : this.initPromise;
    }

    // TODO: before initialize what happens?
    add(data) {
        this.index.add(data);
        return this;
    }

    get(ids) {
        ids = Utils.isArray(ids) ? ids : [].slice.call(arguments);
        return this.index.get(ids);
    }

    search(query, sync, async) {
        let that = this,
            local;

        local = this.sorter(this.index.search(query));

        // return a copy to guarantee no changes within this scope
        // as this array will get used when processing the remote results
        sync(this.remote ? local.slice() : local);

        if (this.remote && local.length < this.sufficient) {
            this.remote.get(query, processRemote);
        } else if (this.remote) {
            // #149: prevents outdated rate-limited requests from being sent
            this.remote.cancelLastRequest();
        }

        return this;

        function processRemote(remote) {
            let nonDuplicates = [];

            // exclude duplicates
            Utils.each(remote, (r) => {
                !Utils.some(local, (l) => {
                    return that.identify(r) === that.identify(l);
                }) && nonDuplicates.push(r);
            });

            async && async(nonDuplicates);
        }
    }

    all() {
        return this.index.all();
    }

    clear() {
        this.index.reset();
        return this;
    }

    clearPrefetchCache() {
        this.prefetch && this.prefetch.clear();
        return this;
    }

    clearRemoteCache() {
        Transport.resetCache();
        return this;
    }

    // DEPRECATED: will be removed in v1
    ttAdapter() {
        return this.__ttAdapter();
    }
}

export default Bloodhound;

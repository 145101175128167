/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import Utils from '../common/utils';
import LruCache from './lru_cache';

class Transport {
    static #pendingRequestsCount = 0;
    static #pendingRequests = {};
    static #maxPendingRequests = 6;
    static #sharedCache = new LruCache(10);

    #get;
    #cache;

    constructor(o) {
        o = o || {};

        this.cancelled = false;
        this.lastReq = null;

        this.#get = o.limiter ? o.limiter(this.#_get) : this.#_get;

        this.#cache = o.cache === false ? new LruCache(0) : Transport.#sharedCache;
    }

    static setMaxPendingRequests(num) {
        Transport.#maxPendingRequests = num;
    }

    static resetCache() {
        Transport.#sharedCache.reset();
    }

    #fingerprint(o) {
        o = o || {};
        return o.url + o.type;
    }

    #_get(o, cb) {
        let that = this,
            fingerprint,
            jqXhr;

        fingerprint = this.#fingerprint(o);

        // #149: don't make a network request if there has been a cancellation
        // or if the url doesn't match the last url Transport#get was invoked with
        if (this.cancelled || fingerprint !== this.lastReq) {
            return;
        }

        // a request is already in progress, piggyback off of it
        if ((jqXhr = Transport.#pendingRequests[fingerprint])) {
            jqXhr.then(done).catch(fail);
        }

        // under the pending request threshold, so fire off a request
        else if (Transport.#pendingRequestsCount < Transport.#maxPendingRequests) {
            Transport.#pendingRequestsCount++;
            Transport.#pendingRequests[fingerprint] = fetch(o.url, {
                headers: o.headers,
                method: o.method
            })
                .then(done)
                .catch(fail)
                .finally(always);
        }

        // at the pending request threshold, so hang out in the on deck circle
        else {
            this.onDeckRequestArgs = [].slice.call(arguments, 0);
        }

        function done(resp) {
            if (resp.ok) {
                resp.json()
                    .then((result) => {
                        cb(null, result);
                        that.#cache.set(fingerprint, result);
                    })
                    .catch(() => cb(true));
            } else {
                cb(true);
            }
        }

        function fail() {
            cb(true);
        }

        function always() {
            Transport.#pendingRequestsCount--;
            delete Transport.#pendingRequests[fingerprint];

            // ensures request is always made for the last query
            if (that.onDeckRequestArgs) {
                that.#get.apply(that, that.onDeckRequestArgs);
                that.onDeckRequestArgs = null;
            }
        }
    }

    get(o, cb) {
        let resp, fingerprint;

        cb = cb || Utils.noop;
        o = Utils.isString(o) ? { url: o } : o || {};

        fingerprint = this.#fingerprint(o);

        this.cancelled = false;
        this.lastReq = fingerprint;

        // in-memory cache hit
        if ((resp = this.#cache.get(fingerprint))) {
            cb(null, resp);
        }

        // go to network
        else {
            this.#get(o, cb);
        }
    }

    cancel() {
        this.cancelled = true;
    }
}

export default Transport;

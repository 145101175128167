import EventEmitter from 'wolfy87-eventemitter';
import merge from 'lodash/merge';

class DOMMutationObserver extends EventEmitter {
    static EVENTS = {
        change: 'DomObserver.EVENTS.change'
    };

    static DEFAULT_SETTINGS = {
        subtree: true,
        attributes: false,
        childList: true,
        characterData: false
    };

    constructor(node, settings) {
        super();
        this.node = node;
        this.settings = merge({}, DOMMutationObserver.DEFAULT_SETTINGS, settings);

        this.observer = new MutationObserver((mutations /*, observer*/) => {
            this.emitEvent(DOMMutationObserver.EVENTS.change, [mutations]);
        });
        this.observer.observe(node, this.settings);
    }
}

export default DOMMutationObserver;

/* globals blueConicClient  */
import merge from 'lodash/merge';
import CdpObserver from '../../utils/CdpObserver';

class ClientsideHero {
    static DEFAULT_CONFIG = {
        selectors: {
            hero: '.hero',
            fallbackHero: '.hero noscript',
            heroLoader: '.hero .hero__loaderContainer'
        }
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, ClientsideHero.DEFAULT_CONFIG, config);

        this.init();
    }

    init() {
        if (window.cdpDisabled || window.cdpLoadError) {
            this.#showFallbackHero();
        } else {
            CdpObserver.on(CdpObserver.EVENTS.loadingDone, (success) => {
                //This event should never trigger because BC is loaded before NS Design
                if (!success) {
                    this.#showFallbackHero();
                } else {
                    this.#handleBC();
                }
            });

            this.#handleBC();

            //Fallback for when the above does not work for some reason
            window.addEventListener('load', () => {
                if (window.cdpLoadError || typeof blueConicClient === 'undefined') {
                    this.#showFallbackHero();
                }
            });
        }
    }

    #handleBC() {
        if (
            typeof blueConicClient !== 'undefined' &&
            typeof blueConicClient.event !== 'undefined' &&
            typeof blueConicClient.event.subscribe !== 'undefined'
        ) {
            this.#subscribeToBcEvent();
        } else {
            window.addEventListener(
                'onBlueConicLoaded',
                () => {
                    // BlueConic is loaded, now we can do API things
                    this.#subscribeToBcEvent();
                },
                false
            );
        }
    }

    /**
     * Show fallback hero and remove hero loader
     */
    #showFallbackHero() {
        if (this.node) {
            const fallbackHero = this.node.querySelector(this.config.selectors.fallbackHero);
            if (fallbackHero) {
                this.node.querySelector(this.config.selectors.heroLoader).outerHTML = fallbackHero.textContent;
            }
        }
    }

    #subscribeToBcEvent() {
        blueConicClient.event.subscribe(blueConicClient.event.onEventReady, {}, () => {
            this.#showFallbackHero();
        });
    }
}

export default ClientsideHero;

import ResponsiveTable from '../ResponsiveTable';
import DOMUtils from '../../utils/DOMUtils';
import merge from 'lodash/merge';

class RichText {
    static SELECTORS = {
        table: 'table'
    };

    static DEFAULT_CONFIG = {};

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, RichText.DEFAULT_CONFIG, config);

        this.responsiveTable();
    }

    responsiveTable() {
        if (!DOMUtils.isVisible(this.node)) {
            return;
        }
        this.node.querySelectorAll(RichText.SELECTORS.table).forEach((element) => {
            ResponsiveTable.init(element);
        });
    }
}

export default RichText;

<a href="{{cartUrl}}" class="ns-menu__menu-button unlink" data-eventdata="{
    &quot;data&quot;:{
        &quot;link_location&quot;:&quot;new_header&quot;,
        &quot;link_name&quot;:&quot;{{shoppingCartText}}&quot;,
        &quot;link_type&quot;:&quot;internal&quot;,
        &quot;link_value&quot;:&quot;{{cartUrl}}&quot;,
        &quot;link_data&quot;:{
            &quot;amount&quot;:&quot;{{#if cartItems}}{{cartItems}}{{/if}}&quot;}},
    &quot;event&quot;:&quot;link_click&quot;}">
    <span class="alt">{{shoppingCartText}}</span>
    <span class="ns-menu__miniCart-icon icon {{shoppingCartIcon}} icon--nsBlue">
        {{#if cartItems}}
        <span class="ns-menu__miniCart-amount">{{cartItems}}</span>
        <span class="alt"> {{cartAmountText}}</span>
        {{/if}}
    </span>
</a>

import OtherValue from '../OtherValue';
import merge from 'lodash/merge';

class OtherValueRadioButton extends OtherValue {
    static DEFAULT_CONFIG = {
        groupItemInput: '.radioButton__input'
    };
    constructor(node, config) {
        super(node, merge({}, OtherValueRadioButton.DEFAULT_CONFIG, config));
        return OtherValueRadioButton;
    }
}

export default OtherValueRadioButton;

import OtherValue from '../OtherValue';
import merge from 'lodash/merge';
class OtherValueCheckbox extends OtherValue {
    static DEFAULT_CONFIG = {
        groupItemInput: '.checkbox__input'
    };

    constructor(node, config) {
        super(node, merge({}, OtherValueCheckbox.DEFAULT_CONFIG, config));
        return OtherValueCheckbox;
    }
}
export default OtherValueCheckbox;

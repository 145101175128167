/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import Utils from '../common/utils';
import EventEmitter from './event_emitter';
import DOMUtils from '../../generic/utils/DOMUtils';

class Input extends EventEmitter {
    static #specialKeyCodeMap = {
        9: 'tab',
        27: 'esc',
        37: 'left',
        39: 'right',
        13: 'enter',
        38: 'up',
        40: 'down'
    };

    constructor(o, www) {
        super();
        o = o || {};

        if (!o.input) {
            Utils.error('input is missing');
        }

        www.mixin(this);

        this.hint = o.hint;
        this.input = o.input;

        // the query defaults to whatever the value of the input is
        // on initialization, it'll most likely be an empty string
        this.query = this.input.value;

        // for tracking when a change event should be triggered
        this.queryWhenFocused = this.hasFocus() ? this.query : null;

        // helps with calculating the width of the input's value
        this.overflowHelper = Input.#buildOverflowHelper(this.input);

        // detect the initial lang direction
        this.#checkLanguageDirection();

        // if no hint, noop all the hint related functions
        if (!this.hint) {
            this.setHint = this.getHint = this.clearHint = this.clearHintIfInvalid = Utils.noop;
        }
    }

    static normalizeQuery(str) {
        // strips leading whitespace and condenses all whitespace
        return Utils.toStr(str)
            .replace(/^\s*/g, '')
            .replace(/\s{2,}/g, ' ');
    }

    #onBlur() {
        this.resetInputValue();
        this.trigger('blurred');
    }

    #onFocus() {
        this.queryWhenFocused = this.query;
        this.trigger('focused');
    }

    #onKeydown(e) {
        // which is normalized and consistent (but not for ie)
        let keyName = Input.#specialKeyCodeMap[e.which || e.keyCode];

        this.#managePreventDefault(keyName, e);
        if (keyName && this.#shouldTrigger(keyName, e)) {
            this.trigger(keyName + 'Keyed', e);
        }
    }

    #onInput() {
        this.#setQuery(this.getInputValue());
        this.clearHintIfInvalid();
        this.#checkLanguageDirection();
    }

    #managePreventDefault(keyName, e) {
        let preventDefault;

        switch (keyName) {
            case 'up':
            case 'down':
                preventDefault = !Input.#withModifier(e);
                break;

            default:
                preventDefault = false;
        }

        preventDefault && e.preventDefault();
    }

    #shouldTrigger(keyName, e) {
        let trigger;

        switch (keyName) {
            case 'tab':
                trigger = !Input.#withModifier(e);
                break;

            default:
                trigger = true;
        }

        return trigger;
    }

    #checkLanguageDirection() {
        let dir = (getComputedStyle(this.input).direction || 'ltr').toLowerCase();

        if (this.dir !== dir) {
            this.dir = dir;
            if (this.hint) {
                this.hint.setAttribute('dir', dir);
            }
            this.trigger('langDirChanged', dir);
        }
    }

    #setQuery(val, silent) {
        let areEquivalent, hasDifferentWhitespace;

        areEquivalent = Input.#areQueriesEquivalent(val, this.query);
        hasDifferentWhitespace = areEquivalent ? this.query.length !== val.length : false;

        this.query = val;

        if (!silent && !areEquivalent) {
            this.trigger('queryChanged', this.query);
        } else if (!silent && hasDifferentWhitespace) {
            this.trigger('whitespaceChanged', this.query);
        }
    }

    bind() {
        let onBlur, onFocus, onKeydown, onInput;

        // bound functions
        onBlur = this.#onBlur.bind(this);
        onFocus = this.#onFocus.bind(this);
        onKeydown = this.#onKeydown.bind(this);
        onInput = this.#onInput.bind(this);

        this.input.addEventListener('blur', onBlur);
        this.input.addEventListener('focus', onFocus);
        this.input.addEventListener('keydown', onKeydown);

        // ie8 don't support the input event
        // ie9 doesn't fire the input event when characters are removed
        if (!Utils.isMsie() || Utils.isMsie() > 9) {
            this.input.addEventListener('input', onInput);
        } else {
            ['keydown', 'keypress', 'cut', 'paste'].forEach((event) =>
                this.input.addEventListener(event, (e) => {
                    // if a special key triggered this, ignore it
                    if (Input.#specialKeyCodeMap[e.which || e.keyCode]) {
                        return;
                    }

                    // give the browser a chance to update the value of the input
                    // before checking to see if the query changed
                    Utils.defer(this.#onInput.bind(this, e));
                })
            );
        }

        return this;
    }

    focus() {
        this.input.focus();
    }

    blur() {
        this.input.blur();
    }

    getLangDir() {
        return this.dir;
    }

    getQuery() {
        return this.query || '';
    }

    setQuery(val, silent) {
        this.setInputValue(val);
        this.#setQuery(val, silent);
    }

    hasQueryChangedSinceLastFocus() {
        return this.query !== this.queryWhenFocused;
    }

    getInputValue() {
        return this.input.value;
    }

    setInputValue(value) {
        this.input.value = value;
        this.clearHintIfInvalid();
        this.#checkLanguageDirection();
    }

    resetInputValue() {
        this.setInputValue(this.query);
    }

    getHint() {
        return this.hint.value;
    }

    setHint(value) {
        this.hint.value = value;
    }

    clearHint() {
        this.setHint('');
    }

    clearHintIfInvalid() {
        let val, hint, valIsPrefixOfHint, isValid;

        val = this.getInputValue();
        hint = this.getHint();
        valIsPrefixOfHint = val !== hint && hint.indexOf(val) === 0;
        isValid = val !== '' && valIsPrefixOfHint && !this.hasOverflow();

        !isValid && this.clearHint();
    }

    hasFocus() {
        return this.input.matches(':focus');
    }

    hasOverflow() {
        // 2 is arbitrary, just picking a small number to handle edge cases
        let constraint = this.input.getBoundingClientRect().width - 2;

        this.overflowHelper.textContent = this.getInputValue();

        return this.overflowHelper.getBoundingClientRect().width >= constraint;
    }

    isCursorAtEnd() {
        let valueLength, selectionStart, range;

        valueLength = this.input.value.length;
        selectionStart = this.input.selectionStart;

        if (Utils.isNumber(selectionStart)) {
            return selectionStart === valueLength;
        } else if (document.selection) {
            // NOTE: this won't work unless the input has focus, the good news
            // is this code should only get called when the input has focus
            range = document.selection.createRange();
            range.moveStart('character', -valueLength);

            return valueLength === range.text.length;
        }

        return true;
    }

    static #buildOverflowHelper(input) {
        const overflowHelper = DOMUtils.createElementFromHTML('<pre aria-hidden="true"></pre>');
        overflowHelper.style.position = 'absolute';
        overflowHelper.style.visibility = 'hidden';
        // avoid line breaks and whitespace collapsing
        overflowHelper.style.whiteSpace = 'pre';
        const inputStyle = getComputedStyle(input);
        // use same font css as input to calculate accurate width
        overflowHelper.style.fontFamily = inputStyle.fontFamily;
        overflowHelper.style.fontSize = inputStyle.fontSize;
        overflowHelper.style.fontStyle = inputStyle.fontStyle;
        overflowHelper.style.fontVariant = inputStyle.fontVariant;
        overflowHelper.style.fontWeight = inputStyle.fontWeight;
        overflowHelper.style.wordSpacing = inputStyle.wordSpacing;
        overflowHelper.style.letterSpacing = inputStyle.letterSpacing;
        overflowHelper.style.textIndent = inputStyle.textIndent;
        overflowHelper.style.textRendering = inputStyle.textRendering;
        overflowHelper.style.textTransform = inputStyle.textTransform;
        input.append(overflowHelper);
        return overflowHelper;
    }

    static #areQueriesEquivalent(a, b) {
        return Input.normalizeQuery(a) === Input.normalizeQuery(b);
    }

    static #withModifier(e) {
        return e.altKey || e.ctrlKey || e.metaKey || e.shiftKey;
    }
}

export default Input;

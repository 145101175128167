/* global Sentry, DD_LOGS, DD_RUM */
import FormValues from './FormValues';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import DOMUtils from '../utils/DOMUtils';

class FormValidation {
    static DEFAULT_CONFIG = {
        validationURL: '',
        classNames: {
            isPristineClass: 'is-pristine',
            errorClass: 'has-error'
        },
        selectors: {
            formfieldSelector: '.formfield',
            errorMessageSelector: '.formfield__errorMessage',
            inputSelector: '.formfield input, .formfield select, .formfield textarea',
            inputGroupSelector: '.formfield__inputGroup',
            processButtonSelector: '[data-formAction="processPage"]',
            previousButtonSelector: '[data-formAction="previousPage"]',
            errorSummarySelector: '[data-formElement="errorSummary"]',
            formfieldLabelTextSelector: '.formfield__labelText',
            formfieldLabelRequiredSelector: '.formfield__required'
        },
        // Object with the INVALID states/values of the used browser validity property.
        validityCheckProps: {
            // Indicates if the user has provided input that the browser is unable to convert.
            // For example, if you have a mail input element whose content does not have a '@'.
            badInput: true,
            // Returns true if the element's value is not in the correct syntax;otherwise false.
            typeMismatch: true,
            // Returns true if the element's value is longer than the provided maximum length; else it wil be false
            tooLong: true,
            // Returns true if the element has no value but is a required field; false otherwise.
            valueMissing: true,
            // Returns true if the element's value has no validity problems; false otherwise.
            valid: false
        },
        skipClientValidation: false,
        otherFieldValue: '-other',
        generalErrorMessage: document.documentElement.lang === 'nl' ? 'Er is een onbekende fout opgetreden' : 'An unknown error occurred'
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, FormValidation.DEFAULT_CONFIG, config);
    }

    attach(form) {
        this.form = form;
        this.#initFieldValidation();
        this.#attachListeners();
    }

    doValidation(element, pageIndex) {
        // Set to true if it's page validation instead of a single field validation;
        let isPageValidation = !isNaN(pageIndex);

        this.#clearErrors(element);
        return new Promise((resolve) => {
            this.#doValidityCheck(element, isPageValidation)
                .then(() => {
                    this.#doServerValidation(element)
                        .then(() => {
                            this.#displayValidationErrors(null, element, pageIndex, isPageValidation);
                            resolve();
                        })
                        .catch((errors) => {
                            this.#displayValidationErrors(errors, element, pageIndex, isPageValidation);
                        });
                })
                .catch((errors) => {
                    this.#displayValidationErrors(errors, element, pageIndex, isPageValidation);
                });
        });
    }

    #attachListeners() {
        let thisForm = this.form;
        thisForm.on('switchPage', () => this.#clearErrors(null));
    }

    #doValidityCheck(element, isPageValidation) {
        return new Promise((resolve, reject) => {
            let arrGroupContainers = [],
                formInputs = Array.from(element.querySelectorAll('input, select, textarea')).filter((input) => DOMUtils.isVisible(input)),
                groupContainers = () => {
                    return arrGroupContainers;
                };

            if (this.config.skipClientValidation) {
                resolve();
            }
            let errors = formInputs
                .filter((field) => {
                    return this.#filterGroupedInputs(field);
                })
                .map((input) => {
                    return this.#checkElementValidityState(input, isPageValidation);
                })
                .filter((error, index, items) => {
                    return this.#deduplicateErrors(error, index, items.length, groupContainers());
                })
                .filter(Boolean);

            if (isEmpty(errors)) {
                resolve();
            } else {
                reject(errors);
            }

            groupContainers = null;
        });
    }

    #filterGroupedInputs(element) {
        // check whether the element needs to be validated if it is part of a group (checkboxes, radiobuttons or "other" field)
        // for checkboxes and radiobuttons only validate if:
        // - 0 checked elements in the containing
        // for "other" field:
        // - only validate if the checkbox or radiobutton this belongs to is checked.

        let groupContainers = DOMUtils.parents(element, this.config.selectors.inputGroupSelector);

        if (groupContainers.length > 0) {
            let checkedInputs = Array.from(groupContainers[0].querySelectorAll('input:checked')),
                isOtherChoiceInput = element.type !== 'checkbox' && element.type !== 'radio',
                isOtherChoiceChecked = checkedInputs.some((e) => {
                    return e.value === this.config.otherFieldValue;
                });

            if (isOtherChoiceInput) {
                return isOtherChoiceChecked;
            } else {
                return checkedInputs.length <= 0;
            }
        } else {
            return true;
        }
    }

    #checkElementValidityState(element, forceCheck) {
        let isPristine = this.config.classNames.isPristineClass,
            validityCheckProps = this.config.validityCheckProps,
            formfield = element.closest(this.config.selectors.formfieldSelector),
            elementValidityMessages = JSON.parse(formfield.dataset.validityMessages || '{}');

        if (element.validity) {
            for (let key in validityCheckProps) {
                let validityKey = element.validity[key],
                    keyIsInvalid = validityKey === validityCheckProps[key];

                if (keyIsInvalid && (!element.classList.contains(isPristine) || forceCheck)) {
                    let elementValidityMessage = key === 'badInput' ? elementValidityMessages.typeMismatch : elementValidityMessages[key];

                    return {
                        message: elementValidityMessage || elementValidityMessages.valid,
                        inputName: element.name,
                        inputLabel: this.#getLabelText(element)
                    };
                }
            }
        }

        return null;
    }

    #deduplicateErrors(error, index, total, groupContainers) {
        if (!error || !error.inputName) {
            return;
        }

        let input = this.#getInputByName(error.inputName)[0];
        let parents = DOMUtils.parents(input, this.config.selectors.inputGroupSelector);
        let groupContainer = parents.length > 0 ? parents[0] : undefined;

        if (!groupContainer) {
            // input is not in a group, so no need for deduplication
            return true;
        } else if (!groupContainers || groupContainers.indexOf(groupContainer) < 0) {
            // only return one error for all elements in a group. store group container in this object for next comparison
            groupContainers.push(groupContainer);
            return true;
        }
        return false;
    }

    async #doServerValidation(formfields) {
        const formValues = FormValues.getFormValues(formfields);
        const errorHandler = (reject) => {
            let errors = Object.keys(formValues).map((key) => {
                return {
                    message: this.config.generalErrorMessage,
                    inputName: key,
                    inputLabel: this.#getLabelText(document.getElementsByName(key)[0])
                };
            });
            reject(errors);
        };
        return new Promise((resolve, reject) => {
            fetch(this.config.validationURL, {
                body: new URLSearchParams(formValues),
                headers: {
                    Accept: 'application/json'
                },
                method: 'POST'
            })
                .then((res) => {
                    if (res.ok) {
                        res.json().then((data) => {
                            if (isEmpty(data)) {
                                //no errors
                                resolve();
                            } else {
                                let keys = Object.keys(data);
                                let errors = keys.map((key) => {
                                    return {
                                        message: data[key].localizedMessage,
                                        inputName: key,
                                        inputLabel: data[key].label || key
                                    };
                                });
                                reject(errors);
                            }
                        });
                    } else {
                        errorHandler(reject);
                    }
                })
                .catch((error) => {
                    if (typeof Sentry !== 'undefined') {
                        try {
                            Sentry.captureException(error);
                        } catch (e) {
                            //Ignore
                        }
                    }
                    if (typeof DD_LOGS !== 'undefined') {
                        try {
                            DD_LOGS.logger.error('Error doing server side validation', {}, error);
                        } catch (e) {
                            //Ignore
                        }
                    }
                    if (typeof DD_RUM !== 'undefined') {
                        try {
                            DD_RUM.addError(error);
                        } catch (e) {
                            //Ignore
                        }
                    }
                    errorHandler(reject);
                });
        });
    }

    #getLabelText(element) {
        let formfield = element.closest(this.config.selectors.formfieldSelector),
            formfieldLabel = formfield.dataset.label;

        //if there is no label, use input name
        return formfieldLabel || element.name;
    }

    #displayValidationErrors(errors, element, pageIndex, isPageValidation) {
        errors?.forEach((error) => {
            let inputs = this.#getInputByName(error.inputName),
                message = error.message.trim();
            this.#setFieldErrors(inputs, message);
        });

        if (isPageValidation && errors && errors.length) {
            let thisForm = this.form;
            thisForm.emit('updateErrorSummary', element, errors);
        }
    }

    #setFieldErrors(elements, message) {
        elements.forEach((element) => {
            let formfield = element.closest(this.config.selectors.formfieldSelector),
                errorMessageHolder = formfield.querySelector(this.config.selectors.errorMessageSelector);

            if (errorMessageHolder) {
                errorMessageHolder.innerText = message;
            }
            formfield.classList.add(this.config.classNames.errorClass);
        });
    }

    #clearErrors(element) {
        let formFields;
        if (element) {
            formFields = element.matches(this.config.selectors.formfieldSelector)
                ? [element]
                : Array.from(element.querySelectorAll(this.config.selectors.formfieldSelector));
        } else {
            formFields = Array.from(this.node.querySelectorAll(this.config.selectors.formfieldSelector));
        }
        formFields.forEach((formField) => {
            formField.classList.remove(this.config.classNames.errorClass);
            formField.querySelector(this.config.selectors.errorMessageSelector)?.replaceChildren();
        });
    }

    #initFieldValidation() {
        let ajaxValidationUrl = this.config.validationURL,
            form = this.node,
            formInputs = Array.from(form.querySelectorAll(this.config.selectors.inputSelector)),
            formButtons = Array.from(
                form.querySelectorAll(this.config.selectors.processButtonSelector + ',' + this.config.selectors.previousButtonSelector)
            ),
            isPristine = this.config.classNames.isPristineClass;

        if (ajaxValidationUrl) {
            let inputTypeFields = ['email', 'search', 'text', 'textarea', 'tel', 'number', 'url'];

            // only add the pristine class to input type fields
            formInputs.forEach((field) => {
                if (inputTypeFields.includes(field.type)) {
                    field.classList.add(isPristine);
                }

                // Pristine still needs to be removed on textfields on the key-up event
                field.addEventListener('keyup', (e) => {
                    // Check the validity for fields who return nothing on invalid input,
                    // so the "pristine" class can be removed.
                    let isInValid = e.target.validity && e.target.validity.badInput;

                    if (field.value.length > 0 || isInValid) {
                        field.classList.remove(isPristine);
                    }
                });

                field.addEventListener('change', (e) => {
                    // Don't validate in any of the below situations:
                    // (1) the change is triggered during a submit, which will result in a complete page validation
                    // (2) the input is on 'other value' checkbox/radio is blurred, in this case validation must be postponed
                    //     until the associated 'other value' textinput has received input

                    let input = e.target;
                    let relatedTarget = this.relatedFormElement;

                    let inputIsCheckedAndForOtherField = input.checked && input.value === this.config.otherFieldValue;
                    let relatedTargetIsSubmit = relatedTarget && relatedTarget.type === 'submit';

                    if (inputIsCheckedAndForOtherField || relatedTargetIsSubmit) {
                        return;
                    }

                    // Field is ready for validation
                    let formField = input.closest(this.config.selectors.formfieldSelector);
                    this.doValidation(formField);
                });
            });

            // Set flag for input blur event to listen to when clicking submit button
            formButtons.forEach((formButton) => {
                formButton.addEventListener('mousedown', (e) => {
                    this.relatedFormElement = e.delegateTarget;
                });
                formButton.addEventListener('mouseup', () => {
                    this.relatedFormElement = null;
                });
            });
        }
    }

    #getInputByName(name) {
        return Array.from(this.node.querySelectorAll(`.formfield *[name="${name}"]`));
    }
}

export default FormValidation;

import Handlebars from 'handlebars/dist/handlebars';
import templateVideoPlayer from './videoPlayer.tpl';
import merge from 'lodash/merge';
import DOMUtils from '../../../utils/DOMUtils';

class VideoPlayer {
    playerId = 0;
    template;

    constructor(node, config) {
        this.config = merge({}, {}, config);

        this.config.id = this.#getPlayerId();

        if (node) {
            this.node = node;
        } else {
            this.node = this.#createPlayerDOMFragment();
        }
    }

    getNode() {
        return this.node;
    }

    init() {
        if (!window.bc) {
            console.error('Brightcove javascript is not loaded, VideoPlayer cannot continue.');
            return;
        }

        // if not added to dom yet: wait for this to happen until initializing.
        if (!this.node.closest('body')) {
            setTimeout(() => this.init(), 5);
            return;
        }
        this.node.classList.remove('is-hidden');

        let videoNode = this.node.querySelector('.video-js');
        let id = videoNode.id;

        // window.bc is the brightcove library that should be included separately
        // in the html.
        this.videojs = window.bc(id);
    }

    destroy() {
        this.videojs.dispose();
        this.node.remove();
        this.node = null;
        this.videojs = null;
        this.config = null;
    }

    #createPlayerDOMFragment() {
        if (!this.template) {
            this.template = Handlebars.compile(templateVideoPlayer);
        }

        return DOMUtils.createElementFromHTML(this.template(this.config));
    }

    #getPlayerId() {
        return 'videoplayer_' + this.playerId++;
    }
}

export default VideoPlayer;

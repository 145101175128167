/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import Utils from '../common/utils';
import VERSION from './version';

export default function oParser(o) {
    let defaults, sorter;

    defaults = {
        initialize: true,
        identify: Utils.stringify,
        datumTokenizer: null,
        queryTokenizer: null,
        sufficient: 5,
        sorter: null,
        local: [],
        prefetch: null,
        remote: null
    };

    o = Utils.mixin(defaults, o || {});
    // throw error if required options are not set
    !o.datumTokenizer && Utils.error('datumTokenizer is required');
    !o.queryTokenizer && Utils.error('queryTokenizer is required');

    sorter = o.sorter;
    o.sorter = sorter
        ? function (x) {
              return x.sort(sorter);
          }
        : Utils.identity;

    o.local = Utils.isFunction(o.local) ? o.local() : o.local;
    o.prefetch = parsePrefetch(o.prefetch);
    o.remote = parseRemote(o.remote);

    return o;
}

function parsePrefetch(o) {
    let defaults;

    if (!o) {
        return null;
    }

    defaults = {
        url: null,
        ttl: 24 * 60 * 60 * 1000, // 1 day
        cache: true,
        cacheKey: null,
        thumbprint: '',
        prepare: Utils.identity,
        transform: Utils.identity,
        transport: null
    };

    // support basic (url) and advanced configuration
    o = Utils.isString(o) ? { url: o } : o;
    o = Utils.mixin(defaults, o);

    // throw error if required options are not set
    !o.url && Utils.error('prefetch requires url to be set');

    // DEPRECATED: filter will be dropped in v1
    o.transform = o.filter || o.transform;

    o.cacheKey = o.cacheKey || o.url;
    o.thumbprint = VERSION + o.thumbprint;
    return o;
}

function parseRemote(o) {
    let defaults;

    if (!o) {
        return;
    }

    defaults = {
        url: null,
        cache: true, // leave undocumented
        prepare: null,
        replace: null,
        wildcard: null,
        limiter: null,
        rateLimitBy: 'debounce',
        rateLimitWait: 300,
        transform: Utils.identity,
        transport: null
    };

    // support basic (url) and advanced configuration
    o = Utils.isString(o) ? { url: o } : o;
    o = Utils.mixin(defaults, o);

    // throw error if required options are not set
    !o.url && Utils.error('remote requires url to be set');

    // DEPRECATED: filter will be dropped in v1
    o.transform = o.filter || o.transform;

    o.prepare = toRemotePrepare(o);
    o.limiter = toLimiter(o);

    delete o.replace;
    delete o.wildcard;
    delete o.rateLimitBy;
    delete o.rateLimitWait;

    return o;
}

function toRemotePrepare(o) {
    let prepare, replace, wildcard;

    prepare = o.prepare;
    replace = o.replace;
    wildcard = o.wildcard;

    if (prepare) {
        return prepare;
    }

    if (replace) {
        prepare = prepareByReplace;
    } else if (o.wildcard) {
        prepare = prepareByWildcard;
    } else {
        prepare = idenityPrepare;
    }

    return prepare;

    function prepareByReplace(query, settings) {
        settings.url = replace(settings.url, query);
        return settings;
    }

    function prepareByWildcard(query, settings) {
        settings.url = settings.url.replace(wildcard, encodeURIComponent(query));
        return settings;
    }

    function idenityPrepare(query, settings) {
        return settings;
    }
}

function toLimiter(o) {
    let limiter, method, wait;

    limiter = o.limiter;
    method = o.rateLimitBy;
    wait = o.rateLimitWait;

    if (!limiter) {
        limiter = /^throttle$/i.test(method) ? throttle(wait) : debounce(wait);
    }

    return limiter;

    function debounce(wait) {
        return function debounce(fn) {
            return Utils.debounce(fn, wait);
        };
    }

    function throttle(wait) {
        return function throttle(fn) {
            return Utils.throttle(fn, wait);
        };
    }
}

import Handlebars from 'handlebars/dist/handlebars';
import template from '../../jstemplates/autosuggest/template.tpl';
import ClearableInput from '../ClearableInput';
import AutosuggestAccessibility from '../AutosuggestAccessibility';
import publishTagmanagementEvent from '../../utils/publishTagmanagementEvent';
import defer from 'lodash/defer';

import Plugin from '../../../typeahead/typeahead/plugin';
import Bloodhound from '../../../typeahead/bloodhound/bloodhound';
import DOMUtils from '../../utils/DOMUtils';
import merge from 'lodash/merge';

class Autosuggest {
    static DEFAULT_CONFIG = {
        hint: false,
        autocomplete: false,
        highlight: false,
        minLength: 1,
        suggestUrl: '',
        suggestHeaders: null,
        extraParam: '',
        limit: 5,
        staticSource: '',
        defaultSuggestions: [],
        forceSelection: false,
        preventSubmit: true,
        autoSubmit: false,
        submitValue: 'name',
        displayValue: 'name',
        apiType: 'search',
        ariaIdPrefix: 'as',
        submitByEvent: false,
        hideClear: false,

        selectors: {
            input: '.searchField, .textInput--search',
            submitButton: 'input[type="submit"]'
        },

        classNames: {
            input: 'autosuggest__input',
            hint: 'autosuggest__hint',
            menu: 'autosuggest__list',
            dataset: 'autosuggest__dataset',
            suggestion: 'autosuggest__suggestion',
            empty: 'autosuggest__empty',
            open: 'autosuggest__open',
            cursor: 'autosuggest__cursor',
            highlight: 'active',
            selectable: 'autosuggest__selectable',
            listItem: 'autosuggest__listItem'
        }
    };

    static instanceCount = 0;

    constructor(node, config) {
        this.node = node;

        this.config = merge({}, Autosuggest.DEFAULT_CONFIG, config);

        // find and set jQuery object variables
        this.input = this.node.querySelector(this.config.selectors.input);
        this.submit = this.node.querySelector(this.config.selectors.submitButton);

        // original input value who the user has typed (requiered for relay analytics);
        this.originalInputValue = '';

        // save selected value on force selection (hover)
        this.selectedValue = '';

        // serves as for submitValue, to check values
        this.suggestionCache = [];

        // create a unique id
        this.autosuggestID = this.config.ariaIdPrefix + Autosuggest.instanceCount++;

        // create result engine
        this.initBloodHound();

        // initialize events for show and hide
        this.initTypeAhead();

        // create default JSON suggestion list
        this.defaultJSONSuggestions = this.createDefaultJSONSuggestions();

        // disable submit button if selection of the autosuggestions is forced, do not allow free input submission
        if (this.config.forceSelection && this.config.preventSubmit) {
            this.setSubmitDisabled(true);
        }

        if (!this.config.hideClear) {
            // Always after initTypeAhead(); because typeahead DOM manipulation
            this.clearableInput = new ClearableInput(this.input, this.config);

            // initialize for listener for clear
            this.listenForClearField();
        }
        this.bindEvents();
        this.autosuggestAccessibility = new AutosuggestAccessibility(this.node, this.config);
    }

    /**
     * Bind autosuggest events
     */
    bindEvents() {
        this.input.addEventListener('keydown', (event) => {
            this.keyObj = event;
        });

        // on form submit
        this.node.addEventListener('submit', (event) => {
            this.onFormSubmit(event);
        });

        // on mouseover / focus
        ['mouseover', 'focus'].forEach((event) =>
            DOMUtils.addEventListener(this.input, event, '.' + this.config.classNames.listItem, (event) => {
                this.onHoverUpdate(event);
            })
        );

        // when the item is clicked or selected (active select with enter or click) submit the form
        // we are listening to typeahead:select event
        this.input.addEventListener('typeahead:select', (e) => {
            this.triggerInput();
            const data = e.detail.args[0];
            this.saveSuggestionValue(data[this.config.displayValue]);
            this.resolveSelectAction();

            /* eslint-disable camelcase */
            let selectedIndex = this.suggestionCache.indexOf(data);
            if (selectedIndex < 0) {
                selectedIndex = this.defaultJSONSuggestions.indexOf(data);
            }
            const interactionEvent = merge(
                {},
                {
                    interaction_type: 'autosuggest',
                    interaction_action: 'select',
                    interaction_name: this.selectedValue,
                    interaction_data: {
                        selectedIndex: selectedIndex,
                        numberOfSuggestions: this.suggestionCache.length || this.defaultJSONSuggestions.length,
                        originalSearchValue: this.originalInputValue
                    }
                },
                this.config.extraEventData
            );
            /* eslint-enable */

            publishTagmanagementEvent(null, 'website_interaction', interactionEvent);
        });

        this.input.addEventListener('typeahead:cursorchange', (e) => {
            if (!this.config.hideClear) {
                this.updateClearButtonState();
            }
            const data = e.detail.args[0] || undefined;
            if (data) {
                this.saveSuggestionValue(data[this.config.displayValue]);
            }
        });

        this.input.addEventListener('typeahead:autocomplete', () => {
            if (!this.config.hideClear) {
                this.clearableInput.showClearButton();
            }
        });

        if (this.config.forceSelection) {
            // disable submit button when autosuggest is closed.
            this.input.addEventListener('typeahead:close', () => {
                if (this.config.preventSubmit) {
                    // CMS-3594: a short timeout before disabling the submit button.
                    // the reason: when the typeahead wants to close it disables the submit button.
                    // but, when it closes because the user clicks the submitbutton, without this timeout
                    // the button will be disabled before it can execute the submit.
                    // 500ms seemed an ok timeout. Much less somehow doesn't work.
                    setTimeout(() => {
                        let found = false,
                            value = this.input.value.toLowerCase();
                        this.suggestionCache.forEach((item) => {
                            if (item[this.config.displayValue].toLowerCase() === value) {
                                found = true;
                            }
                        });
                        this.setSubmitDisabled(!found);
                    }, 500);
                }
            });
            // automatically select/highlight the first suggestion
            ['typeahead:render', 'typeahead:open'].forEach((event) =>
                this.input.addEventListener(event, (e) => this.selectFirstSuggestion(e, e.detail.args[0]))
            );
            // on mouseout
            DOMUtils.addEventListener(this.input, 'mouseout', '.' + this.config.classNames.listItem, (e) =>
                this.selectFirstSuggestion(e, e.detail.args[0])
            );
        }
    }

    /**
     * Save text of selected autosuggest value
     */
    saveSuggestionValue(value) {
        this.selectedValue = value;
    }

    /**
     * Automatically select/highlight the first suggestion
     */
    selectFirstSuggestion(e, firstSuggestion) {
        const suggestions = this.getSuggestionItems();

        if (this.input.value.length >= this.config.minLength && suggestions.length) {
            suggestions.forEach((suggestion, index) => {
                suggestion.classList.remove(this.config.classNames.cursor);
                if (index === 0) {
                    suggestion.classList.add(this.config.classNames.cursor);
                }
            });
            if (firstSuggestion) {
                this.saveSuggestionValue(firstSuggestion[this.config.displayValue]);
            }
            if (this.config.preventSubmit) {
                this.setSubmitDisabled(false);
            }
        } else {
            if (this.config.preventSubmit) {
                this.setSubmitDisabled(true);
            }
        }
    }

    /**
     * When the form has been submitted, check if the input field is not empty,
     * if it is empty, don't submit the form
     */
    onFormSubmit(event) {
        if (this.config.preventSubmit) {
            if (this.input.value.length <= 0) {
                event.preventDefault();
                return false;
            }
        }

        if (this.config.forceSelection && this.config.preventSubmit) {
            if (!this.getSuggestionItems().length) {
                event.preventDefault();
                return false;
            }

            if (this.config.submitValue !== this.config.displayValue) {
                // filter correct submitValue from clicked displayValue
                const filter = {};
                filter[this.config.displayValue] = this.selectedValue;
                let keys = Object.keys(filter);
                const value = this.suggestionCache.filter((item) => keys.every((key) => item[key] === filter[key]));
                this.input.value = value[0][this.config.submitValue];
                this.triggerInput();
            }
        }

        // Valid submit, continue but close the typeahead.
        // Not noticed when using server side pagerefresh,
        // but on SPA the autosuggest is not closed when using
        // a value not from the autosuggest options.
        this.close();
    }

    /**
     * When suggestion gets a hover, remove autosuggest__cursor on suggestion and sync with the hover state
     */
    onHoverUpdate(event) {
        const currentTarget = event.target;

        this.getSuggestionItems().forEach((suggestion) => suggestion.classList.remove(this.config.classNames.cursor));
        currentTarget.classList.add(this.config.classNames.cursor);
    }

    /**
     * Sets the right action after a select from the suggestions list
     */
    resolveSelectAction() {
        const checkSubmitValidity = () => {
            const autoSubmit = this.getBoolean(this.config.autoSubmit);
            if (autoSubmit) {
                // Do nothing when the tab key is pressed
                if (this.keyObj && this.keyObj.key === 'Tab') {
                    return false;
                } else {
                    this.submitForm();
                }
                this.keyObj = null;
            }
        };

        // wait a millisecond to get the last key code
        defer(() => {
            checkSubmitValidity();
        });

        if (!this.config.hideClear) {
            this.updateClearButtonState();
        }
    }

    /**
     * submits the form
     */
    submitForm() {
        if (!this.getBoolean(this.config.submitByEvent)) {
            this.node.submit();
        } else {
            let submitEvent;
            try {
                submitEvent = new Event('submit', { bubbles: true });
            } catch (e) {
                // For IE
                submitEvent = document.createEvent('Event');
                submitEvent.initEvent('submit', true, true);
            }
            this.node.dispatchEvent(submitEvent);
        }
    }

    /**
     * Checks the state of the clearbutton
     */
    updateClearButtonState() {
        if (this.input.value.length > 0) {
            this.clearableInput.showClearButton();
        } else {
            this.clearableInput.hideClearButton();
        }
    }

    /**
     * Hide the list when the input has been cleared
     */
    listenForClearField() {
        // we will make this field a clearable inputfield
        this.clearableInput.on(ClearableInput.EVENTS.cleared, () => {
            // clear the proper field
            this.typeaheadPlugin.val('');
            this.selectedValue = '';
        });
    }

    /**
     * Set state of submit button
     */
    setSubmitDisabled(disableSubmit) {
        if (this.submit) {
            this.submit.classList.toggle('is-disabled', !!disableSubmit);
            this.submit.disabled = !!disableSubmit;
        }
    }

    /**
     * Close the autosuggest
     */
    close() {
        this.typeaheadPlugin.close();
    }

    /**
     * Bloodhound is used to call search query and to parse the result
     * because the result is one level deeper, we use transform to pull the
     * proper resultset from remote url
     */
    initBloodHound() {
        const bloodHoundConfig = {
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('description'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: this.config.suggestUrl + '%QUERY%' + this.config.extraParam,
                prepare: (query, settings) => {
                    this.originalInputValue = query;
                    settings.url = settings.url.replace('%QUERY%', encodeURIComponent(query));
                    if (this.config.suggestHeaders) {
                        settings.headers = this.config.suggestHeaders;
                    }
                    return settings;
                },
                wildcard: '%QUERY%',
                transform: (response) => {
                    let result;
                    if (this.config.apiType === 'stationsinfo') {
                        result = response;
                    } else if (this.config.apiType === 'searchv2') {
                        result = response.suggestions.slice();
                        if (response.didyoumean) {
                            result.push(response.didyoumean);
                        }
                    } else {
                        result = response.results;
                    }

                    this.suggestionCache = result;
                    return result;
                }
            }
        };

        // Override if we've set a static source
        if (this.config.staticSource) {
            bloodHoundConfig.sufficient = 1;
            bloodHoundConfig.prefetch = {
                url: this.config.staticSource,
                transform: (response) => {
                    return response;
                }
            };
            delete bloodHoundConfig.remote;
        }

        this.bloodhound = new Bloodhound(bloodHoundConfig);

        if (this.config.staticSource) {
            this.bloodhound.clearPrefetchCache();
        }

        this.bloodhound.initialize();
    }

    /**
     * Get autosuggest results
     */
    initTypeAhead() {
        // precompile template. this is called outside the 'suggestion' function where it's used to
        // actually take advantage of performance improvement associated with precompiling.
        const compiledTemplate = Handlebars.compile(template);
        this.typeaheadPlugin = new Plugin(this.input);
        // typeahead configuration, we predefine our own classnames so we can style the components on our own way
        this.typeaheadPlugin.initialize(
            this.input,
            {
                classNames: this.config.classNames,
                hint: this.config.hint,
                highlight: this.config.highlight,
                minLength: 0,
                autocomplete: this.config.autocomplete
            },
            {
                name: 'results',
                display: this.config.displayValue,
                limit: this.config.limit,
                source: (q, sync, async) => this.processRequest(q, sync, async),
                templates: {
                    suggestion: (data) => {
                        const name = data[this.config.displayValue];
                        return compiledTemplate(data, {
                            // helper is not stored globally in Handlebars,
                            // but only used with this specific template.
                            helpers: {
                                uniqueItemID: this.autosuggestID + '_' + name.replace(/\s+/g, '-').toLowerCase(),
                                displayKey: (context) => {
                                    return context.data.root[this.config.displayValue];
                                }
                            }
                        });
                    }
                }
            }
        );
    }

    clear() {
        this.typeaheadPlugin.val('');
    }

    /**
     * Process request to show default suggestions or search
     *
     * @param q
     * @param sync
     * @param async
     */
    processRequest(q, sync, async) {
        if (q.length < this.config.minLength) {
            sync(this.defaultJSONSuggestions);
        } else {
            if (this.config.staticSource) {
                this.bloodhound.search(q, sync);
            } else {
                this.bloodhound.search(q, sync, async);
            }
        }
    }

    /**
     * Creates default JSON suggestions converting from a comma-separated string
     */
    createDefaultJSONSuggestions() {
        return this.config.defaultSuggestions.map((suggestion) => {
            const result = {};
            result[this.config.displayValue] = suggestion;
            result[this.config.submitValue] = suggestion;
            return result;
        });
    }

    /**
     * Returns a true boolean when a 'string' boolean 'true'/'false' is given
     */

    getBoolean(param) {
        return typeof param === 'boolean' ? param : param === 'true';
    }

    getSuggestionItems() {
        return this.node.querySelectorAll('.' + this.config.classNames.listItem);
    }

    triggerInput() {
        let inputEvent;
        try {
            inputEvent = new Event('input');
        } catch (e) {
            // For IE
            inputEvent = document.createEvent('Event');
            inputEvent.initEvent('input', true, true);
        }
        this.input.dispatchEvent(inputEvent);
    }
}

export default Autosuggest;

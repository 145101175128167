/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import every from 'lodash/every';
import some from 'lodash/some';
import filter from 'lodash/filter';
import map from 'lodash/map';

class Utils {
    static isMsie() {
        // from https://github.com/ded/bowser/blob/master/bowser.js
        return /(msie|trident)/i.test(navigator.userAgent) ? navigator.userAgent.match(/(msie |rv:)(\d+(.\d+)?)/i)[2] : false;
    }

    static isBlankString(str) {
        return !str || /^\s*$/.test(str);
    }

    // http://stackoverflow.com/a/6969486
    static escapeRegExChars(str) {
        return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    }

    static isString(obj) {
        return typeof obj === 'string';
    }

    static isNumber(obj) {
        return typeof obj === 'number';
    }

    static isArray = Array.isArray;

    static isFunction(value) {
        return typeof value === 'function';
    }

    static isObject(obj) {
        if (typeof obj !== 'object' || obj.nodeType || (obj !== null && obj !== undefined && obj === obj.window)) {
            return false;
        }

        return !(obj.constructor && !Object.prototype.hasOwnProperty.call(obj.constructor.prototype, 'isPrototypeOf'));
    }

    static isUndefined(obj) {
        return typeof obj === 'undefined';
    }

    static isElement(obj) {
        return !!(obj && obj.nodeType === 1);
    }

    static toStr(s) {
        return Utils.isUndefined(s) || s === null ? '' : s + '';
    }

    static each = each;

    static map = map;

    static filter = filter;

    static every = every;

    static some = some;

    static mixin = assign;

    static identity(x) {
        return x;
    }

    static clone(obj) {
        return cloneDeep(obj);
    }

    static getIdGenerator() {
        let counter = 0;
        return function () {
            return counter++;
        };
    }

    static templatify(obj) {
        return typeof obj === 'function' ? obj : template;

        function template() {
            return String(obj);
        }
    }

    static defer(fn) {
        setTimeout(fn, 0);
    }

    static debounce(func, wait, immediate) {
        let timeout, result;

        return function () {
            let context = this,
                args = arguments,
                later,
                callNow;

            later = function () {
                timeout = null;
                if (!immediate) {
                    result = func.apply(context, args);
                }
            };

            callNow = immediate && !timeout;

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);

            if (callNow) {
                result = func.apply(context, args);
            }

            return result;
        };
    }

    static throttle(func, wait) {
        let context, args, timeout, result, previous, later;

        previous = 0;
        later = function () {
            previous = new Date();
            timeout = null;
            result = func.apply(context, args);
        };

        return function () {
            let now = new Date(),
                remaining = wait - (now - previous);

            context = this;
            args = arguments;

            if (remaining <= 0) {
                clearTimeout(timeout);
                timeout = null;
                previous = now;
                result = func.apply(context, args);
            } else if (!timeout) {
                timeout = setTimeout(later, remaining);
            }

            return result;
        };
    }

    static stringify(val) {
        return Utils.isString(val) ? val : JSON.stringify(val);
    }

    static noop() {
        // This is intentional
    }

    static error(msg) {
        throw new Error(msg);
    }
}

export default Utils;

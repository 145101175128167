import merge from 'lodash/merge';
import DOMUtils from '../utils/DOMUtils';

class TabSequenceWatch {
    #goToFirstElement = false;
    #goToLastElement = false;

    static ELEMENT_SELECTOR = ['a', 'button', 'input', 'textarea', 'select', '[tabindex]']
        .map((value) => {
            return value + ':not([tabindex|="-1"])';
        })
        .join(', ');

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, config);

        this.enabled = false;
    }

    enable() {
        if (this.enabled) {
            // prevent double adding of event handlers.
            this.disable();
        }

        this.#getElements();
        this.#monitorTabs();
        this.enabled = true;
    }

    disable() {
        document.removeEventListener('keydown', this.#keyDownListener);
        document.removeEventListener('keyup', this.#keyUpListener);
        this.enabled = false;
    }

    #getElements() {
        let elements = Array.from(this.node.querySelectorAll(TabSequenceWatch.ELEMENT_SELECTOR)).filter((element) => {
            return DOMUtils.isVisible(element);
        });

        this.totalElements = elements.length;
        this.firstFocusElement = elements[0];
        this.lastFocusElement = elements[this.totalElements - 1];
    }

    #keyDownListener = (e) => {
        let keyCode = e.keyCode;
        let activeElement = document.activeElement;

        if (keyCode === 9) {
            // Tab key
            // If there is only 1 focusable element,
            // and it's already focused, do nothing.
            if (this.totalElements === 1 && activeElement === this.firstFocusElement) {
                return false;
            }
            // Tab + shift key
            if (e.shiftKey) {
                if (activeElement === this.firstFocusElement) {
                    if (this.totalElements > 1) {
                        e.preventDefault();
                    }
                    this.#goToLastElement = true;
                }
            }
            // Tab key only
            else {
                if (activeElement === this.lastFocusElement) {
                    // Prevent focus outside the bounding box
                    if (this.totalElements > 1) {
                        e.preventDefault();
                    }
                    this.#goToFirstElement = true;
                }
            }
        }
    };

    #keyUpListener = () => {
        if (this.#goToFirstElement) {
            this.firstFocusElement.focus();
            this.#goToFirstElement = false;
        }
        if (this.#goToLastElement) {
            this.lastFocusElement.focus();
            this.#goToLastElement = false;
        }
    };
    #monitorTabs() {
        document.addEventListener('keydown', this.#keyDownListener);
        document.addEventListener('keyup', this.#keyUpListener);
    }
}

export default TabSequenceWatch;

import Responsive from '../utils/Responsive';
import getScrollbarWidth from '../utils/getScrollbarWidth';
import merge from 'lodash/merge';

class ScrollBlocker {
    static CONFIG = {
        showingOverlayClass: 'is-showingOverlay',
        scrollbarSpacerTargets: 'body, .header'
    };

    constructor() {
        this.body = document.body;
        this.html = document.documentElement;

        this.config = merge({}, ScrollBlocker.CONFIG);

        Responsive.on(Responsive.EVENTS.resized, () => {
            this.#updateScrollbarWidth();
        });
    }

    disableScroll() {
        if (this.body.classList.contains(this.config.showingOverlayClass)) {
            return;
        }

        if (!this.scrollbarWidth) {
            this.#updateScrollbarWidth();
        }

        this.scrollPosition = {
            x: window.scrollX,
            y: window.scrollY
        };
        this.#addScrollbarPadding();

        this.body.style.position = 'fixed';
        this.body.style.overflowY = 'hidden';
        this.body.style.backfaceVisibility = 'hidden';
        this.body.style.webkitBackfaceVisibility = 'hidden';
        this.body.style.top = -this.scrollPosition.y + 'px';
        this.body.style.width = '100%';
    }

    enableScroll() {
        this.body.style.position = '';
        this.body.style.overflowY = '';
        this.body.style.backfaceVisibility = '';
        this.body.style.webkitBackfaceVisibility = '';
        this.body.style.top = '';
        this.body.style.width = '';

        this.html.style.scrollBehavior = 'auto';
        if (this.scrollPosition) {
            window.scrollTo(this.scrollPosition.x, this.scrollPosition.y);
        }
        this.html.style.scrollBehavior = '';

        this.#removeScrollbarPadding();
    }

    #updateScrollbarWidth() {
        this.scrollbarWidth = getScrollbarWidth();
    }

    #addScrollbarPadding() {
        if (this.body.getBoundingClientRect().height > window.innerHeight) {
            const elements = document.querySelectorAll(this.config.scrollbarSpacerTargets);
            elements.forEach((element) => {
                element.style.paddingRight = this.scrollbarWidth + 'px';
            });
        }
    }

    #removeScrollbarPadding() {
        const elements = document.querySelectorAll(this.config.scrollbarSpacerTargets);
        elements.forEach((element) => {
            element.style.paddingRight = '';
        });
    }
}

export default new ScrollBlocker();

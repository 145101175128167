import ChatObserver from './ChatObserver';
import publishTagmanagementEvent from './publishTagmanagementEvent';

class ChatUtil {
    static showChatbot(source) {
        window.postMessage('chat-window-open', '*');

        /* eslint-disable camelcase */
        let interactionEvent = {
            interaction_type: 'chatbot',
            interaction_action: 'open',
            interaction_location: 'body',
            interaction_data: {
                source: source
            }
        };
        /* eslint-enable */
        publishTagmanagementEvent(null, 'website_interaction', interactionEvent);
    }

    //Checks if the chat is ready to chat. An extra check for the button.
    static checkChatReady() {
        return ChatObserver.getCurrentState() === 'online';
    }
}

export default ChatUtil;

/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import Utils from '../common/utils';

class PersistentStorage {
    static #LOCAL_STORAGE;
    static {
        try {
            PersistentStorage.#LOCAL_STORAGE = window.localStorage;

            // while in private browsing mode, some browsers make
            // localStorage available, but throw an error when used
            PersistentStorage.#LOCAL_STORAGE.setItem('~~~', '!');
            PersistentStorage.#LOCAL_STORAGE.removeItem('~~~');
        } catch (err) {
            PersistentStorage.#LOCAL_STORAGE = null;
        }
    }

    constructor(namespace, override) {
        this.prefix = ['__', namespace, '__'].join('');
        this.ttlKey = '__ttl__';
        this.keyMatcher = new RegExp('^' + Utils.escapeRegExChars(this.prefix));

        // for testing purpose
        this.ls = override || PersistentStorage.#LOCAL_STORAGE;

        // if local storage isn't available, everything becomes a noop
        !this.ls && this.#noop();
    }

    #prefix(key) {
        return this.prefix + key;
    }

    #ttlKey(key) {
        return this.#prefix(key) + this.ttlKey;
    }

    #noop() {
        this.get = this.set = this.remove = this.clear = this.isExpired = Utils.noop;
    }

    #safeSet(key, val) {
        try {
            this.ls.setItem(key, val);
        } catch (err) {
            // hit the localstorage limit so clean up and better luck next time
            if (err.name === 'QuotaExceededError') {
                this.clear();
                this.#noop();
            }
        }
    }

    get(key) {
        if (this.isExpired(key)) {
            this.remove(key);
        }

        return PersistentStorage.#decode(this.ls.getItem(this.#prefix(key)));
    }

    set(key, val, ttl) {
        if (Utils.isNumber(ttl)) {
            this.#safeSet(this.#ttlKey(key), PersistentStorage.#encode(PersistentStorage.#now() + ttl));
        } else {
            this.ls.removeItem(this.#ttlKey(key));
        }

        return this.#safeSet(this.#prefix(key), PersistentStorage.#encode(val));
    }

    remove(key) {
        this.ls.removeItem(this.#ttlKey(key));
        this.ls.removeItem(this.#prefix(key));

        return this;
    }

    clear() {
        let i,
            keys = PersistentStorage.#gatherMatchingKeys(this.keyMatcher);

        for (i = keys.length; i--; ) {
            this.remove(keys[i]);
        }

        return this;
    }

    isExpired(key) {
        let ttl = PersistentStorage.#decode(this.ls.getItem(this.#ttlKey(key)));

        return Utils.isNumber(ttl) && PersistentStorage.#now() > ttl ? true : false;
    }

    static #now() {
        return new Date().getTime();
    }

    static #encode(val) {
        // convert undefined to null to avoid issues with JSON.parse
        return JSON.stringify(Utils.isUndefined(val) ? null : val);
    }

    static #decode(val) {
        return JSON.parse(val);
    }

    static #gatherMatchingKeys(keyMatcher) {
        let i,
            key,
            keys = [],
            len = PersistentStorage.#LOCAL_STORAGE.length;

        for (i = 0; i < len; i++) {
            if ((key = PersistentStorage.#LOCAL_STORAGE.key(i)).match(keyMatcher)) {
                keys.push(key.replace(keyMatcher, ''));
            }
        }

        return keys;
    }
}

export default PersistentStorage;

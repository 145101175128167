<div class="overlay" aria-live="polite" role="dialog">
    <div class="overlay__scrollContainer">
        <div class="overlay__contentPositioner">
            <div class="overlay__content">
                <div class="container">
                    <div class="grid">
                       <div class="grid__unit s-4-4 m-10-12 m-push-left-1-12 xl-8-12 xl-push-left-1-12">
                            <div class="overlay__body" data-contentContainer>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#" class="closeButton overlay__close">
                <span class="icon">
                    <span class="alt">Sluiten</span>
                </span>
            </a>
        </div>
    </div>
    <div class="darkener"></div>
</div>

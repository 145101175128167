export default function setTemporaryFocus(element) {
    let originalTabIndex = element.getAttribute('tabindex') || '';
    element.setAttribute('tabIndex', '-1');
    element.focus();
    element.addEventListener('blur', reset);

    function reset() {
        element.removeEventListener('blur', reset);
        if (originalTabIndex) {
            element.setAttribute('tabindex', originalTabIndex);
        } else {
            element.setAttribute('tabIndex', '');
        }
    }
}

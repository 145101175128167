export default {
    monthsFull: [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december'
    ],
    monthsShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
    weekdaysFull: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
    weekdaysShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
    today: 'vandaag',
    clear: 'verwijderen',
    close: 'sluiten',
    labelMonthNext: 'Volgende maand',
    labelMonthPrev: 'Vorige maand',
    labelMonthSelect: 'Selecteer een maand',
    labelYearSelect: 'Selecteer een jaar'
};

import Responsive from '../../utils/Responsive';
import each from 'lodash/each';
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';
import DOMMutationObserver from '../../utils/DOMMutationObserver';
import merge from 'lodash/merge';

class ResponsiveGrid {
    readyState = 'is-ready';
    static DEFAULT_CONFIG = {
        unitSizeExpr: /(s|m|ml|l|xl)-(\d{1,2})-(\d{1,2})/,
        gridUnitSelector: ':scope > .grid__unit'
    };

    static DEBUG = {
        colors: [],
        whiteBorder: '4px solid white',
        active: document.location.hash.toLowerCase().indexOf('debugmode') > -1
    };

    // TODO: create a better way to determine whether a grid unit is positioned at the end of a row.
    // so double-height banners can work together with an allEqual = true grid.

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, ResponsiveGrid.DEFAULT_CONFIG, config);

        this.init();

        Responsive.on(Responsive.EVENTS.resized, () => {
            this.update();
        });

        this.observer = new DOMMutationObserver(this.node);
        this.observer.on(
            DOMMutationObserver.EVENTS.change,
            debounce(() => {
                this.init();
            }, 50)
        );
    }

    init() {
        if (ResponsiveGrid.DEBUG.active) {
            let l = 20;
            while (--l >= 0) {
                let random = Math.floor(360 * Math.random());
                ResponsiveGrid.DEBUG.colors.push('hsl(' + random + ',50%,50%)');
            }
        }

        this.initGridUnits();
        this.initImages();
        this.update();
    }

    initGridUnits() {
        this.gridUnits = [];

        let siteBreakpointData = Responsive.getBreakpointData();
        this.node.querySelectorAll(this.config.gridUnitSelector).forEach((unit) => {
            let unitData = {
                node: unit,
                breakpoints: [],
                noequalize: !!unit.getAttribute('data-responsivegrid-noequalize'),
                addHeightToNextRow: !!unit.getAttribute('data-responsivegrid-height-add-to-next-row')
            };

            let classes = unit.className.split(' '),
                matchedSmall = false;

            classes.forEach((className) => {
                let match = className.match(this.config.unitSizeExpr);
                if (match) {
                    let breakpointName = match[1],
                        columnSpan = parseInt(match[2]),
                        columnCount = parseInt(match[3]),
                        height = parseFloat(unitData.node.getAttribute('data-responsivegrid-height-' + breakpointName)) || 1;

                    let pushRegex = new RegExp(breakpointName + '-push-(?:left|right)-(\\d{1,2})-' + columnCount, 'g'),
                        pushMatch = pushRegex.exec(unit.className);

                    if (pushMatch) {
                        columnSpan += parseInt(pushMatch[1]);
                    }

                    unitData.breakpoints.push({
                        breakpoint: siteBreakpointData[breakpointName],
                        columnSize: columnSpan / columnCount,
                        unitHeight: height
                    });

                    if (breakpointName === 's') {
                        matchedSmall = true;
                    }
                }
            });

            if (!matchedSmall) {
                unitData.breakpoints.push({
                    breakpoint: siteBreakpointData.s,
                    columnSize: 1,
                    unitHeight: 1
                });
            }

            unitData.breakpoints = sortBy(unitData.breakpoints, (bp) => {
                return bp.breakpoint.width;
            });

            this.gridUnits.push(unitData);
        });
    }

    initImages() {
        let images = this.node.querySelectorAll('img'),
            loadHandler = debounce(() => {
                this.update();
            }, 5);
        each(images, (image) => {
            image.removeEventListener('load', loadHandler);
            image.addEventListener('load', loadHandler);
        });
    }

    update() {
        this.matchGridUnits();
        this.createRows();

        for (let i = 0, l = this.rows.length; i < l; i++) {
            this.updateRow(i);
        }
    }

    matchGridUnits() {
        let viewportWidth = Responsive.getViewportWidth();
        this.gridUnits.forEach((unit) => {
            let i = unit.breakpoints.length;
            while (--i >= 0) {
                let unitBreakpoint = unit.breakpoints[i];
                if (viewportWidth >= unitBreakpoint.breakpoint.width) {
                    unit.matchingBreakpoint = unitBreakpoint;
                    break;
                }
            }
        });
    }

    createRows() {
        let rows = [],
            currentRow,
            nextRowSize = 0,
            nextRowHeight = 0,
            doubleHeightFromPreviousRow = false,
            previousRow,
            createRow = () => {
                // carry over width from double-height banners in rowSize
                previousRow = currentRow;
                currentRow = { units: [], height: nextRowHeight, rowSize: nextRowSize };
                doubleHeightFromPreviousRow = nextRowHeight > 0;
                nextRowSize = 0;
                nextRowHeight = 0;
                rows.push(currentRow);
            };

        this.gridUnits.forEach((unit) => {
            if (ResponsiveGrid.DEBUG.active) {
                // add 4px border for consistent heights while debugging
                unit.node.style.border = ResponsiveGrid.DEBUG.whiteBorder;
            }

            if (!currentRow) {
                createRow();
            }

            let unitInfo = unit.matchingBreakpoint;

            while (currentRow.rowSize + unitInfo.columnSize > 1 && !this.config.allEqual) {
                // create a new row if the unit being processed will not fit in the current row
                createRow();
            }
            currentRow.units.push(unit);

            // A row's height is determined by the maximum height of all nodes with a rowspan of 1 within that node.
            unit.node.style.height = ''; // clear earlier height
            unit.node.classList.remove(this.readyState);

            if (unitInfo.unitHeight === 2) {
                // if the unit is double height, add its width to the next row as well
                nextRowSize += unitInfo.columnSize;

                // if the double-height unit is positioned in the middle of a row and not after another double height, reserve an extra (empty)
                // spot in the next row.
                // this means: currentRowsize is currently larger than 0, and will not be 1 after the loop is done.
                if (currentRow.rowSize > 0 && currentRow.rowSize + unitInfo.columnSize !== 1 && nextRowHeight === 0) {
                    nextRowSize += currentRow.rowSize;
                }

                if (unit.addHeightToNextRow) {
                    currentRow.height = Math.max(Math.ceil(unit.node.offsetHeight), currentRow.height);
                    nextRowHeight = currentRow.height;
                } else {
                    currentRow.height = Math.max(Math.ceil(unit.node.offsetHeight), currentRow.height);
                    nextRowHeight = 0;
                }
            } else {
                currentRow.height = Math.max(Math.ceil(unit.node.offsetHeight), currentRow.height);
                if (doubleHeightFromPreviousRow) {
                    previousRow.height = Math.max(currentRow.height, previousRow.height);
                }
            }

            currentRow.rowSize += unitInfo.columnSize;
        });

        this.rows = rows;
    }

    updateRow(rowIndex) {
        let row = this.rows[rowIndex];

        each(row.units, (unit, unitIndex) => {
            if (ResponsiveGrid.DEBUG.active) {
                unit.node.style.border = '4px solid ' + ResponsiveGrid.DEBUG.colors[rowIndex];
            }

            if (unit.noequalize) {
                return;
            }
            if (unit.matchingBreakpoint.columnSize === 1) {
                return;
            }

            if (unit.matchingBreakpoint.unitHeight === 1) {
                unit.node.style.height = row.height + 'px';
            } else if (unit.matchingBreakpoint.unitHeight === 2) {
                // if the unit is double height, lookup the height of the next row.
                // if there is no next row, just take 2x the height of the current row.
                // this last situation should only happen while authoring a layout in the CMS.
                let nextRow = this.rows[rowIndex + 1] || row,
                    unitHeight = row.height + nextRow.height;

                // fix the float position. Items not in the left column (with rowIndex === 0)
                // should float to the right in order to maintain a correct grid.
                let unitFloat = 'left';
                if (unitIndex === row.units.length - 1 && row.rowSize === 1) {
                    unitFloat = 'right';
                }

                unit.node.style.height = unitHeight + 'px';
                unit.node.style.float = unitFloat;
            }
            unit.node.classList.add(this.readyState);
        });
    }
}
export default ResponsiveGrid;

class EventNamespaceHandlerUtils {
    constructor() {
        this.functionMap = {};
    }

    addEventListener(el, event, func) {
        this.functionMap[event] = func;
        const eventName = event.includes('.') ? event.split('.')[0] : event;
        el.addEventListener(eventName, this.functionMap[event]);
    }

    removeEventListener(el, event) {
        const eventName = event.includes('.') ? event.split('.')[0] : event;
        el.removeEventListener(eventName, this.functionMap[eventName]);
        delete this.functionMap[event];
    }
}

export default new EventNamespaceHandlerUtils();

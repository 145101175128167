class Utils {
    /**
     * Create a group of nodes. Expects:
     * `
     {
            min:    {Integer},
            max:    {Integer},
            i:      {Integer},
            node:   {String},
            item:   {Function}
        }
     * `
     */
    static group(groupObject) {
        let // Scope for the looped object
            loopObjectScope,
            // Create the nodes list
            nodesList = '',
            // The counter starts from the `min`
            counter = Utils.trigger(groupObject.min, groupObject);

        // Loop from the `min` to `max`, incrementing by `i`
        for (; counter <= Utils.trigger(groupObject.max, groupObject, [counter]); counter += groupObject.i) {
            // Trigger the `item` function within scope of the object
            loopObjectScope = Utils.trigger(groupObject.item, groupObject, [counter]);

            // Splice the subgroup and create nodes out of the sub nodes
            nodesList += Utils.node(
                groupObject.node,
                loopObjectScope[0], // the node
                loopObjectScope[1], // the classes
                loopObjectScope[2] // the attributes
            );
        }

        // Return the list of nodes
        return nodesList;
    }

    /**
     * Create a dom node string
     */
    static node(wrapper, item, klass, attribute) {
        // If the item is false-y, just return an empty string
        if (!item) return '';

        // If the item is an array, do a join
        item = Array.isArray(item) ? item.join('') : item;

        // Check for the class
        klass = klass ? ' class="' + klass + '"' : '';

        // Check for any attributes
        attribute = attribute ? ' ' + attribute : '';

        // Return the wrapped item
        return '<' + wrapper + klass + attribute + '>' + item + '</' + wrapper + '>';
    }

    /**
     * Lead numbers below 10 with a zero.
     */
    static lead(number) {
        return (number < 10 ? '0' : '') + number;
    }

    /**
     * Trigger a function otherwise return the value.
     */
    static trigger(callback, scope, args) {
        return typeof callback == 'function' ? callback.apply(scope, args || []) : callback;
    }

    /**
     * If the second character is a digit, length is 2 otherwise 1.
     */
    static digits(string) {
        return /\d/.test(string[1]) ? 2 : 1;
    }

    /**
     * Tell if something is a date object.
     */
    static isDate(value) {
        return {}.toString.call(value).indexOf('Date') > -1 && this.isInteger(value.getDate());
    }

    /**
     * Tell if something is an integer.
     */
    static isInteger(value) {
        return {}.toString.call(value).indexOf('Number') > -1 && value % 1 === 0;
    }

    /**
     * Create ARIA attribute strings.
     */
    static ariaAttr(attribute, data) {
        if (!Utils.isPlainObject(attribute)) {
            attribute = { attribute: data };
        }
        data = '';
        for (let key in attribute) {
            let attr = (key === 'role' ? '' : 'aria-') + key,
                attrVal = attribute[key];
            data += attrVal == null ? '' : attr + '="' + attribute[key] + '"';
        }
        return data;
    }

    static isPlainObject(value) {
        return value != null && Object.prototype.toString.call(value) === '[object Object]';
    }
}

export default Utils;

import Responsive from '../../utils/Responsive';
import HeaderUtil from '../../utils/HeaderUtil';
import debounce from 'lodash/debounce';
import publishTagmanagementEvent from '../../utils/publishTagmanagementEvent';
import ScrollBlocker from '../ScrollBlocker';
import gsap from 'gsap';
import merge from 'lodash/merge';

class Header {
    static DEFAULT_CONFIG = {
        selectors: {
            menuButtonAlt: '.ns-menu__menu-button-alt',
            mainMenu: '.ns-menu--mainmenu',
            secondaryMenu: '.ns-menu--secondary',
            submenuIsOpen: '.ns-submenu.is-open',
            submenuContainer: '.ns-submenu__column',
            submenuContent: '.ns-submenu__content',
            submenuHeader: '.ns-submenu__header',
            submenuTitle: '.ns-submenu__title',
            clickOnlyAttr: 'data-click-only',
            menuButtonSearch: '.ns-menu__menu-button--search',
            menuMobileButtonSearch: '.ns-menu__menu-button--mobileSearch',
            menuButtonsMijnNS: '.ns-menu__list-item--mijnns .ns-menu__menu-button',
            menuButtonsMijnNSMobile: '.ns-header__mobile .ns-header__mijnns-button',
            mijnNSMobileCloseText: '.ns-menu__mijnns-close-text',
            menuButtonNSI: '.ns-menu__list-item--nsinternational .ns-menu__menu-button',
            mainMenuButtons: '.ns-menu:first-of-type .ns-menu__menu-button, .ns-menu__menu-button--more-ns-mobile',
            menuSearchWrapper: '.ns-search',
            menuSearchField: '.ns-search__searchfield',
            searchFormWrapper: '.searchForm',
            toggleNavButton: '.ns-header__toggle-button',
            headerContainerWrapper: '.ns-header__containerWrapper',
            headerContainer: '.ns-header__container',
            headerContent: '.ns-header__content',
            headerMobile: '.ns-header__mobile',
            previousButton: '.ns-header__previous-button',
            submenuCloseButton: '.ns-submenu__header .ns-header__toggle-button',
            searchCloseButton: '.ns-search__close-button'
        },
        classNames: {
            active: 'is-active',
            isOpen: 'is-open',
            isToggled: 'is-toggled',
            isToggledMijnns: 'is-toggled-mijnns',
            isToggledSearch: 'is-toggled-search'
        },
        openMenuLabel: 'Open submenu',
        closeMenuLabel: 'Sluit submenu'
    };

    constructor(node, config, componentManager) {
        this.node = node;
        this.config = merge({}, Header.DEFAULT_CONFIG, config);
        this.componentManager = componentManager;
        this.eventState = 'hover';
        this.MobileMenuOpen = false;
        this.MobileMijnnsMenuOpen = false;
        this.MobileSubmenuOpen = false;
        this.MobileSearchOpen = false;
        this.animation = {
            duration: 0.25,
            durationDropdown: 0.2,
            durationSearch: 0.2,
            ease: 'power4.inOut',
            easeSearch: 'power3.inOut',
            startScaleY: 0,
            startScaleYDropdown: 0.5,
            targetDisplay: 'grid',
            targetDisplayDropdown: 'block'
        };

        this.headerMobile = this.node.querySelector(this.config.selectors.headerMobile);
        this.headerContainerWrapper = this.node.querySelector(this.config.selectors.headerContainerWrapper);
        this.headerContainer = this.node.querySelector(this.config.selectors.headerContainer);
        this.headerContent = this.node.querySelector(this.config.selectors.headerContent);
        this.headerFade = this.node.querySelector('.ns-header__fade');
        this.menuButtonSearch = this.node.querySelector(this.config.selectors.menuButtonSearch);
        this.menuMobileButtonSearch = this.node.querySelector(this.config.selectors.menuMobileButtonSearch);
        this.mainMenu = this.node.querySelector(this.config.selectors.mainMenu);
        this.secondaryMenu = this.node.querySelector(this.config.selectors.secondaryMenu);
        this.toggleNavButton = this.node.querySelector(this.config.selectors.toggleNavButton);
        this.menuButtonsMijnNSMobile = [...this.node.querySelectorAll(this.config.selectors.menuButtonsMijnNSMobile)];
        this.submenuContent = this.node.querySelectorAll(this.config.selectors.submenuContent);
        this.submenuHeader = this.node.querySelector(this.config.selectors.submenuHeader);
        this.searchCloseButton = this.node.querySelector(this.config.selectors.searchCloseButton);

        this.bindEventHandlers();
    }

    bindEventHandlers() {
        if (!this.tlNav) {
            this.tlNav = gsap
                .timeline({
                    paused: true,
                    defaults: {
                        duration: 0.4,
                        ease: this.animation.ease
                    }
                })
                .to(this.headerContainerWrapper, {
                    height: 'calc(100vh - 60px)'
                });
        }

        if (this.toggleNavButton) {
            this.toggleNavButton.addEventListener(
                'click',
                debounce(
                    () => {
                        this.toggleMobileMenu();
                    },
                    200,
                    { leading: true }
                )
            );
        }

        this.menuButtonsMijnNSMobile.forEach((menuButtonMijnns) => {
            menuButtonMijnns.addEventListener(
                'click',
                debounce(
                    () => {
                        this.toggleMijnNSMobile(menuButtonMijnns);
                    },
                    200,
                    { leading: true }
                )
            );
        });

        this.previousButton = [...this.node.querySelectorAll(this.config.selectors.previousButton)];
        this.previousButton.forEach((previousButton) => {
            previousButton.addEventListener(
                'click',
                debounce(
                    () => {
                        this.closeMobileSubmenu(previousButton);
                    },
                    200,
                    { leading: true }
                )
            );
        });

        this.submenuCloseButton = [...this.node.querySelectorAll(this.config.selectors.submenuCloseButton)];
        this.submenuCloseButton.forEach((submenuCloseButton) => {
            submenuCloseButton.addEventListener(
                'click',
                debounce(
                    () => {
                        this.closeMobileMenu(submenuCloseButton);
                    },
                    200,
                    { leading: true }
                )
            );
        });

        if (this.headerContent && this.submenuContent) {
            this.contentWrappers = [this.headerContent, ...this.submenuContent];
            this.contentWrappers.forEach((item) => {
                item.addEventListener('scroll', () =>
                    requestAnimationFrame(() => {
                        // Add shadow to the correct navbar on scroll
                        const isMainMenu = item.classList.contains(this.config.selectors.headerContent.substring(1));
                        const isSubmenu = item.classList.contains(this.config.selectors.submenuContent.substring(1));
                        const isMijnNS = isSubmenu && item.previousElementSibling === null;
                        const theHeader = isMainMenu || isMijnNS ? this.headerMobile : item.previousElementSibling;
                        if (typeof theHeader !== 'undefined') {
                            item.scrollTop > 12
                                ? theHeader.classList.add(this.config.classNames.active)
                                : theHeader.classList.remove(this.config.classNames.active);
                        }

                        // White gradient removal on scroll
                        gsap.to(this.headerFade, {
                            duration: 0.3,
                            opacity: 0
                        });
                    })
                );
            });
        }

        Responsive.on(Responsive.EVENTS.crossedBreakpoint, () => {
            this.resetMenu();
        });

        if (this.menuButtonSearch) {
            this.menuButtonSearch.addEventListener(
                'click',
                debounce(
                    () => {
                        this.closeMainMenuIfOpen(false);
                        this.closeMijnNSMenuIfOpen(false);
                        this.closeNSIMenuIfOpen(false);
                        this.openSearchfield();
                    },
                    200,
                    { leading: true }
                )
            );
        }

        if (this.menuMobileButtonSearch) {
            this.menuMobileButtonSearch.addEventListener(
                'click',
                debounce(
                    () => {
                        this.MobileSearchOpen = true;
                        this.openSearchfield();
                    },
                    200,
                    { leading: true }
                )
            );
        }

        if (this.searchCloseButton) {
            this.searchCloseButton.addEventListener(
                'click',
                debounce(
                    () => {
                        this.MobileSearchOpen = false;
                        this.closeSearchfield();
                    },
                    200,
                    { leading: true }
                )
            );
        }

        this.menuButtonsMijnNS = [...this.node.querySelectorAll(this.config.selectors.menuButtonsMijnNS)];
        this.menuButtonsMijnNS.forEach((menuButton) => {
            menuButton.addEventListener(
                'click',
                debounce(
                    (event) => {
                        const menuListItemIsActive = menuButton.parentNode.classList.contains(this.config.classNames.active);

                        //toggle submenu
                        if (!menuListItemIsActive) {
                            this.closeMainMenuIfOpen(false);
                            this.closeNSIMenuIfOpen(false);
                            this.showSubmenu(menuButton, true, true, event);
                            this.eventState = 'click';
                        } else {
                            this.hideSubmenu(menuButton, true, event);
                            this.eventState = 'hover';
                        }
                    },
                    200,
                    { leading: true }
                )
            );
        });

        this.menuButtonNSI = this.node.querySelector(this.config.selectors.menuButtonNSI);
        if (this.menuButtonNSI) {
            this.menuButtonNSI.addEventListener(
                'click',
                debounce(
                    (event) => {
                        const menuListItemIsActive = this.menuButtonNSI.parentNode.classList.contains(this.config.classNames.active);

                        //toggle submenu
                        if (!menuListItemIsActive) {
                            this.closeMainMenuIfOpen(false);
                            this.closeMijnNSMenuIfOpen(false);
                            this.showSubmenu(this.menuButtonNSI, true, true, event);
                            this.eventState = 'click';
                        } else {
                            this.hideSubmenu(this.menuButtonNSI, true, event);
                            this.eventState = 'hover';
                        }
                    },
                    200,
                    { leading: true }
                )
            );
        }

        this.mainMenuButtons = [...this.node.querySelectorAll(this.config.selectors.mainMenuButtons)];
        this.mainMenuButtons.forEach((menuButton) => {
            let hoverIntent;

            menuButton.addEventListener(
                'click',
                debounce(
                    (event) => {
                        const menuListItemIsActive = menuButton.parentNode.classList.contains(this.config.classNames.active);
                        const menuIsActive = !!menuButton.parentElement.parentElement.parentElement.querySelector(
                            '.ns-menu__list-item.' + this.config.classNames.active
                        );

                        //toggle submenu
                        if (!menuListItemIsActive) {
                            if (!menuIsActive) {
                                this.closeMijnNSMenuIfOpen(false);
                                this.closeNSIMenuIfOpen(false);
                            }
                            // reset current active states
                            this.mainMenuButtons.forEach((mainMenuButton) => {
                                this.resetActiveState(mainMenuButton);
                            });
                            this.showSubmenu(menuButton, !menuIsActive, false, event);
                            this.eventState = 'click';
                        } else {
                            if (this.eventState !== 'hover' && Responsive.testBreakpoint('ml')) {
                                this.hideSubmenu(menuButton, false, event);
                                this.eventState = 'hover';
                            } else {
                                /* eslint-disable camelcase */
                                publishTagmanagementEvent(null, 'website_interaction', {
                                    interaction_type: event.type,
                                    interaction_action: 'open',
                                    interaction_location: 'header_menu',
                                    interaction_name: menuButton.getAttribute('data-menuname')
                                });
                                /* eslint-enable */
                                this.eventState = 'click';
                            }
                        }
                    },
                    200,
                    { leading: true }
                )
            );

            menuButton.addEventListener('mouseenter', (e) => {
                if (this.eventState !== 'click' && Responsive.testBreakpoint('ml')) {
                    hoverIntent = setTimeout(() => {
                        const menuIsActive = !!menuButton.parentElement.parentElement.parentElement.querySelector(
                            '.ns-menu__list-item.' + this.config.classNames.active
                        );

                        // reset current active states
                        this.mainMenuButtons.forEach((mainMenuButton) => {
                            this.resetActiveState(mainMenuButton);
                        });
                        this.showSubmenu(menuButton, !menuIsActive, false, e);
                    }, 150);
                }
            });

            menuButton.addEventListener('mouseleave', () => {
                if (this.eventState !== 'click' && Responsive.testBreakpoint('ml')) {
                    clearTimeout(hoverIntent);
                }
            });
        });

        this.node.addEventListener('mouseleave', () => {
            if (this.eventState !== 'click' && Responsive.testBreakpoint('ml')) {
                this.closeMainMenuIfOpen(false);
            }
        });

        if (this.mainMenu) {
            this.mainMenu.addEventListener('mouseleave', (e) => {
                if (this.eventState !== 'click' && Responsive.testBreakpoint('ml')) {
                    let mouseX = e.pageX;
                    let mouseY = e.pageY;

                    if (
                        mouseY < this.mainMenu.offsetTop ||
                        mouseX <= Math.floor(this.mainMenu.getBoundingClientRect().left) ||
                        mouseX >= Math.floor(this.mainMenu.getBoundingClientRect().right)
                    ) {
                        this.closeMainMenuIfOpen(false);
                    }
                }
            });
        }

        this.searchFormWrapper = this.node.querySelector(this.config.selectors.searchFormWrapper);
        if (this.searchFormWrapper) {
            this.searchFormWrapper.addEventListener('focusout', () => {
                if (document.hasFocus()) {
                    this.closeSearchMenuIfOpen(false);
                }
            });
        }

        document.addEventListener('click', (e) => {
            if (!this.node.contains(e.target) && !!this.node.querySelector('.' + this.config.classNames.active)) {
                this.eventState = 'hover';
                this.closeMainMenuIfOpen(false);
                this.closeMijnNSMenuIfOpen(false);
                this.closeNSIMenuIfOpen(false);
                this.closeSearchMenuIfOpen(false);
            }
        });

        document.addEventListener('keyup', (e) => {
            if (e.key === 'Escape' || e.code === 'Escape') {
                if (Responsive.testBreakpoint('ml')) {
                    this.eventState = 'hover';
                    this.closeMainMenuIfOpen(true);
                    this.closeMijnNSMenuIfOpen(true);
                    this.closeNSIMenuIfOpen(true);
                    this.closeSearchMenuIfOpen(true);
                } else {
                    if (this.MobileMenuOpen && !this.MobileSubmenuOpen && !this.MobileSearchOpen) {
                        this.toggleNavButton.click();
                    } else if (this.MobileMijnnsMenuOpen) {
                        this.node.querySelector(this.config.selectors.menuButtonsMijnNSMobile + ':not(.is-hidden)').click();
                    } else if (this.MobileMenuOpen && this.MobileSubmenuOpen) {
                        const submenu = this.node.querySelector(this.config.selectors.submenuIsOpen);
                        submenu.querySelector(this.config.selectors.previousButton).click();
                    } else if (this.MobileMenuOpen && this.MobileSearchOpen) {
                        this.searchCloseButton.click();
                    }
                }
            }
        });
    }

    closeSearchMenuIfOpen(focusAfterClose) {
        const menuListItemIsActive = this.menuButtonSearch.parentNode.classList.contains(this.config.classNames.active);
        if (menuListItemIsActive) {
            this.closeSearchfield(focusAfterClose);
        }
    }

    closeMainMenuIfOpen(focusAfterClose) {
        this.mainMenuButtons.forEach((menuButton) => {
            const menuListItemIsActive = menuButton.parentNode.classList.contains(this.config.classNames.active);
            if (menuListItemIsActive) {
                this.closeSubmenu(menuButton, false);
                if (focusAfterClose) {
                    menuButton.focus();
                }
            }
        });
    }

    closeMijnNSMenuIfOpen(focusAfterClose) {
        this.menuButtonsMijnNS.forEach((menuButton) => {
            const menuListItemIsActive = menuButton.parentNode.classList.contains(this.config.classNames.active);
            if (menuListItemIsActive) {
                this.closeSubmenu(menuButton, true);
                if (focusAfterClose) {
                    menuButton.focus();
                }
            }
        });
    }

    closeNSIMenuIfOpen(focusAfterClose) {
        const menuListItemIsActive = this.menuButtonNSI.parentNode.classList.contains(this.config.classNames.active);
        if (menuListItemIsActive) {
            this.closeSubmenu(this.menuButtonNSI, true);
            if (focusAfterClose) {
                this.menuButtonNSI.focus();
            }
        }
    }

    showSubmenu(menuButton, animate, dropDownStyle, event) {
        const submenu = menuButton.nextElementSibling;
        const toggleNavButton = submenu.querySelector(this.config.selectors.toggleNavButton);

        // set active state
        this.setActiveState(menuButton);
        if (toggleNavButton) {
            toggleNavButton.classList.add(this.config.classNames.active);
        }

        // check if menu is opened to animate it the first time
        // or if it is a clickOnly element then always animate
        if (submenu) {
            HeaderUtil.matchHeight(submenu.querySelectorAll(this.config.selectors.submenuTitle));

            if (animate) {
                this.openSubmenu(menuButton, dropDownStyle);
            } else {
                submenu.style.opacity = '1';
                submenu.style.scaleY = 1;
            }
        }

        /* eslint-disable camelcase */
        publishTagmanagementEvent(null, 'website_interaction', {
            interaction_type: event.type,
            interaction_action: 'open',
            interaction_location: 'header_menu',
            interaction_name: menuButton.getAttribute('data-menuname')
        });
        /* eslint-enable */
    }

    hideSubmenu(menuButton, dropDownStyle, event) {
        const menuListItemIsActive = menuButton.parentNode.classList.contains(this.config.classNames.active);

        if (menuListItemIsActive) {
            if (event.type === 'keyup') {
                menuButton.focus();
            }

            this.closeSubmenu(menuButton, dropDownStyle);

            /* eslint-disable camelcase */
            publishTagmanagementEvent(null, 'website_interaction', {
                interaction_type: event.type,
                interaction_action: 'close',
                interaction_location: 'header_menu',
                interaction_name: menuButton.getAttribute('data-menuname')
            });
            /* eslint-enable */
        }
    }

    setActiveState(menuButton) {
        const menuButtonAlt = menuButton.querySelector(this.config.selectors.menuButtonAlt);
        const submenu = menuButton.nextElementSibling;

        HeaderUtil.setExpandedState(menuButton, true);
        menuButton.parentNode.classList.add(this.config.classNames.active);

        if (menuButtonAlt) {
            menuButtonAlt.innerHTML = this.config.closeMenuLabel;
        }

        if (submenu) {
            submenu.classList.add(this.config.classNames.isOpen);
            submenu.setAttribute('tabindex', '-1');

            // Only on mobile if there is actually a submenu
            if (!Responsive.testBreakpoint('ml') && !this.MobileMijnnsMenuOpen) {
                this.MobileSubmenuOpen = true;
                this.headerContainerWrapper.classList.add(this.config.classNames.isOpen);
            }
        }
    }

    resetActiveState(menuButton) {
        const menuButtonAlt = menuButton.querySelector(this.config.selectors.menuButtonAlt);
        const submenu = menuButton.nextElementSibling;

        HeaderUtil.setExpandedState(menuButton, false);
        menuButton.parentNode.classList.remove(this.config.classNames.active);

        if (menuButtonAlt) {
            menuButtonAlt.innerHTML = this.config.openMenuLabel;
        }

        if (submenu) {
            submenu.querySelector(this.config.selectors.submenuContent).scrollTop = 0;
            submenu.classList.remove(this.config.classNames.isOpen);
            submenu.removeAttribute('tabindex');
            submenu.removeAttribute('style');

            const submenuContainer = [...submenu.querySelectorAll(this.config.selectors.submenuContainer)];
            submenuContainer.forEach((element) => {
                element.removeAttribute('style');
            });
        }
    }

    openSubmenu(menuButton, dropDownStyle) {
        const submenu = menuButton.nextElementSibling;

        if (Responsive.testBreakpoint('ml')) {
            const submenuContent = [...submenu.querySelectorAll(this.config.selectors.submenuContainer)];
            const animationDuration = dropDownStyle ? this.animation.durationDropdown : this.animation.duration;
            const startScaleY = dropDownStyle ? this.animation.startScaleYDropdown : this.animation.startScaleY;
            const display = dropDownStyle ? this.animation.targetDisplayDropdown : this.animation.targetDisplay;
            const tl = gsap.timeline({
                defaults: {
                    duration: animationDuration,
                    ease: this.animation.ease
                }
            });

            if (dropDownStyle) {
                gsap.set(submenu, {
                    // fix NSI desktop open position when swith mobile to desktop
                    clearProps: 'all'
                });
                tl.paused(true)
                    .set(submenu, {
                        opacity: 0,
                        scaleY: startScaleY,
                        display: display
                    })
                    .to(submenu, {
                        opacity: 1,
                        scaleY: 1,
                        ease: this.animation.ease,
                        clearProps: 'all'
                    })
                    .to(
                        submenuContent,
                        {
                            opacity: 1,
                            ease: this.animation.ease
                        },
                        '-=' + (animationDuration - 0.1)
                    );
            } else {
                tl.paused(true)
                    .set(submenu, {
                        scaleY: startScaleY,
                        display: display,
                        x: 0 //Bizarre: This is needed because else the 100vw of the previousButton timeline is used
                    })
                    .to(submenu, {
                        scaleY: 1,
                        ease: this.animation.ease,
                        clearProps: 'all'
                    })
                    .fromTo(
                        submenuContent,
                        {
                            opacity: 0
                        },
                        {
                            opacity: 1,
                            ease: this.animation.ease,
                            clearProps: 'all'
                        },
                        '-=' + (animationDuration - 0.1)
                    );
            }
            tl.play();
        } else {
            const staggeredItems = [...submenu.querySelectorAll('.js-sub-stag')].slice(0, 14);
            const toggleNavButton = submenu.querySelector(this.config.selectors.toggleNavButton);
            const tlSubmenu = gsap
                .timeline({
                    paused: true,
                    defaults: {
                        duration: 0.5,
                        ease: this.animation.ease
                    },
                    onComplete: () => {
                        toggleNavButton.setAttribute('aria-expanded', 'true');
                        toggleNavButton.setAttribute('aria-label', this.config.closeMenuLabel);
                    }
                })
                .set(submenu, {
                    x: '100vw',
                    display: 'block'
                })
                .to(submenu, {
                    x: 0
                })
                .to(
                    this.headerFade,
                    {
                        opacity: 1,
                        display: 'block'
                    },
                    '<0'
                )
                .fromTo(
                    staggeredItems,
                    {
                        opacity: 0,
                        x: 24
                    },
                    {
                        duration: 0.75,
                        stagger: 0.04,
                        opacity: 1,
                        x: 0
                    },
                    '<0'
                );
            tlSubmenu.play();
        }
    }

    closeSubmenu(menuButton, dropDownStyle) {
        const submenu = menuButton.nextElementSibling;
        const submenuContent = [...submenu.querySelectorAll(this.config.selectors.submenuContainer)];
        const animationDuration = dropDownStyle ? this.animation.durationDropdown : this.animation.duration / 1.5;
        const startScaleY = dropDownStyle ? this.animation.startScaleYDropdown : this.animation.startScaleY;
        if (dropDownStyle) {
            const tl = gsap.timeline({
                defaults: {
                    duration: animationDuration,
                    ease: this.animation.ease
                },
                onComplete: () => {
                    this.resetActiveState(menuButton);
                }
            });

            tl.paused(true)
                .to(submenuContent, {
                    opacity: 0
                })
                .to(
                    submenu,
                    {
                        opacity: 0,
                        scaleY: startScaleY,
                        clearProps: 'all'
                    },
                    '-=' + (animationDuration - 0.1)
                );
            tl.play();
        } else {
            const tl = gsap.timeline({
                defaults: {
                    duration: animationDuration,
                    ease: this.animation.ease
                },
                onComplete: () => {
                    this.resetActiveState(menuButton);
                }
            });

            tl.paused(true)
                .to(submenuContent, {
                    opacity: 0
                })
                .to(
                    submenu,
                    {
                        scaleY: startScaleY,
                        clearProps: 'all'
                    },
                    '-=' + (animationDuration - 0.1)
                );
            tl.play();
        }
    }

    openSearchfield() {
        if (Responsive.testBreakpoint('ml')) {
            const searchButton = this.node.querySelector(this.config.selectors.menuButtonSearch);
            const tl = gsap.timeline({
                onComplete: () => {
                    //focus input
                    this.node.querySelector(this.config.selectors.menuSearchField).focus();
                }
            });

            tl.set(this.config.selectors.menuSearchWrapper, {
                display: 'block',
                opacity: 1,
                width: 'auto'
            }).to(this.config.selectors.menuSearchWrapper, {
                duration: this.animation.durationSearch,
                width: '100%'
            });

            searchButton.parentNode.classList.add(this.config.classNames.active);
            searchButton.style.display = 'none';
        } else {
            const searchItem = this.secondaryMenu.querySelector('.ns-menu__list-item--search');
            const menuSearchWrapper = this.node.querySelector(this.config.selectors.menuSearchWrapper); // .ns-search

            if (!this.tlSearch) {
                this.tlSearch = gsap
                    .timeline({
                        paused: true,
                        defaults: {
                            duration: 0.35,
                            ease: 'power4.inOut'
                        },
                        onComplete: () => {
                            this.node.querySelector(this.config.selectors.menuSearchField).focus();
                        },
                        onReverseComplete: () => {
                            this.node.classList.remove(this.config.classNames.isToggledSearch);
                        }
                    })
                    .set(searchItem, {
                        display: 'block'
                    })
                    .set(menuSearchWrapper, {
                        display: 'block'
                    })
                    .fromTo(
                        menuSearchWrapper,
                        {
                            y: '100vh'
                        },
                        {
                            y: 0
                        }
                    );
            }
            this.node.classList.add(this.config.classNames.isToggledSearch);
            this.tlSearch.restart();
        }

        /* eslint-disable camelcase */
        publishTagmanagementEvent(null, 'website_interaction', {
            interaction_type: 'click',
            interaction_action: 'open',
            interaction_location: 'header_menu',
            interaction_name: 'zoeken'
        });
        /* eslint-enable */
    }

    closeSearchfield(focusAfterClose) {
        if (Responsive.testBreakpoint('ml')) {
            const searchButton = this.node.querySelector(this.config.selectors.menuButtonSearch);
            const autosuggestNode = this.node.querySelector(this.config.selectors.searchFormWrapper);
            const autosuggestController = this.componentManager.getInstanceDescriptorForNode(autosuggestNode).instance;

            // Clear search value and autosuggest results
            autosuggestController.clear();
            this.node.querySelector(this.config.selectors.menuSearchField).value = '';

            const tl = gsap.timeline({
                onComplete: () => {
                    if (focusAfterClose) {
                        this.menuButtonSearch.focus();
                    }
                }
            });
            tl.to(this.config.selectors.menuSearchWrapper, {
                duration: this.animation.durationSearch,
                opacity: 0,
                ease: this.animation.easeSearch
            }).to(
                this.config.selectors.menuSearchWrapper,
                {
                    display: 'none',
                    duration: this.animation.durationSearch,
                    width: '0%',
                    ease: this.animation.easeSearch
                },
                '-=' + (this.animation.durationSearch - 0.1)
            );

            searchButton.parentNode.classList.remove(this.config.classNames.active);
            searchButton.style.display = 'inline-block';
        } else {
            this.tlSearch.reverse();
        }

        /* eslint-disable camelcase */
        publishTagmanagementEvent(null, 'website_interaction', {
            interaction_type: 'click',
            interaction_action: 'close',
            interaction_location: 'header_menu',
            interaction_name: 'zoeken'
        });
        /* eslint-enable */
    }

    toggleMobileMenu() {
        const staggeredItems = [...this.node.querySelectorAll('.js-nav-stag')];

        if (!this.tlMain) {
            this.tlMain = gsap
                .timeline({
                    paused: true,
                    defaults: {
                        duration: 0.3,
                        ease: this.animation.ease
                    },
                    onComplete: () => {
                        HeaderUtil.setExpandedState(this.toggleNavButton, true);
                    },
                    onReverseComplete: () => {
                        this.node.classList.remove(this.config.classNames.isToggled);
                        HeaderUtil.setExpandedState(this.toggleNavButton, false);
                    }
                })
                .call(() => {
                    if (this.tlMain.timeScale() > 0) {
                        this.headerContent.scrollTop = 0;
                    }
                })
                .fromTo(
                    staggeredItems,
                    {
                        opacity: 0,
                        y: -8
                    },
                    {
                        stagger: 0.025,
                        opacity: 1,
                        y: 0,
                        clearProps: 'all'
                    },
                    '<0.1'
                )
                .to(
                    this.headerFade,
                    {
                        opacity: 1,
                        display: 'block'
                    },
                    '<0.2'
                );
        }

        if (!this.MobileMenuOpen && !this.MobileMijnnsMenuOpen) {
            this.MobileMenuOpen = true;
            this.node.classList.add(this.config.classNames.isToggled);
            this.toggleNavButton.classList.add(this.config.classNames.active);
            ScrollBlocker.disableScroll();
            this.tlNav.play().timeScale(1).restart();
            this.tlMain.play().timeScale(1).restart();

            /* eslint-disable camelcase */
            publishTagmanagementEvent(null, 'website_interaction', {
                interaction_type: 'click',
                interaction_action: 'open',
                interaction_location: 'new_header_menu',
                interaction_name: 'mobileMenuToggleButton'
            });
            /* eslint-enable */
        } else if (this.MobileMijnnsMenuOpen) {
            this.MobileMenuOpen = true;
            this.MobileMijnnsMenuOpen = false;
            this.node.classList.add(this.config.classNames.isToggled);
            this.toggleNavButton.classList.add(this.config.classNames.active);
            this.tlMain.play().timeScale(1).restart();
            this.tlMijnNS.timeScale(-3);
            this.tlMijnNSIcon.timeScale(-1);
            this.node.classList.remove(this.config.classNames.isToggledMijnns);
            this.menuButtonsMijnNSMobile.forEach((menuButtonMijnns) => {
                HeaderUtil.setExpandedState(menuButtonMijnns, false);
            });
        } else {
            this.MobileMenuOpen = false;
            this.toggleNavButton.classList.remove(this.config.classNames.active);
            ScrollBlocker.enableScroll();
            this.tlMain.timeScale(-2);
            setTimeout(() => this.tlNav.timeScale(-3), 150);

            /* eslint-disable camelcase */
            publishTagmanagementEvent(null, 'website_interaction', {
                interaction_type: 'click',
                interaction_action: 'close',
                interaction_location: 'new_header_menu',
                interaction_name: 'mobileMenuToggleButton'
            });
            /* eslint-enable */
        }
    }

    toggleMijnNSMobile(menuButtonMijnns) {
        const mijnnsSubmenuButton = this.node.querySelector(
            '.ns-menu__list-item--dropdown:not(.is-hidden)' + this.config.selectors.menuButtonsMijnNS
        );
        const mijnnsSubmenu = [...this.node.querySelectorAll('.ns-menu__list-item--dropdown .ns-submenu__column')];
        const mijnNSMobileCloseText = [...this.node.querySelectorAll(this.config.selectors.mijnNSMobileCloseText)];
        const menuButtonMijnnsLoggedOut = this.node.querySelector(
            this.config.selectors.menuButtonsMijnNSMobile + '[data-header-toggle="mijnns-loggedOut"]'
        );
        const menuButtonMijnnsLoggedIn = this.node.querySelector(
            this.config.selectors.menuButtonsMijnNSMobile + '[data-header-toggle="mijnns-loggedIn"]'
        );
        const staggeredItems = [...this.node.querySelectorAll('.js-mijnns-stag')];

        if (!this.tlMijnNS) {
            this.tlMijnNS = gsap
                .timeline({
                    paused: true,
                    defaults: {
                        duration: 0.4,
                        ease: this.animation.ease
                    },
                    onComplete: () => {
                        HeaderUtil.setExpandedState(menuButtonMijnns, true);
                    },
                    onReverseComplete: () => {
                        this.node.classList.remove(this.config.classNames.isToggledMijnns);
                        this.resetActiveState(mijnnsSubmenuButton);
                        HeaderUtil.setExpandedState(menuButtonMijnns, false);
                    }
                })
                .call(() => {
                    if (this.tlMijnNS.timeScale() > 0) {
                        this.headerContent.scrollTop = 0;
                    }
                })
                .set(mijnnsSubmenu, {
                    display: 'block',
                    opacity: 0,
                    delay: 0.04
                })
                .to(
                    mijnnsSubmenu,
                    {
                        opacity: 1
                    },
                    '<0'
                )
                .fromTo(
                    staggeredItems,
                    {
                        opacity: 0,
                        y: -8
                    },
                    {
                        stagger: 0.04,
                        opacity: 1,
                        y: 0
                    },
                    '<0'
                );
        }

        if (!this.tlMijnNSIcon) {
            this.tlMijnNSIcon = gsap
                .timeline({
                    paused: true,
                    defaults: {
                        duration: 0.4,
                        ease: this.animation.ease
                    }
                })
                .to(
                    menuButtonMijnnsLoggedIn,
                    {
                        width: 115
                    },
                    '<0'
                )
                .to(
                    menuButtonMijnnsLoggedOut,
                    {
                        width: 100
                    },
                    '<0'
                )
                .set(
                    mijnNSMobileCloseText,
                    {
                        display: 'inline-block'
                    },
                    '<0'
                )
                .to(
                    mijnNSMobileCloseText,
                    {
                        opacity: 1
                    },
                    '<0.1'
                );
        }

        if (!this.MobileMenuOpen && !this.MobileMijnnsMenuOpen) {
            this.MobileMijnnsMenuOpen = true;
            this.node.classList.add(this.config.classNames.isToggledMijnns);
            this.setActiveState(mijnnsSubmenuButton);
            ScrollBlocker.disableScroll();
            this.tlNav.play().timeScale(1).restart();
            this.tlMijnNS.play().timeScale(1).restart();
            this.tlMijnNSIcon.play().timeScale(1).restart();

            /* eslint-disable camelcase */
            publishTagmanagementEvent(null, 'website_interaction', {
                interaction_type: 'click',
                interaction_action: 'open',
                interaction_location: 'new_header_menu',
                interaction_name: 'mobileMijnnsButton'
            });
            /* eslint-enable */
        } else if (this.MobileMenuOpen) {
            this.MobileMenuOpen = false;
            this.MobileMijnnsMenuOpen = true;
            this.node.classList.add(this.config.classNames.isToggledMijnns);
            this.setActiveState(mijnnsSubmenuButton);
            this.toggleNavButton.classList.remove(this.config.classNames.active);
            this.tlMain.timeScale(-3);
            this.tlMijnNS.play().timeScale(1).restart();
            this.tlMijnNSIcon.play().timeScale(1).restart();

            /* eslint-disable camelcase */
            publishTagmanagementEvent(null, 'website_interaction', {
                interaction_type: 'click',
                interaction_action: 'open',
                interaction_location: 'new_header_menu',
                interaction_name: 'mobileMijnnsButton'
            });
            /* eslint-enable */
        } else {
            this.MobileMijnnsMenuOpen = false;
            ScrollBlocker.enableScroll();
            this.tlMijnNS.timeScale(-2);
            this.tlMijnNSIcon.timeScale(-1.5);
            setTimeout(() => this.tlNav.timeScale(-2), 200);

            /* eslint-disable camelcase */
            publishTagmanagementEvent(null, 'website_interaction', {
                interaction_type: 'click',
                interaction_action: 'close',
                interaction_location: 'new_header_menu',
                interaction_name: 'mobileMijnnsButton'
            });
            /* eslint-enable */
        }
    }

    closeMobileSubmenu() {
        const submenu = this.node.querySelector(this.config.selectors.submenuIsOpen);
        const activeMenuButton = submenu.previousElementSibling;
        const tlSubmenuPrevious = gsap
            .timeline({
                paused: true,
                defaults: {
                    duration: 0.5,
                    ease: this.animation.ease
                },
                onComplete: () => {
                    // Reset active button states
                    this.resetActiveState(activeMenuButton);
                    // Reset submenu is-open state
                    this.headerContainerWrapper.classList.remove(this.config.classNames.isOpen);
                    this.headerContent.removeAttribute('style');
                }
            })
            .set(submenu, {
                x: 0
            })
            .to(submenu, {
                x: '100vw'
            });

        this.MobileSubmenuOpen = false;
        tlSubmenuPrevious.play();

        /* eslint-disable camelcase */
        publishTagmanagementEvent(null, 'website_interaction', {
            interaction_type: 'click',
            interaction_action: 'previous',
            interaction_location: 'new_header_menu',
            interaction_name: 'previousButton'
        });
        /* eslint-enable */
    }

    closeMobileMenu(submenuCloseButton) {
        const submenu = this.node.querySelector(this.config.selectors.submenuIsOpen);
        const activeMenuButton = submenu.previousElementSibling;
        const tlSubmenuClose = gsap
            .timeline({
                paused: true,
                defaults: {
                    duration: 0.5,
                    ease: this.animation.ease
                },
                onComplete: () => {
                    // Reset active button states
                    this.resetActiveState(activeMenuButton);
                    HeaderUtil.setExpandedState(submenuCloseButton, false);
                    submenuCloseButton.classList.remove(this.config.classNames.active);
                    submenuCloseButton.setAttribute('aria-label', this.config.openMenuLabel);

                    // Reset header is-toggled state
                    this.node.classList.remove(this.config.classNames.isToggled);

                    // Reset submenu is-open state
                    this.headerContainerWrapper.classList.remove(this.config.classNames.isOpen);
                    this.headerContent.removeAttribute('style');

                    // Enable complete scrolling
                    ScrollBlocker.enableScroll();
                }
            })
            .set(
                this.headerContent,
                {
                    visibility: 'hidden'
                },
                '0'
            )
            .set(
                submenu,
                {
                    visibility: 'visible'
                },
                '0'
            )
            .to(this.headerFade, {
                opacity: 0,
                display: 'none'
            })
            .to(
                submenu,
                {
                    x: '100vw'
                },
                '<0'
            );

        this.MobileMenuOpen = false;
        this.MobileSubmenuOpen = false;
        this.tlNav.timeScale(-3);
        tlSubmenuClose.play();
        this.toggleNavButton.classList.remove(this.config.classNames.active);

        /* eslint-disable camelcase */
        publishTagmanagementEvent(null, 'website_interaction', {
            interaction_type: 'click',
            interaction_action: 'close',
            interaction_location: 'new_header_menu',
            interaction_name: 'submenuCloseButton'
        });
        /* eslint-enable */
    }

    resetMenu() {
        // Reset state
        this.MobileMenuOpen = false;
        this.MobileMijnnsMenuOpen = false;
        this.MobileSubmenuOpen = false;
        this.MobileSearchOpen = false;
        this.eventState = 'hover';
        ScrollBlocker.enableScroll();

        // Remove styling / classes
        if (this.toggleNavButton) {
            this.toggleNavButton.classList.remove(this.config.classNames.active);
        }

        this.node.classList.remove(this.config.classNames.isToggled);
        this.node.classList.remove(this.config.classNames.isToggledMijnns);
        this.node.classList.remove(this.config.classNames.isToggledSearch);
        this.node.removeAttribute('style');

        if (this.headerContainerWrapper) {
            this.headerContainerWrapper.classList.remove(this.config.classNames.isOpen);
        }

        const inlineStyles = this.node.querySelectorAll('[style]');
        const menuSearchWrapper = this.node.querySelector(this.config.selectors.menuSearchWrapper);
        Array.prototype.forEach.call(inlineStyles, (element) => {
            if (!menuSearchWrapper.contains(element)) {
                element.removeAttribute('style');
            }
        });

        const submenu = this.node.querySelector(this.config.selectors.submenuIsOpen);
        if (submenu) {
            submenu.parentNode.classList.remove(this.config.classNames.active);
            this.resetActiveState(submenu.previousElementSibling);
        }

        // Clear search value and autosuggest results
        const searchButton = this.node.querySelector(this.config.selectors.menuButtonSearch);
        const autosuggestNode = this.node.querySelector(this.config.selectors.searchFormWrapper);
        const menuSearchField = this.node.querySelector(this.config.selectors.menuSearchField);
        if (autosuggestNode) {
            const autosuggestController = this.componentManager.getInstanceDescriptorForNode(autosuggestNode).instance;
            autosuggestController.clear();
        }

        if (menuSearchField) {
            menuSearchField.value = '';
        }

        if (menuSearchWrapper) {
            menuSearchWrapper.removeAttribute('style');
        }

        if (searchButton) {
            searchButton.parentNode.classList.remove(this.config.classNames.active);
        }
    }
}

export default Header;

/* global Sentry, DD_RUM */
import without from 'lodash/without';
import ComponentFactory from './ComponentFactory';
import DOMMutationObserver from './DOMMutationObserver';
import debounce from 'lodash/debounce';

class ComponentManager {
    DEBUG = {
        active: document.location.hash.toLowerCase().indexOf('debugmode') > -1
    };
    STATES = {
        PENDING: 'pending',
        OPERATIONAL: 'operational',
        DESTROYED: 'destroyed'
    };

    constructor() {
        this.instanceCount = 0;
        this.instanceDescriptors = [];
        this.nodesToInit = [];
        this.nodesToDeinit = [];
    }

    init(root) {
        this.initNode(root);

        // if the body has an editmode===true value in its data, observe dom.
        // this means we are in the channelmanager.
        if (document.body.dataset.editmode === 'true') {
            this.observeDom(root);
        }

        const elements = root.querySelectorAll('[data-observe-mutations=true]');
        elements.forEach((el) => {
            this.observeDom(el);
        });
    }

    observeDom(document) {
        const scheduleUpdate = debounce(() => this.update(), 1);

        // observe changes to the dom
        this.observer = new DOMMutationObserver(document);
        this.observer.addListener(DOMMutationObserver.EVENTS.change, (mutations) => {
            mutations.forEach((mutation) => {
                // iterate through addedNodes
                mutation.addedNodes.forEach((node) => {
                    if (node instanceof HTMLElement) {
                        this.nodesToInit.push(node);
                        scheduleUpdate();
                    }
                });

                // iterate through removedNodes
                mutation.removedNodes.forEach((node) => {
                    if (node instanceof HTMLElement) {
                        this.nodesToDeinit.push(node);
                        scheduleUpdate();
                    }
                });
            });
        });
    }

    update() {
        while (this.nodesToDeinit.length) {
            this.deinitNode(this.nodesToDeinit.shift());
        }
        while (this.nodesToInit.length) {
            this.initNode(this.nodesToInit.shift());
        }
    }

    /**
     * Initializes components at startup and for added nodes on dom mutation.
     */
    initNode(rootNode) {
        // get all componentNodes within our rootNode.
        // a componentNode is a node which has a controller specified.
        // which means it has an attribute ie: data-controller="/generic/ui/..."
        const componentNodes = this.getComponentNodes(rootNode);
        // loop through componentNodes
        componentNodes.forEach((node) => {
            try {
                // make sure each component is only initialized once
                const initializedComponents = node._cmInitialized || [];
                const componentPath = node.dataset.controller;

                if (initializedComponents && initializedComponents.indexOf(componentPath) !== -1) {
                    return false;
                }

                initializedComponents.push(componentPath);
                node._cmInitialized = initializedComponents;

                // get the componentName, the config and the (possibly) existing instance of the component
                let componentName = componentPath.split('/').pop(),
                    config = node.dataset.config || '',
                    existingInstanceDescriptor = this.getInstanceDescriptorForNode(node);

                // if there is an existing instance,
                if (existingInstanceDescriptor) {
                    // AND it has a reInit method, reinit and be done
                    if (typeof existingInstanceDescriptor.instance.reInit === 'function') {
                        existingInstanceDescriptor.instance.reInit();
                        return;
                    }
                    // else, kill instance and start over
                    this.#killInstance(existingInstanceDescriptor);
                }

                // parse the config from a JSON string into a JS data object if it is specified
                if (config) {
                    try {
                        config = JSON.parse(config);
                    } catch (e) {
                        console.error('error parsing', config);
                    }
                }

                // up our instance counter
                this.instanceCount++;

                // create a unique id
                const instanceId = componentName + '_' + this.instanceCount,
                    // create a descriptor object for our instance for future reference.
                    // this will help us destroy the instance later on.
                    instanceDescriptor = {
                        id: instanceId,
                        node: node,
                        instance: null,
                        state: this.STATES.PENDING
                    };

                // enables us to use the dom inspector to see which instance belongs to the node.
                if (this.DEBUG.active) {
                    node.dataset.instance = instanceId;
                    console.log(instanceDescriptor.id, 'state is now', instanceDescriptor.state); // eslint-disable-line no-console
                }

                // store the instanceDescriptor in our list.
                this.instanceDescriptors.push(instanceDescriptor);

                // instantiate the component
                instanceDescriptor.instance = ComponentFactory.create(componentPath, node, config, this);
                instanceDescriptor.state = this.STATES.OPERATIONAL;

                if (this.DEBUG.active) {
                    console.log(instanceDescriptor.id, 'state is now', instanceDescriptor.state); // eslint-disable-line no-console
                }
            } catch (e) {
                if (typeof Sentry !== 'undefined') {
                    try {
                        Sentry.captureException(e);
                    } catch (e) {
                        //Ignore
                    }
                }
                //DD_LOGS uses console.error, DD_RUM does not do error tracking for console.error
                if (typeof DD_RUM !== 'undefined') {
                    try {
                        DD_RUM.addError(e, {
                            controller: node.dataset.controller,
                            config: node.dataset.config
                        });
                    } catch (e) {
                        //Ignore
                    }
                }
                console.error('Error instantiating component: ' + node.dataset.controller + ', ' + node.dataset.config, e);
            }
        });
    }

    /**
     * De-initializes components for removed nodes on dom mutation.
     */
    deinitNode(rootNode) {
        // get all component nodes within our rootNode.
        const componentNodes = this.getComponentNodes(rootNode, true);

        // loop through all component nodes
        componentNodes.forEach((node) => {
            delete node._cmInitialized;

            // find the instanceDescriptor that belongs to the componentNode
            const instanceDescriptor = this.getInstanceDescriptorForNode(node);

            if (this.DEBUG.active) {
                console.log('deinit', node.dataset.instance, instanceDescriptor.state); // eslint-disable-line no-console
            }

            // if found, ...
            if (instanceDescriptor) {
                // ... kill the instance
                this.#killInstance(instanceDescriptor);
            }
        });
    }

    /**
     * Searches a node and its children for elements which have a controller specified.
     */
    getComponentNodes(rootNode) {
        return Array.from(rootNode.querySelectorAll('[data-controller]'));
    }

    /**
     * Searches the instanceDescriptors list for an item belonging to a specific node.
     */
    getInstanceDescriptorForNode(node) {
        return this.instanceDescriptors.find((instanceDescriptor) => instanceDescriptor.node === node);
    }

    #killInstance(instanceDescriptor) {
        // Call the destroy method if available, ...
        if (instanceDescriptor.state === this.STATES.OPERATIONAL && typeof instanceDescriptor.instance.destroy === 'function') {
            instanceDescriptor.instance.destroy();
        }

        // ... remove the instance from the list, ...
        this.instanceDescriptors = without(this.instanceDescriptors, instanceDescriptor);
        delete instanceDescriptor.node.dataset.instance;

        // ... and mark the instance as destroyed.
        instanceDescriptor.state = this.STATES.DESTROYED;

        if (this.DEBUG.active) {
            console.log(instanceDescriptor.id, 'state is now', instanceDescriptor.state); // eslint-disable-line no-console
        }
    }
}

export default new ComponentManager();

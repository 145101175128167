import gsap from 'gsap';
import setTemporaryFocus from '../utils/setTemporaryFocus';
import FormValues from './FormValues';
import FormErrorSummary from './FormErrorSummary';
import merge from 'lodash/merge';

class FormPages {
    static DEFAULT_CONFIG = {
        classNames: {
            isHidden: 'is-hidden',
            isActive: 'is-active',
            isLoaded: 'is-loaded',
            isLoading: 'is-loading',
            isOverflowing: 'is-overflowing'
        },
        selectors: {
            errorSummarySelector: '.formErrorSummary'
        }
    };

    constructor(node, config) {
        this.node = node;
        this.config = merge({}, FormPages.DEFAULT_CONFIG, config);
        this.pageFlowIndex = 0;

        this.buttonPreviousPage = this.node.querySelector('[data-formAction="previousPage"]');
        this.buttonProcessPage = this.node.querySelector('[data-formAction="processPage"]');

        this.labelNextPage = this.buttonProcessPage?.querySelector('[data-buttonlabel="nextPage"]');
        this.labelSubmit = this.buttonProcessPage?.querySelector('[data-buttonlabel="submit"]');
        this.busyProcessing = false;
    }

    attach(form) {
        this.form = form;

        let pageNodes = Array.from(this.node.querySelectorAll('.formPage'));

        if (pageNodes.length === 0) {
            return;
        }

        this.pageNodes = pageNodes;
        this.formPages = this.config.formPages || [];

        this.#pageModel();
        this.#updatePageFlow();
        this.#updateButtonVisibility();
        this.#notifyStepIndicator();
        this.#attachListeners();
    }

    #pageModel() {
        let conditions = this.config.conditions || { pages: [] },
            formPages = this.formPages,
            pageNodes = this.pageNodes,
            getPageConditions = (index) => {
                for (let i = 0, l = conditions.pages.length; i < l; i++) {
                    if (parseInt(conditions.pages[i].index) === index) {
                        let conditionsObj = conditions.pages[i];
                        conditionsObj.fields = Array.from(this.node.querySelectorAll('.formfield *[name=' + conditionsObj.condname + ']'));

                        return conditionsObj;
                    }
                }
            };

        for (let j = 0; j < pageNodes.length; j++) {
            // get formPage object, and create dummy object if none is provided.
            let page = formPages[j] || (formPages[j] = { pagename: '' });

            page.node = pageNodes[j];
            page.conditions = getPageConditions(j);
            page.isVisible = this.#getPageVisibility(page);

            this.#attachConditionalFields(page);
            this.#attachErrorSummary(page);
        }

        // set first page as active.
        this.currentPageData = this.formPages[0];
    }

    #attachConditionalFields(pageData) {
        if (pageData.conditions) {
            pageData.conditions.fields.forEach((field) => {
                field.addEventListener('change', () => {
                    let pageIsInFlow = this.#getPageVisibility(pageData);
                    if (pageData.conditions.condtype === 'visibility') {
                        pageData.isVisible = pageIsInFlow;
                        this.#updatePageFlow();
                        this.#updateButtonVisibility();
                        this.#notifyStepIndicator();
                    }
                });
            });
        }
    }

    #attachErrorSummary(pageData) {
        let errorSummary = pageData.node.querySelector(this.config.selectors.errorSummarySelector);
        this.errorSummary = new FormErrorSummary(errorSummary, { pageData: pageData });
        this.errorSummary.attach(this.form);
    }

    #attachListeners() {
        // Previous button
        this.buttonPreviousPage?.addEventListener('click', (e) => {
            e.preventDefault();
            if (this.pageFlowIndex > 0 && !this.busyProcessing) {
                //  Testautomatisering
                this.node.classList.remove(this.config.classNames.isLoaded);
                this.node.classList.add(this.config.classNames.isLoading);
                //  END Testautomatisering

                this.pageFlowIndex--;

                this.#processPage();
            }
        });

        this.validationPassed = false;
        this.node.addEventListener('submit', (event) => {
            if (this.busyProcessing) {
                event.preventDefault();
            } else {
                if (!this.validationPassed) {
                    event.preventDefault();

                    let validationFlow = this.#doFormValidation();
                    validationFlow.then(() => {
                        this.validationPassed = true;

                        let lastPageInFlow = this.#isLastPageInFlow();
                        if (lastPageInFlow) {
                            this.node.submit();
                        } else {
                            this.pageFlowIndex++;
                            this.#processPage();
                        }

                        this.validationPassed = false;
                    });
                } else {
                    this.busyProcessing = true;
                }
            }
        });

        let thisForm = this.form;
        thisForm.on('errorSummaryScrollDone', () => {
            //  Testautomatisering
            this.node.classList.add(this.config.classNames.isLoaded);
            this.node.classList.remove(this.config.classNames.isLoading);
            //  END Testautomatisering
        });
    }

    #doFormValidation() {
        //  Testautomatisering
        this.node.classList.remove(this.config.classNames.isLoaded);
        this.node.classList.add(this.config.classNames.isLoading);
        //  END Testautomatisering

        return this.form.getValidation().doValidation(this.currentPageData.node, this.pageFlowIndex);
    }

    #processPage() {
        let thisForm = this.form,
            formPageContainer = this.node.querySelector('.formPageHolder'),
            offset = window.scrollY,
            activeClass = this.config.classNames.isActive,
            windowScrollTime = 0.2 + offset / 800,
            tl = gsap.timeline();

        let animateIn = (prevPage, activePage) => {
            thisForm.emit('switchPage');
            prevPage.node.classList.remove(activeClass);
            activePage.node.classList.add(activeClass);

            this.#notifyStepIndicator();
            this.#updateButtonVisibility();

            tl.to(formPageContainer, {
                duration: 0.3,
                opacity: 1,
                onComplete: () => {
                    if (this.currentPageData) {
                        this.currentPageData.node.classList.remove(this.config.classNames.isActive);
                    }

                    this.currentPageData = this.pageFlow[this.pageFlowIndex];
                    this.currentPageData.node.classList.add(this.config.classNames.isActive);
                    setTemporaryFocus(this.currentPageData.node);

                    formPageContainer.classList.remove(this.config.classNames.isOverflowing);
                    formPageContainer.style.height = 'auto';

                    //  Testautomatisering
                    this.node.classList.add(this.config.classNames.isLoaded);
                    this.node.classList.remove(this.config.classNames.isLoading);
                    // END  Testautomatisering
                    this.busyProcessing = false;
                }
            });
        };

        let startAnimation = () => {
            this.busyProcessing = true;
            let nextPage = this.pageFlow[this.pageFlowIndex],
                isOverFlowingClass = this.config.classNames.isOverflowing;

            formPageContainer.classList.add(isOverFlowingClass);

            nextPage.node.style.display = 'block';
            let nextPageHeight = nextPage.node.getBoundingClientRect().height;
            nextPage.node.removeAttribute('style');

            if (this.currentPageData) {
                tl.addLabel('animationStart');
                tl.to(window, { duration: windowScrollTime, scrollTo: { y: 0 } }, 'animationStart');
                tl.to(
                    formPageContainer,
                    {
                        duration: 0.5,
                        opacity: 0,
                        height: nextPageHeight,
                        onComplete: animateIn,
                        onCompleteParams: [this.currentPageData, nextPage]
                    },
                    'animationStart'
                );
            }
            this.currentPageData = this.pageFlow[this.pageFlowIndex];
        };

        startAnimation();
    }

    #updateButtonVisibility() {
        let backButtonVisible = this.pageFlowIndex > 0,
            hiddenClass = this.config.classNames.isHidden,
            lastPageInFlow = this.#isLastPageInFlow();

        if (backButtonVisible) {
            this.buttonPreviousPage?.classList.remove(hiddenClass);
        } else {
            this.buttonPreviousPage?.classList.add(hiddenClass);
        }

        if (lastPageInFlow) {
            this.labelNextPage?.classList.add(hiddenClass);
            this.labelSubmit?.classList.remove(hiddenClass);
        } else {
            this.labelNextPage?.classList.remove(hiddenClass);
            this.labelSubmit?.classList.add(hiddenClass);
        }
    }

    #notifyStepIndicator() {
        let thisForm = this.form,
            stepData = {
                currentIndex: this.pageFlowIndex,
                pagesInFlow: this.pageFlow.length
            };
        thisForm.updateStepIndicator().setSteps(stepData);
    }

    #getPageVisibility(pageData) {
        if (!pageData.conditions) {
            return true;
        }

        let conditions = pageData.conditions,
            fieldValue = FormValues.getInputValue(conditions.condname),
            conditionIsMatch = conditions.condvalue === fieldValue;
        return conditions.condnegated ? !conditionIsMatch : conditionIsMatch;
    }

    #updatePageFlow() {
        this.pageFlow = this.formPages.filter((p) => {
            return p.isVisible;
        });
    }

    #isLastPageInFlow() {
        return this.pageFlowIndex + 1 === this.pageFlow.length;
    }
}

export default FormPages;

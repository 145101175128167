import Responsive from '../utils/Responsive';

export default function initResponsive() {
    // make sure breakpoints keep in line with breakpoints in css in setup/_responsive.scss!
    Responsive.addBreakpointData('s', 0);
    Responsive.addBreakpointData('sm', 480);
    Responsive.addBreakpointData('m', 640);
    Responsive.addBreakpointData('ml', 768);
    Responsive.addBreakpointData('l', 912);
    Responsive.addBreakpointData('xl', 1024);
    Responsive.addBreakpointData('xxl', 1200);
    Responsive.addBreakpointData('xxxl', 1408);
    Responsive.emitEvent(Responsive.EVENTS.initialized);
}

/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
import Utils from '../common/utils';
import EventEmitter from './event_emitter';
import highlight from './highlight';
import DOMUtils from '../../generic/utils/DOMUtils';

class Dataset extends EventEmitter {
    static #keys = {
        val: 'tt-selectable-display',
        obj: 'tt-selectable-object'
    };

    static #nameGenerator = Utils.getIdGenerator();

    constructor(o, www) {
        super();
        o = o || {};
        o.templates = o.templates || {};

        // DEPRECATED: empty will be dropped in v1
        o.templates.notFound = o.templates.notFound || o.templates.empty;

        if (!o.source) {
            Utils.error('missing source');
        }

        if (!o.node) {
            Utils.error('missing node');
        }

        if (o.name && !Dataset.#isValidName(o.name)) {
            Utils.error('invalid dataset name: ' + o.name);
        }

        www.mixin(this);

        this.highlight = !!o.highlight;
        this.name = o.name || Dataset.#nameGenerator();

        this.limit = o.limit || 5;
        this.displayFn = Dataset.#getDisplayFn(o.display || o.displayKey);
        this.templates = Dataset.#getTemplates(o.templates, this.displayFn);

        // use duck typing to see if source is a bloodhound instance by checking
        // for the __ttAdapter property; otherwise assume it is a function
        this.source = o.source.__ttAdapter ? o.source.__ttAdapter() : o.source;

        // if the async option is undefined, inspect the source signature as
        // a hint to figuring out of the source will return async suggestions
        this.async = Utils.isUndefined(o.async) ? this.source.length > 2 : !!o.async;

        this.#resetLastSuggestion();

        this.el = o.node;
        this.el.classList.add(this.classes.dataset, this.classes.dataset + '-' + this.name);
    }

    static extractData(el) {
        const data = el[Dataset.#keys.obj];
        if (data) {
            return {
                val: el[Dataset.#keys.val] || '',
                obj: data
            };
        }

        return null;
    }

    #overwrite(query, suggestions) {
        suggestions = suggestions || [];

        // got suggestions: overwrite dom with suggestions
        if (suggestions.length) {
            this.#renderSuggestions(query, suggestions);
        }

        // no suggestions, expecting async: overwrite dom with pending
        else if (this.async && this.templates.pending) {
            this.#renderPending(query);
        }

        // no suggestions, not expecting async: overwrite dom with not found
        else if (!this.async && this.templates.notFound) {
            this.#renderNotFound(query);
        }

        // nothing to render: empty dom
        else {
            this.#empty();
        }

        this.trigger('rendered', this.name, suggestions, false);
    }

    #append(query, suggestions) {
        suggestions = suggestions || [];

        // got suggestions, sync suggestions exist: append suggestions to dom
        if (suggestions.length && this.lastSuggestion) {
            this.#appendSuggestions(query, suggestions);
        }

        // got suggestions, no sync suggestions: overwrite dom with suggestions
        else if (suggestions.length) {
            this.#renderSuggestions(query, suggestions);
        }

        // no async/sync suggestions: overwrite dom with not found
        else if (!this.lastSuggestion && this.templates.notFound) {
            this.#renderNotFound(query);
        }

        this.trigger('rendered', this.name, suggestions, true);
    }

    #renderSuggestions(query, suggestions) {
        const fragment = this.#getSuggestionsFragment(query, suggestions);
        this.lastSuggestion = fragment.childNodes.item(fragment.childNodes.length - 1);

        this.el.replaceChildren(fragment);
        const header = this.#getHeader(query, suggestions);
        if (header) {
            this.el.prepend(header);
        }
        const footer = this.#getFooter(query, suggestions);
        if (footer) {
            this.el.append(footer);
        }
    }

    #appendSuggestions(query, suggestions) {
        let fragment, lastSuggestion;

        fragment = this.#getSuggestionsFragment(query, suggestions);
        lastSuggestion = fragment.childNodes.item(fragment.childNodes.length - 1);

        this.lastSuggestion.after(fragment);

        this.lastSuggestion = lastSuggestion;
    }

    #renderPending(query) {
        let template = this.templates.pending;

        this.#resetLastSuggestion();
        if (template) {
            this.el.innerHTML = template({
                query: query,
                dataset: this.name
            });
        }
    }

    #renderNotFound(query) {
        let template = this.templates.notFound;

        this.#resetLastSuggestion();
        if (template) {
            this.el.innerHTML = template({
                query: query,
                dataset: this.name
            });
        }
    }

    #empty() {
        this.el.replaceChildren();
        this.#resetLastSuggestion();
    }

    #getSuggestionsFragment(query, suggestions) {
        const fragment = document.createDocumentFragment();
        suggestions.forEach((suggestion) => {
            let el, context;

            context = this.#injectQuery(query, suggestion);

            el = DOMUtils.createElementFromHTML(this.templates.suggestion(context));
            el[Dataset.#keys.obj] = suggestion;
            el[Dataset.#keys.val] = this.displayFn(suggestion);
            el.classList.add(this.classes.suggestion, this.classes.selectable);
            fragment.appendChild(el);
        });

        this.highlight &&
            highlight({
                className: this.classes.highlight,
                node: fragment,
                pattern: query
            });

        return fragment;
    }

    #getFooter(query, suggestions) {
        return this.templates.footer
            ? this.templates.footer({
                  query: query,
                  suggestions: suggestions,
                  dataset: this.name
              })
            : null;
    }

    #getHeader(query, suggestions) {
        return this.templates.header
            ? this.templates.header({
                  query: query,
                  suggestions: suggestions,
                  dataset: this.name
              })
            : null;
    }

    #resetLastSuggestion() {
        this.lastSuggestion = null;
    }

    #injectQuery(query, obj) {
        return Utils.isObject(obj) ? Utils.mixin({ _query: query }, obj) : obj;
    }

    update(query) {
        let that = this,
            canceled = false,
            syncCalled = false,
            rendered = 0;

        // cancel possible pending update
        this.cancel();

        this.cancel = function cancel() {
            canceled = true;
            that.cancel = Utils.noop;
            that.async && that.trigger('asyncCanceled', query);
        };

        this.source(query, sync, async);
        !syncCalled && sync([]);

        function sync(suggestions) {
            if (syncCalled) {
                return;
            }

            syncCalled = true;
            suggestions = (suggestions || []).slice(0, that.limit);
            rendered = suggestions.length;

            that.#overwrite(query, suggestions);

            if (rendered < that.limit && that.async) {
                that.trigger('asyncRequested', query);
            }
        }

        function async(suggestions) {
            suggestions = suggestions || [];

            // if the update has been canceled or if the query has changed
            // do not render the suggestions as they've become outdated
            if (!canceled && rendered < that.limit) {
                that.cancel = Utils.noop;
                that.#append(query, suggestions.slice(0, that.limit - rendered));
                rendered += suggestions.length;

                that.async && that.trigger('asyncReceived', query);
            }
        }
    }

    // cancel function gets set in #update
    cancel = Utils.noop;

    clear() {
        this.#empty();
        this.cancel();
        this.trigger('cleared');
    }

    isEmpty() {
        return this.el.matches(':empty');
    }

    static #getDisplayFn(display) {
        display = display || Utils.stringify;

        return Utils.isFunction(display) ? display : displayFn;

        function displayFn(obj) {
            return obj[display];
        }
    }

    static #getTemplates(templates, displayFn) {
        return {
            notFound: templates.notFound && Utils.templatify(templates.notFound),
            pending: templates.pending && Utils.templatify(templates.pending),
            header: templates.header && Utils.templatify(templates.header),
            footer: templates.footer && Utils.templatify(templates.footer),
            suggestion: templates.suggestion || suggestionTemplate
        };

        function suggestionTemplate(context) {
            return `<div>${displayFn(context)}</div>`;
        }
    }

    static #isValidName(str) {
        // dashes, underscores, letters, and numbers
        return /^[_a-zA-Z0-9-]+$/.test(str);
    }
}

export default Dataset;

import Cookies from 'js-cookie';
import merge from 'lodash/merge';

class CartValueTester {
    static DEFAULT_CONFIG = {
        cartCookieName: 'MiniBasketCookie'
    };

    constructor() {
        this.config = merge({}, CartValueTester.DEFAULT_CONFIG);
        this.cookieName = this.config.cartCookieName;

        window.setNewCartValues = (itemCount) => {
            this.setNewCartValues(itemCount);
        };

        window.clearCartValues = () => {
            this.clearCartValues();
        };
    }

    setNewCartValues(itemCount) {
        let hostname = document.location.hostname;

        Cookies.remove(this.cookieName);
        Cookies.set(
            this.cookieName,
            JSON.stringify({
                basket: {
                    itemCount: itemCount
                }
            }),
            {
                domain: hostname === 'localhost' ? hostname : '.' + hostname
            }
        );

        setTimeout(() => {
            location.reload();
        }, 1000);

        console.log('items: ' + itemCount); // eslint-disable-line no-console
    }

    clearCartValues() {
        Cookies.remove(this.cookieName);
        setTimeout(() => {
            location.reload();
        }, 1000);

        console.log('card cookie cleared'); // eslint-disable-line no-console
    }
}

export default new CartValueTester();

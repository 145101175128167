class ToggleSync {
    constructor(node) {
        this.node = node;

        this.node.addEventListener('change', (event) => {
            let target = event.target;
            let otherControl = Array.from(document.querySelectorAll('input[name="' + target.name + '"]')).find((control) => {
                return target !== control && target.value === control.value;
            });
            if (otherControl && !otherControl.checked) {
                otherControl.checked = true;
                otherControl.dispatchEvent(new Event('change', { bubbles: true }));
            }
        });
    }
}

export default ToggleSync;

import template from '../jstemplates/gridreference.tpl';
import throttle from 'lodash/throttle';
import DOMUtils from '../utils/DOMUtils';

class GridReference {
    #resizeListener;

    constructor() {
        this.#resizeListener = throttle(() => this.update(), 20);
        document.addEventListener('keypress', (e) => {
            if (document.activeElement && document.activeElement.matches(':read-write')) {
                return;
            }

            if (e.key === 'g' || e.key === 'G') {
                this.toggle();
            }
        });

        this.showing = false;
        this.list = [];
    }

    toggle() {
        if (this.showing) {
            this.showing = false;
            window.removeEventListener('resize', this.#resizeListener);
            this.cleanup();
        } else {
            this.showing = true;
            window.addEventListener('resize', this.#resizeListener);
            this.update();
        }
    }

    cleanup() {
        this.list.forEach((item) => {
            item.reference.remove();
        });
        this.list = [];
    }

    update() {
        if (!this.showing) {
            return;
        }

        // all grids that will be referenced
        let targets = document.querySelectorAll('.grid:not(.dev-GridReference):not(.grid .grid)');
        targets.forEach((target) => {
            // an object linking the target grid on page with the reference
            let item = this.list.find((e) => {
                return e.target === target;
            });
            if (!item) {
                const element = DOMUtils.createElementFromHTML(template);
                item = {
                    target: target,
                    reference: element
                };
                document.body.append(element);
                this.list.push(item);
            }

            // the grid reference element
            const reference = item.reference;
            // offset of a target grid on page
            const offset = DOMUtils.offset(target);

            reference.style.left = `${offset.left}px`;
            reference.style.top = `${offset.top - 14}px`;
            reference.style.width = `${target.getBoundingClientRect().width}px`;
            reference.style.height = `${target.getBoundingClientRect().height + 28}px`;
        });
    }
}

export default new GridReference();

import Responsive from '../utils/Responsive';
import ComponentManager from '../utils/ComponentManager';
import publishTagmanagementEvent from '../utils/publishTagmanagementEvent';
import Darkener from '../ui/Darkener';
import ScrollBlocker from '../ui/ScrollBlocker';
import TabSequenceWatch from '../ui/TabSequenceWatch';
import scrollToTarget from '../utils/scrollToTarget';

export default function createRequirePatch() {
    window.require = (path) => {
        switch (path) {
            case 'generic/utils/Responsive':
                return Responsive;

            case 'generic/utils/ComponentManager':
                return ComponentManager;

            case 'generic/ui/Darkener':
                return Darkener;

            case 'generic/ui/ScrollBlocker':
                return ScrollBlocker;

            case 'generic/ui/TabSequenceWatch':
                return TabSequenceWatch;

            case 'generic/utils/scrollToTarget':
                return scrollToTarget;

            case 'generic/utils/publishTagmanagementEvent':
                return publishTagmanagementEvent;

            default:
                break;
        }
    };
}

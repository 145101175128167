export default function initPolyfills() {
    // polyfill for replaceChildren
    if (Element.prototype.replaceChildren === undefined) {
        Element.prototype.replaceChildren = function (addNodes) {
            while (this.lastChild) {
                this.removeChild(this.lastChild);
            }
            if (addNodes !== undefined) {
                this.append(addNodes);
            }
        };
    }
}

class HeaderUtil {
    static matchHeight(elements) {
        if (elements) {
            let heights = [];
            [...elements].forEach((element) => {
                element.style.height = '';
                heights.push(element.offsetHeight);
            });

            let tallest = Math.max(...heights);
            [...elements].forEach((element) => {
                element.style.height = tallest + 'px';
            });
        }
    }

    static setExpandedState(element, isExpanded) {
        element.setAttribute('aria-expanded', isExpanded);
    }
}
export default HeaderUtil;

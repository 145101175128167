import EventEmitter from 'wolfy87-eventemitter';

class CdpObserver extends EventEmitter {
    EVENTS = {
        loadingDone: 'CdpObserver.EVENTS.loading_done'
    };

    handleCdpLoadingFinished(success) {
        this.emitEvent(this.EVENTS.loadingDone, [success]);
    }
}

export default new CdpObserver();

import Handlebars from 'handlebars/dist/handlebars';
import templateForTable from '../jstemplates/richtext/table.tpl';
import Responsive from '../utils/Responsive';
import DOMUtils from '../utils/DOMUtils';

class ResponsiveTable {
    static CLASSNAMES = {
        tableParent: 'richText',
        container: 'tableContainer',
        scrollElement: 'tableContainer__scroll',
        content: 'tableContainer__content',
        fade: 'tableContainer--fade',
        fadeElement: 'tableContainer__fadeElement'
    };

    /**
     * @param node
     * 1 Fix cell widths to min-widths
     * 2 Wrap it
     * 3 Set height of fade
     * 4 Set fade when necessary;
     * Hide/show fade on resizing depending on whether it fits in its container (richText)
     */
    constructor(node) {
        this.node = node;
        this.tableTemplate = Handlebars.compile(templateForTable);

        this.fixCellWidth(this.node);
        this.wrapTable(this.node);
        this.setFadeHeight(this.node);
        this.toggleFade(this.node);

        Responsive.on(Responsive.EVENTS.resized, () => {
            this.setFadeHeight(this.node);
            this.toggleFade(this.node);
        });
    }

    static init(node) {
        let instance = node._rtResponsiveTable;

        if (instance) {
            return instance;
        } else {
            instance = new ResponsiveTable(node);
            node._rtResponsiveTable = instance;

            return instance;
        }
    }

    /**
     * Fixes cell widths to min-widths, should be set by Hippo but unfortunately not possible
     */
    fixCellWidth(node) {
        // Select only cells with a style attribute containing the word "width:"
        // Note: this will also match "min-width:" etc.
        let tableCells = node.querySelectorAll('td[style*="width:"], th[style*="width:"]');

        tableCells.forEach((cell) => {
            let inlineWidth = cell.style.width;
            // Check if a width has been provided
            if (inlineWidth) {
                cell.style.width = '';
                cell.style.minWidth = inlineWidth;
            }
        });
    }

    /**
     * Wrapping the table into a scrollable div
     */
    wrapTable(node) {
        const container = this.tableTemplate();
        const containerElement = DOMUtils.createElementFromHTML(container);
        this.content = containerElement.querySelector('[data-table]');

        const parentNode = node.parentNode;
        parentNode.insertBefore(containerElement, node.nextSibling);
        this.content.appendChild(node);
    }

    /**
     * Set height of the fadeElement, otherwise, it'll cover the scrollbar
     */
    setFadeHeight(node) {
        let containerSelector = '.' + ResponsiveTable.CLASSNAMES.container;
        let tableContainer = node.closest(containerSelector);
        let contentHeight = tableContainer.querySelector('.' + ResponsiveTable.CLASSNAMES.content).clientHeight;

        let fadeElement = tableContainer.querySelector('.' + ResponsiveTable.CLASSNAMES.fadeElement);
        fadeElement.style.height = contentHeight + 'px';
    }

    /**
     * Checks if table is bigger than richText container. If so, show it otherwise hide it.
     */
    toggleFade(node) {
        let containerSelector = '.' + ResponsiveTable.CLASSNAMES.container;
        let tableContainer = node.closest(containerSelector);
        tableContainer.classList.remove(ResponsiveTable.CLASSNAMES.fade);

        let parent = tableContainer.parentNode;
        let parentWidth = parent.offsetWidth;
        let tableWidth = node.offsetWidth;

        if (tableWidth > parentWidth) {
            tableContainer.classList.add(ResponsiveTable.CLASSNAMES.fade);
        }
    }
}

export default ResponsiveTable;

/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */

import Transport from './transport';

class Remote {
    constructor(o) {
        this.url = o.url;
        this.prepare = o.prepare;
        this.transform = o.transform;

        this.transport = new Transport({
            cache: o.cache,
            limiter: o.limiter
        });
    }

    #settings() {
        return { url: this.url, type: 'GET', dataType: 'json' };
    }

    get(query, cb) {
        let that = this,
            settings;

        if (!cb) {
            return;
        }

        query = query || '';
        settings = this.prepare(query, this.#settings());

        return this.transport.get(settings, onResponse);

        function onResponse(err, resp) {
            err ? cb([]) : cb(that.transform(resp));
        }
    }

    cancelLastRequest() {
        this.transport.cancel();
    }
}

export default Remote;

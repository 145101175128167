class UrlUtils {
    /**
     * Get or set the query params
     *
     * @param params (Object, String)
     * @returns {*}
     */
    static params(params) {
        let obj = {},
            i,
            parts,
            len,
            key,
            value;

        // Get or set?
        if (typeof params === 'string') {
            value = window.location.search.match(new RegExp('[?&]' + params + '=?([^&]*)[&#$]?'));
            return value ? value[1] : undefined;
        }

        // Set, so get current params
        let _params = window.location.search.substring(1).split('&');

        // Remove non-key params
        for (i = 0, len = _params.length; i < len; i++) {
            parts = _params[i].split('=');
            if (!parts[0]) {
                continue;
            }
            obj[parts[0]] = parts[1] || true;
        }

        if (typeof params !== 'object') {
            return obj;
        }

        // Remove empty values
        for (key in params) {
            value = params[key];
            if (typeof value === 'undefined') {
                delete obj[key];
            } else {
                obj[key] = value;
            }
        }

        // Build the query parts
        parts = [];
        for (key in obj) {
            parts.push(key + (obj[key] === true ? '' : '=' + obj[key]));
        }

        // Add them to the URL
        window.history.replaceState('', '', '?' + parts.join('&'));
    }
}
export default UrlUtils;
